import useWidgetAction from './useWidgetAction'
import { MenuItem, IconButton } from '@mui/material'
import useWidgetActionCall from './useWidgetActionCall'
import { WidgetActionDynamicStatus } from 'genesis-suite/types/visualTypes'

const { DISABLE, HIDE } = WidgetActionDynamicStatus

export default function WidgetActionMenuItem({
    action,
    config,
    filters,
    inlineFilters,
    networkContext,
    isPopupWidget,
}) {
    const [onActionClick, Icon] = useWidgetAction(action, isPopupWidget)
    const [isLoading, actionStatus] = useWidgetActionCall({ action, config, filters, inlineFilters, networkContext })

    if (actionStatus === HIDE) return

    return (
        <MenuItem
            sx={{
                '&.Mui-disabled': {
                    cursor: 'not-allowed',
                    pointerEvents: 'auto',
                },
            }}
            disabled={actionStatus === DISABLE}
            onClick={actionStatus === DISABLE ? null : onActionClick}
        >
            <IconButton disabled={actionStatus === DISABLE}>{Icon}</IconButton>
            {isLoading ? 'Loading...' : action.ShortName || action.ActionName}
        </MenuItem>
    )
}
