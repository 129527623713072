import { Box } from '@mui/material'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { ReDragModal } from 'genesis-suite/components'
import { widgetSelectors } from '../../selectors'
import PerspectiveWidget from '../widgets/PerspectiveWidget'
import { PerspectiveProvider } from './PerspectiveContext'

interface PopoutContextProps {
    popoutWidget: (config: any) => void
    closePopout: (config: any) => void
    clearPopup: () => void
}
const PopoutContext = React.createContext<Partial<PopoutContextProps>>({})

const PopoutProvider = ({ children }) => {
    const [configs, setWidgetConfigs] = useState([])

    const popoutWidget = config => {
        if (!configs.find(c => c.Id === config.Id)) setWidgetConfigs(prevConfigs => [...prevConfigs, config])
    }

    const closePopout = config => {
        const filtered = configs.filter(c => c.Id !== config.Id)
        setWidgetConfigs(filtered)
    }

    const clearPopup = () => {
        setWidgetConfigs([])
    }

    function getSize(config) {
        if (!config.isComponent) return
        return { width: config.width, height: config.height }
    }

    function renderContent(config) {
        if (config.isComponent) {
            return config.component
        }
        if (config.isPerspective) {
            const cellArr = config?.ContainerConfig?.Cells.filter(cell => cell.WidgetId !== null)
            return (
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fit, minmax(750px, 1fr))',
                        gap: 1.5,
                        overflowY: 'auto',
                        gridAutoRows: 'minmax(450px, auto)',
                        ...(cellArr.length === 1 ? { height: '100%' } : {}),
                    }}
                >
                    <PopoutPerspective config={config} />
                </Box>
            )
        }
        return (
            <PerspectiveProvider id={config.PerspectiveId} configs={[config]}>
                <Box sx={{ width: '100%', height: '100%' }}>
                    <PerspectiveWidget config={config} isPopup={true} actionFilters={config?.actionFilters} />
                </Box>
            </PerspectiveProvider>
        )
    }

    return (
        <PopoutContext.Provider value={{ popoutWidget, closePopout, clearPopup }}>
            {children}
            {configs.map(config => {
                const isPerspective = config?.ContainerConfig !== null
                return (
                    <ReDragModal
                        resizeable
                        key={config.Id}
                        heading={config.Title}
                        closeModal={() => closePopout(config)}
                        resizeProps={getSize(config)}
                    >
                        {renderContent({ ...config, isPerspective })}
                    </ReDragModal>
                )
            })}
        </PopoutContext.Provider>
    )
}

function PopoutPerspective({ config }) {
    const configs = useSelector(widgetSelectors.getWidgetConfigs)
    const widgetIds = config.ContainerConfig.Cells.filter(cell => cell.WidgetId !== null)
        .sort((a, b) => (a.Top - b.Top === 0 ? a.Left - b.Left : a.Top - b.Top))
        .map(w => w.WidgetId)
    const widgetConfigs = widgetIds.map(id => configs[id])

    return (
        <PerspectiveProvider id={config.Id} configs={widgetConfigs}>
            {widgetConfigs.map(w => (
                <PerspectiveWidget
                    key={w.Id}
                    config={w}
                    actionFilters={config?.actionFilters}
                    perspectiveId={config.Id}
                    isPopup={true}
                />
            ))}
        </PerspectiveProvider>
    )
}

export { PopoutContext, PopoutProvider }
