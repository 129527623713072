import { List, ListItem, Paper, useTheme } from '@mui/material'

import { ChopText } from 'genesis-suite/components'
import { MapSeries } from 'genesis-suite/types/visualTypes'
import pickFromCarousel from '../utils/pickFromCarousel'
import useWidgetColors from '../../../hooks/useWidgetColors'
import { generateIconPath, getSeriesColor } from './mapUtils'

interface Props {
    configSeries: MapSeries[]
    disabledIndexes: number[]
    enabled: boolean
    onClick: (dataIndex: number) => void
    mapData?: any
}

export default function MapLegend({ configSeries, disabledIndexes, enabled, onClick, mapData }: Props) {
    const theme = useTheme()
    const defaultColors = useWidgetColors()

    if (!enabled) return null

    return (
        <Paper elevation={3} sx={{ background: '#ffffffdd', color: 'text.default', width: '200px' }}>
            <List>
                {configSeries.map((series, i) => {
                    const iconPath = generateIconPath(series.type === 'marker' ? series.markerType : null)
                    const enabled = !disabledIndexes.includes(i)
                    const color = getSeriesColor(series) ?? pickFromCarousel(defaultColors, i)
                    const iconFill = enabled ? color : theme.palette.grayscale.light
                    if (series.type === 'marker' && mapData[i]?.data?.length === 0) {
                        return null
                    }
                    return (
                        <ListItem key={i} sx={{ gap: 1, padding: '2px 0 2px 10px' }} button onClick={() => onClick(i)}>
                            <svg viewBox="-5 -5 10 10" height="12">
                                <path d={iconPath} fill={iconFill} />
                            </svg>
                            <ChopText showEllipsis tooltipProps={{ placement: 'top' }}>
                                {series.service.name || `Series${i + 1}`}
                            </ChopText>
                        </ListItem>
                    )
                })}
            </List>
        </Paper>
    )
}
