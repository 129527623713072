import ExpandMore from '@mui/icons-material/ExpandMore'
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Popover,
    TextField,
    Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import produce from 'immer'
import { uniq } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'

import { ChopText } from 'genesis-suite/components'
import AggregateMenu from 'genesis-suite/components/aggregation_selector/AggregateMenu'
import { aggregationDetailDictionary } from 'genesis-suite/components/aggregation_selector/aggregationOptions'
import ExpressionEditor, { Suggestion, SuggestMethod } from 'genesis-suite/components/ExpressionEditor'
import { Property } from 'genesis-suite/types/networkTypes'
import {
    Aggregation,
    ChartType,
    CustomTooltip,
    DataField,
    DataGroup,
    SeriesConfig,
    TooltipType,
} from 'genesis-suite/types/visualTypes'
import LabeledToggleButton from '~/components/LabeledToggleButton'
import { replaceMustacheValues } from '../../../../lib/utils'
import { ParsedResponse } from '../../../../types/WidgetTypes'
import Markdown from '../../../Markdown'
import { aggregateFunction } from '../../../widgets2/utils/aggregateFunctions'
import { makeDefaultTooltip } from '../../../widgets2/utils/configDefaults'
import makeTooltipMarkdown from '../../../widgets2/utils/makeTooltipMarkdown'
import { ConfigContext } from '../../ConfigContext'
import { getAllProperties, getDataFieldProperty, getDefaultAggregation } from '../../utils'
import CurrencySymbolEditor from '../CurrencySymbolEditor'
import { baseOptions } from '../DisplayFormatFields'
import EditorWrapper from '../EditorWrapper'
import { typeSeriesKeyMapping } from './SeriesEditor2'

const valueSeparator = '~'

type NewValueType = 'text' | 'property' | 'values'

const useStyles = makeStyles(({ spacing, palette, border }) => ({
    dialog: { width: '800px', maxWidth: '90vw' },
    content: { display: 'grid', gridColumnGap: spacing(), gridTemplateColumns: 'auto 200px' },
    expressionWrapper: { marginTop: 0 },
    displayFormatWrapper: {
        border: `1px solid rgba(0, 0, 0, 0.23)`,
        borderRadius: border.radius.round,
        padding: spacing(),
    },
    tooltipPreviewBox: {
        border: border.default,
        padding: spacing(),
        margin: `auto ${spacing()}`,
        borderRadius: border.radius.round,
        cursor: 'default',
    },
    tooltipWrapper: { '& p': { margin: 0 } },
}))

export default function SeriesTooltipEditor() {
    const { resources, dispatch, dataResponse, selectedField, configKey, ...rest } = useContext(ConfigContext)
    const config = rest.config as SeriesConfig
    const [open, setOpen] = useState(false)
    const [draft, setDraft] = useState<CustomTooltip>(emptyTooltip)
    const [isDefault, setIsDefault] = useState(true)
    const [selectedConfigIndex, setSelectedConfigIndex] = useState<number>(null)
    const [changesMade, setChangesMade] = useState(false)
    const seriesKey = typeSeriesKeyMapping[config?.Type]

    const activeSeriesIndex = selectedField.index
    // const activeSeries = config[configKey]?.Series?.[activeSeriesIndex]
    let widgetConfig
    let activeSeries

    if (selectedField?.type === 'series') {
        if (selectedField?.subSeries === 'pivot') {
            widgetConfig = config[configKey]?.PivotFieldConfig
            activeSeries = widgetConfig?.ValueFields?.[activeSeriesIndex]
        } else {
            widgetConfig = config[configKey]
            activeSeries = widgetConfig?.[seriesKey]?.[activeSeriesIndex]
        }
    } else {
        return null
    }
    const properties = getAllProperties(resources.byId)
    const classes = useStyles()

    useEffect(() => {
        init()
    }, [activeSeriesIndex])

    function init() {
        setChangesMade(false)
        setDraft(
            activeSeries?.Tooltip?.type === TooltipType.CUSTOM
                ? activeSeries.Tooltip
                : makeDefaultTooltip(config.type, activeSeries)
        )
        setIsDefault(activeSeries?.Tooltip?.type !== TooltipType.CUSTOM)
    }

    function handleDone() {
        if (changesMade)
            if (selectedField?.type === 'series') {
                // dispatch({
                //     type: 'UPDATE_ACTIVE_SERIES',
                //     payload: { Tooltip: isDefault ? { type: TooltipType.DEFAULT } : draft },
                // })
                if (selectedField?.subSeries === 'pivot') {
                    dispatch({
                        type: 'UPDATE_ACTIVE_SERIES',
                        payload: {
                            Tooltip: isDefault
                                ? { type: TooltipType.DEFAULT, TooltipText: '', TooltipConfigs: [] }
                                : draft,
                            isPivot: true,
                        },
                    })
                }
                if (!selectedField?.subSeries) {
                    dispatch({
                        type: 'UPDATE_ACTIVE_SERIES',
                        payload: {
                            Tooltip: isDefault
                                ? { type: TooltipType.DEFAULT, TooltipText: '', TooltipConfigs: [] }
                                : draft,
                        },
                    })
                }
            } else {
                return
            }
        setSelectedConfigIndex(null)
        setOpen(false)
    }

    function handleClose() {
        init()
        setOpen(false)
    }

    function handleEnableTooltip(e) {
        const disabled = !e.target.checked
        // dispatch({
        //     type: 'UPDATE_ACTIVE_SERIES',
        //     payload: { ...activeSeries, Tooltip: { ...activeSeries.Tooltip, disabled } },
        // })
        if (selectedField?.type === 'series') {
            if (selectedField?.subSeries === 'pivot') {
                dispatch({
                    type: 'UPDATE_ACTIVE_SERIES',
                    payload: { ...activeSeries, Tooltip: { ...activeSeries.Tooltip, disabled }, isPivot: true },
                })
            }
            if (!selectedField?.subSeries) {
                dispatch({
                    type: 'UPDATE_ACTIVE_SERIES',
                    payload: { ...activeSeries, Tooltip: { ...activeSeries.Tooltip, disabled } },
                })
            }
        } else {
            return
        }
    }

    function handleDefaultCheckbox(e) {
        const { checked } = e.target
        if (!checked) return

        setIsDefault(checked)
        setDraft(makeDefaultTooltip(config.type, activeSeries))
        setChangesMade(true)
    }

    const suggestions = makeRawSuggestions(properties)
    const displayMarkdown = replaceMustacheValues(
        draft.TooltipText,
        draft.TooltipConfigs?.map(c => makeDisplayField(c, properties))
    )

    const selectedConfig = draft.TooltipConfigs?.[selectedConfigIndex]

    const selectedConfigProperty = getDataFieldProperty({ name: selectedConfig?.tipField }, properties)

    function handleChange(e, display: string, method: SuggestMethod) {
        if (method === 'up' || method === 'down' || method === 'escape') return

        let TooltipText = displayToMarkdown(draft.TooltipConfigs, display, properties)
        const TooltipConfigs = [...draft.TooltipConfigs]
        const currentValues = Array.from(display.matchAll(/\{\{!(.*?)!\}\}/g)).map(match => match[1])
        const existingValues = TooltipConfigs.map(config => config.tipField)

        const removedValues = existingValues.filter(value => !currentValues.includes(value))
        if (removedValues.length > 0) {
            removedValues.forEach(removedValue => {
                const index = TooltipConfigs.findIndex(config => config.tipField === removedValue)
                if (index > -1) {
                    TooltipConfigs.splice(index, 1)
                }
            })

            TooltipText = displayToMarkdown(TooltipConfigs, display, properties)
        }

        const newValue = display.match(/\{\{!(.*)!\}\}/)

        if (newValue) {
            e.preventDefault()

            const { 1: input, index: newIndex } = newValue
            const [valueType, value] = input.split(valueSeparator) as [NewValueType, string]
            let displayValue: string

            const updateConfigsAndMarkdown = (field: DataField) => {
                const uniqueId = `tooltip-${field.name}-${Math.random().toString(36).substr(2, 9)}`
                const configsBefore = TooltipConfigs.reduce((acc, _, i) => {
                    return (acc += TooltipText.indexOf(`{${i.toString()}}`) < newIndex ? 1 : 0)
                }, 0)

                displayValue = `${field.name}`
                TooltipConfigs.splice(configsBefore, 0, {
                    tipField: field.name,
                    id: uniqueId,
                    AggregationType: Aggregation.UNKNOWN,
                    ValueFormat: '',
                })

                new Array(TooltipConfigs.length - configsBefore - 1)
                    .fill(null)
                    .map((_, i) => [
                        configsBefore + i,
                        `{{${configsBefore + i + `{${TooltipConfigs[configsBefore + i + 1].tipField}}`}}}`,
                    ])
                    .reverse()
                    .forEach(([key, value]) => {
                        TooltipText = replaceMustacheValues(TooltipText, { [key]: value })
                    })
            }

            switch (valueType) {
                case 'values': {
                    const { field } = activeSeries.values[value]
                    updateConfigsAndMarkdown(field)
                    break
                }
                case 'property': {
                    const { container, id, name, semanticType } = properties.find(p => p.name === value)
                    const field = {
                        resourceType: container.type,
                        resourceId: container.id,
                        resourceName: container.name,
                        id,
                        name,
                        dataTypeId: semanticType.id,
                    }
                    updateConfigsAndMarkdown(field)
                    break
                }
                case 'text': {
                    displayValue = value
                    break
                }
                default:
                    console.log('Something is wrong')
            }

            TooltipText = TooltipText.replace(
                new RegExp(escapeRegExp(makeValue(valueType, value)), 'g'),
                `{${displayValue}}`
            )
        }

        setDraft(s => ({ ...s, TooltipText, TooltipConfigs }))
        handleChangesMade()
    }

    function handleKeyDown(e) {
        const ignoreKeys = [
            'ArrowLeft',
            'ArrowRight',
            'ArrowUp',
            'ArrowDown',
            'Meta',
            'Alt',
            'Shift',
            'Control',
            'End',
            'Home',
        ]
        if (ignoreKeys.includes(e.key)) return

        const { selectionStart, selectionEnd } = convertSelectionAndValue(e, draft, properties)

        const newMarkdown = draft.TooltipText.slice(0, selectionStart) + draft.TooltipText.slice(selectionEnd)
        if (draft.TooltipText === newMarkdown) return
        e.preventDefault()

        const TooltipConfigs = draft.TooltipConfigs
        const replacements = uniq(newMarkdown)
            .sort()
            .reduce((acc, cur, newIndex) => ({ ...acc, [cur]: `{{${newIndex}}}` }), {})
        const TooltipText = replaceMustacheValues(newMarkdown, replacements)

        setDraft(s => ({ ...s, TooltipConfigs, TooltipText }))
        handleChangesMade()
    }

    function handleKeyUp(e) {
        const { focusedConfigIndex } = convertSelectionAndValue(e, draft, properties)
        if (focusedConfigIndex !== selectedConfigIndex) setSelectedConfigIndex(focusedConfigIndex)
    }

    function handleClick(e) {
        if (!e.target.selectionStart) return

        const { focusedConfigIndex } = convertSelectionAndValue(e, draft, properties)
        if (focusedConfigIndex !== selectedConfigIndex) setSelectedConfigIndex(focusedConfigIndex)
    }

    function handleFormatChange(format) {
        setDraft(s =>
            produce(s, draft => {
                draft.TooltipConfigs[selectedConfigIndex].BaseFormat = format
            })
        )
        handleChangesMade()
    }
    function handleCurrencySymbolChange(currency) {
        setDraft(s =>
            produce(s, draft => {
                draft.TooltipConfigs[selectedConfigIndex].CurrencySymbol = currency
            })
        )
        handleChangesMade()
    }

    function handleDisplayFormatChange(format) {
        setDraft(s =>
            produce(s, draft => {
                draft.TooltipConfigs[selectedConfigIndex].ValueFormat = format
            })
        )
        handleChangesMade()
    }

    function handleAggregationChange(aggregation) {
        setDraft(s =>
            produce(s, draft => {
                draft.TooltipConfigs[selectedConfigIndex].AggregationType = aggregation
            })
        )
        handleChangesMade()
    }

    function handleChangesMade() {
        setChangesMade(true)
        setIsDefault(false)
    }

    return (
        <EditorWrapper header="Tooltip">
            <TheCheckbox label="Enabled" checked={!activeSeries?.Tooltip?.disabled} onChange={handleEnableTooltip} />
            <Button variant="outlined" onClick={() => setOpen(true)}>
                Edit
            </Button>

            <Dialog open={open} onClose={handleClose} classes={{ paper: classes.dialog }}>
                <DialogTitle>Tooltip Editor</DialogTitle>
                <DialogContent className={classes.content}>
                    <div>
                        <Typography variant="subtitle2">
                            <strong>Expression</strong>
                        </Typography>
                        <ExpressionEditor
                            expression={displayMarkdown || ''}
                            suggestions={suggestions}
                            onChange={handleChange}
                            textFieldProps={{
                                rows: 10,
                                className: classes.expressionWrapper,
                                label: '',
                                spellCheck: 'false',
                                onKeyDown: handleKeyDown,
                                onKeyUp: handleKeyUp,
                                onClick: handleClick,
                            }}
                        />
                        <Box display="flex">
                            <TheCheckbox label="default" checked={isDefault} onChange={handleDefaultCheckbox} />
                            <>
                                <div data-for="editorTooltip" data-tip className={classes.tooltipPreviewBox}>
                                    Preview sample
                                </div>

                                <ReactTooltip id="editorTooltip" place="bottom">
                                    <div className={classes.tooltipWrapper}>
                                        <Markdown>
                                            {makeTooltipDisplay(activeSeries, draft, activeSeriesIndex, dataResponse)}
                                        </Markdown>
                                    </div>
                                </ReactTooltip>
                            </>
                        </Box>
                    </div>
                    {selectedConfigProperty?.name && (
                        <div>
                            <ChopText variant="subtitle2" showEllipsis tooltipProps={{ placement: 'top' }}>
                                <strong>
                                    Property
                                    {selectedConfigProperty
                                        ? ` (${selectedConfigProperty?.displayName || selectedConfigProperty?.name})`
                                        : ''}
                                </strong>
                            </ChopText>
                            <div className={classes.displayFormatWrapper}>
                                {/* {!isRaw && ( */}
                                <AggregationPicker
                                    aggregation={selectedConfig?.AggregationType}
                                    onChange={handleAggregationChange}
                                />

                                <LabeledToggleButton
                                    label="Denomination:"
                                    // disabled={disabled}
                                    value={selectedConfig?.BaseFormat}
                                    onChange={(e, base) => handleFormatChange(base)}
                                    options={baseOptions}
                                />
                                <CurrencySymbolEditor
                                    label="Currency Symbol"
                                    value={selectedConfig?.CurrencySymbol}
                                    onChange={currency => {
                                        handleCurrencySymbolChange(currency)
                                    }}
                                />
                                <TextField
                                    value={selectedConfig?.ValueFormat || ''}
                                    onChange={event => {
                                        handleDisplayFormatChange(event.target.value)
                                    }}
                                    label="Display Format"
                                    variant="outlined"
                                />
                            </div>
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    {changesMade && <Button onClick={init}>Reset</Button>}
                    <Button variant="contained" color="primary" onClick={handleDone}>
                        {changesMade ? 'Save' : 'Done'}
                    </Button>
                </DialogActions>
            </Dialog>
        </EditorWrapper>
    )
}

interface AggregationPickerProps {
    aggregation: Aggregation
    onChange: (aggregation: Aggregation) => void
}

function AggregationPicker({ aggregation, onChange }: AggregationPickerProps) {
    const [aggregationMenuAnchor, setAggregationMenuAnchor] = useState(null)

    function handleAggregationChange(e, aggregation) {
        setAggregationMenuAnchor(null)
        onChange(aggregation)
    }

    const { label, Icon } = aggregationDetailDictionary[aggregation] ?? {}

    return (
        <>
            <Button
                variant="outlined"
                size="small"
                endIcon={<ExpandMore fontSize="small" />}
                startIcon={Icon ? <Icon fontSize="small" /> : undefined}
                onClick={e => setAggregationMenuAnchor(e.currentTarget)}
            >
                {label ?? 'Aggregation'}
            </Button>
            <Popover
                anchorEl={aggregationMenuAnchor}
                keepMounted
                open={Boolean(aggregationMenuAnchor)}
                onClose={() => setAggregationMenuAnchor(null)}
            >
                <AggregateMenu value={aggregation} onChange={handleAggregationChange} />
            </Popover>
        </>
    )
}

const emptyTooltip: CustomTooltip = { type: TooltipType.CUSTOM, TooltipText: '', TooltipConfigs: [] }

function displayToMarkdown(TooltipConfigs: CustomTooltip['TooltipConfigs'], display: string, properties: Property[]) {
    return TooltipConfigs.map((cur, ind) => [
        escapeRegExp(makeDisplayField(cur, properties)),
        `${cur.tipField}`,
    ]).reduce((acc, [key, value]) => acc.replace(new RegExp(key), value), display)
}

/** convert text area selection props from display to markdown format */
function convertSelectionAndValue(e, TooltipConfigs: CustomTooltip, properties: Property[]) {
    let selectionStart = e.target.selectionStart as number
    let selectionEnd = e.target.selectionEnd as number
    let markdownBuilder = e.target.value as string
    let focusedConfigIndex: number = null

    TooltipConfigs.TooltipConfigs.forEach((config, i) => {
        const displayField = makeDisplayField(config, properties)
        const markupField = config.tipField
        const regex = new RegExp(`\\b${displayField}\\b`, 'g')

        let match: RegExpExecArray | null

        while ((match = regex.exec(markdownBuilder)) !== null) {
            const matchStart = match.index
            const matchEnd = matchStart + displayField.length

            markdownBuilder =
                markdownBuilder.substring(0, matchStart) + markupField + markdownBuilder.substring(matchEnd)

            if (selectionStart > matchStart && selectionStart < matchEnd) {
                if (e.key === 'ArrowLeft' || selectionStart === matchEnd) {
                    selectionStart = matchEnd
                } else {
                    selectionStart = matchStart
                }
                focusedConfigIndex = i
            }

            if (selectionEnd > matchStart && selectionEnd < matchEnd) {
                selectionEnd = matchEnd
            }
        }
    })
    if (markdownBuilder !== TooltipConfigs.TooltipText) {
        console.log('Something wrong here..')
    }

    return { selectionStart, selectionEnd, focusedConfigIndex }
}

function makeDisplayField(TooltipConfigs: CustomTooltip['TooltipConfigs'][0], properties: any) {
    // const { aggregation, field } = { ...tooltipConfig.source }
    const aggregation = getDefaultAggregation(properties.semanticType)
    const tipField = TooltipConfigs?.tipField
    const { name } = { ...getDataFieldProperty({ name: tipField }, properties) }

    return aggregation === Aggregation.NONE ? `${name}` : `${name}`
}

/** escape all special characters from string for regex match */
const escapeRegExp = string => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

function makeRawSuggestions(properties: Property[]): Suggestion[] {
    if (!properties?.length) return []

    const fields = properties.map((p, i) => ({
        label: p.displayName,
        value: makeValue('property', `${p.name}`),
        description: `Value for ${p.displayName}`,
    }))

    return [
        {
            type: 'section',
            label: 'Properties',
            suggestions: fields,
        },
        {
            ...aggregateFunction.totalRecords,
            value: makeValue('text', aggregateFunction.totalRecords.valueString),
        },
    ]
}

// function makeAggregateSuggestions(
//     properties: Property[],
//     categories: Widget['categories'],
//     activeSeries: OneSeries
// ): Suggestion[] {
//     if (!activeSeries) return []
//     if (!properties?.length) return []

//     const { values, subSeries } = activeSeries
//     const primaryPropertyId = values[0]?.field.id
//     const primaryProperty = properties.find(p => p.id === primaryPropertyId)
//     const primaryTitle = primaryProperty?.displayName ?? primaryProperty?.name
//     const categoryTitles = letMeMap(categories).map(c => {
//         const { name, displayName } = properties.find(p => p.id === c.field.id) ?? {}
//         return displayName || name
//     })
//     const subSeriesProperty = subSeries ? properties.find(p => p.id === subSeries.field.id) : null
//     const subSeriesTitle = subSeriesProperty?.displayName || subSeriesProperty?.name || ''

//     const otherFields = properties.map((p, i) => ({
//         label: p.displayName,
//         value: makeValue('property', `${i}`),
//         description: `Value for ${p.displayName}`,
//         hide: p.name === primaryPropertyId || p.semanticType?.type !== 'Quantitative',
//     }))

//     const functions = [
//         {
//             ...aggregateFunction.valueTotal,
//             value: makeValue('text', aggregateFunction.valueTotal.valueString),
//             hide: !categoryTitles.length,
//         },
//         {
//             ...aggregateFunction.categoryValueTotal,
//             value: makeValue('text', aggregateFunction.categoryValueTotal.valueString),
//             hide: !subSeries,
//         },
//         {
//             ...aggregateFunction.percentOfTotal,
//             value: makeValue('text', aggregateFunction.percentOfTotal.valueString),
//             hide: !categoryTitles.length,
//         },
//         {
//             ...aggregateFunction.percentOfCategory,
//             value: makeValue('text', aggregateFunction.percentOfCategory.valueString),
//             hide: !subSeries,
//         },
//         {
//             ...aggregateFunction.totalRecords,
//             value: makeValue('text', aggregateFunction.totalRecords.valueString),
//         },
//         {
//             ...aggregateFunction.totalCategoryRecords,
//             value: makeValue('text', aggregateFunction.totalCategoryRecords.valueString),
//             hide: !categoryTitles.length,
//         },
//         {
//             ...aggregateFunction.totalSubSeriesRecords,
//             value: makeValue('text', aggregateFunction.totalSubSeriesRecords.valueString),
//             hide: !subSeries,
//         },
//     ]

//     const arithmeticFunctions = [
//         {
//             ...arithmeticFunction.addition,
//             value: makeValue('text', arithmeticFunction.addition.valueString),
//         },
//         {
//             ...arithmeticFunction.subtraction,
//             value: makeValue('text', arithmeticFunction.subtraction.valueString),
//         },
//         {
//             ...arithmeticFunction.multiplication,
//             value: makeValue('text', arithmeticFunction.multiplication.valueString),
//         },
//         {
//             ...arithmeticFunction.division,
//             value: makeValue('text', arithmeticFunction.division.valueString),
//         },
//     ]

//     return [
//         {
//             label: primaryTitle,
//             value: makeValue('values', '0'),
//             description: 'Primary value field and aggregation',
//         },
//         {
//             label: 'Category',
//             value: makeValue('text', `${categoryTitles[categoryTitles.length - 1]}: [category]`),
//             description: 'Active category name',
//             hide: !categoryTitles.length,
//         },
//         {
//             label: 'Sub-series',
//             value: makeValue('text', `${subSeriesTitle}: [sub-series]`),
//             description: 'Active category name',
//             hide: !subSeries,
//         },
//         {
//             type: 'section',
//             label: 'Other properties',
//             suggestions: otherFields,
//         },
//         {
//             type: 'section',
//             label: 'Arithmetic operations',
//             suggestions: arithmeticFunctions,
//         },
//         {
//             type: 'section',
//             label: 'Functions',
//             suggestions: functions,
//         },
//     ]
// }

const makeValue = (type: NewValueType, string: string) => `{{!${type}${valueSeparator}${string}!}}`

function TheCheckbox({ label, ...rest }) {
    return <FormControlLabel control={<Checkbox {...rest} />} label={label} />
}

function makeTooltipDisplay(
    config: SeriesConfig,
    draft: CustomTooltip,
    activeSeriesIndex: number,
    res: ParsedResponse
) {
    if (!res) return 'Data required..'
    if (config.type === ChartType.TABLE) return 'table tooltip..'

    const allSeriesWithDraft = produce(config, seriesDraft => {
        if (seriesDraft[activeSeriesIndex]) seriesDraft[activeSeriesIndex].tooltip = draft
    })

    const updatedConfig = { ...config, series: allSeriesWithDraft }
    const dataIndexes = {
        groupNames: getGroupNamesOfFirstPoint(res.data[activeSeriesIndex]?.data),
        series: activeSeriesIndex,
    }

    //@ts-ignore
    return makeTooltipMarkdown(updatedConfig, res.data, dataIndexes) || 'Data required..'
}

function getGroupNamesOfFirstPoint(dataGroups: DataGroup[]): string[] {
    if (!dataGroups?.length) return []
    const { group, children } = dataGroups[0]
    return [group, ...getGroupNamesOfFirstPoint(children)]
}

function propertyInsideFunction(markdown: string, configIndex: number) {
    return (
        markdown.indexOf(`${aggregateFunction.percentOfTotal.value}({{${configIndex}}})`) > -1 ||
        markdown.indexOf(`${aggregateFunction.percentOfCategory.value}({{${configIndex}}})`) > -1
    )
}
