import { useContext } from 'react'
import produce from 'immer'

import { PropertyType } from 'genesis-suite/types/architectureTypes'
import { Aggregation, Basket } from 'genesis-suite/types/visualTypes'
import { ConfigContext } from '../ConfigContext'
import DnDPropertySelector from './DnDPropertySelector'
import { getDataFieldProperty, getDefaultAggregation } from '../utils'
import { DragField, FieldPointer } from '../builderTypes'
import useWidgetColors from '../../../hooks/useWidgetColors'
import { useSemanticTypeById } from '../../../hooks/useSemanticTypes'

interface Props {
    basket?: Basket
    label?: string
    limit?: number
    isPivot?: boolean
}

const typeSeriesKeyMapping = {
    Chart: 'Series',
    Table: 'Fields',
}

/** for charts w/ multiple series (combo) */
export default function SeriesSelector({ basket, label, limit, isPivot }: Props) {
    const colors = useWidgetColors()
    const semanticTypeById = useSemanticTypeById()

    const { dataResponse, dispatch, selectedField, resources, config, configKey } = useContext(ConfigContext)
    const widgetConfig = config[configKey]
    const seriesKey = typeSeriesKeyMapping[config?.Type] || 'Series'

    const activeSeries = isPivot
        ? widgetConfig?.PivotFieldConfig?.['ValueFields']?.[
              selectedField?.type === 'series' && selectedField?.subSeries === 'pivot'
                  ? selectedField?.index
                  : undefined
          ]
        : widgetConfig?.[seriesKey]?.[selectedField?.type === 'series' ? selectedField?.index : undefined]

    const seriesValues = (isPivot ? widgetConfig?.PivotFieldConfig?.['ValueFields'] : widgetConfig?.[seriesKey])?.map(
        (seriesObj, index) => {
            const subSeries = dataResponse?.data?.[index]?.data[0]?.children?.[0]?.group
            return {
                field: {
                    name: seriesObj.FieldName,
                    id: seriesObj.FieldName,
                },
                service: {
                    id: resources.selectedId,
                    name: seriesObj.Source?.ElementName,
                    type: seriesObj.Source?.ElementType,
                    color: colors[index % colors.length],
                },
                pointer: { type: 'series', index, valueIndex: 0, subSeries },
                title: seriesObj.SeriesName,
            }
        }
    )

    const flatSeries = seriesValues?.map((v, i) => {
        const { properties } = resources.byId[v.service.id] ?? {}
        const enrichedField = getDataFieldProperty(v.field, properties)
        const initialAggregation = getDefaultAggregation(enrichedField.semanticType, enrichedField.hasAggregates)

        const updatedField = {
            resourceType: enrichedField.container?.type,
            resourceId: enrichedField.container?.id,
            resourceName: enrichedField.container?.name,
            id: enrichedField.id,
            name: enrichedField.name,
        }

        return {
            ...v,
            aggregation: initialAggregation,
            field: updatedField,
            type: enrichedField.semanticType?.type,
            title: enrichedField.name,
            selected:
                selectedField?.type === 'series' &&
                selectedField?.index === i &&
                (isPivot ? selectedField?.subSeries === 'pivot' : !selectedField?.subSeries),
            color: activeSeries?.Color,
            basket: basket,
        }
    })

    const handleAdd = (dragField: DragField, _index?: number) => {
        if (isPivot) {
            const seriesLength = widgetConfig?.PivotFieldConfig?.['ValueFields']?.length ?? 0
            const index = _index !== undefined ? _index : seriesLength
            const { pointer, type, ...field } = dragField
            const to: FieldPointer = { type: 'series', index, subSeries: 'pivot' }

            const { properties } = resources?.byId[resources.selectedId] ?? {}
            const { semanticType, hasAggregates } = getDataFieldProperty(field, properties)
            const aggregation = getDefaultAggregation(semanticType, hasAggregates)
            const source = { field, aggregation, basket }

            const options = pointer
                ? { operation: 'move' as const, from: pointer }
                : { operation: 'new' as const, source }

            dispatch({
                type: 'UPDATE_SELECTED_PROPERTY',
                payload: {
                    to,
                    // from,
                    isPivot: true,
                    limit,
                    ...options,
                    semanticTypeById,
                    pivotFieldConfig: {
                        ValueFields: [
                            ...(widgetConfig?.PivotFieldConfig?.ValueFields || []),
                            {
                                FieldName: source.field.name,
                                HeaderName: source.field.name,
                                Id: source.field.id,
                                DefaultSort: 'Ascending',
                                PropertyType: 'Property',
                                AggregationType: source.aggregation,
                            },
                        ],
                    },
                },
            })
        } else {
            const seriesLength = widgetConfig[seriesKey]?.length ?? 0
            const index = _index !== undefined ? _index : seriesLength
            const { pointer, type, ...field } = dragField
            const to: FieldPointer = { type: 'series', index }
            const { properties } = resources?.byId[resources.selectedId] ?? {}
            const { semanticType, hasAggregates } = getDataFieldProperty(field, properties)
            const aggregation = getDefaultAggregation(semanticType, hasAggregates)
            const source = { field, aggregation, basket }

            const options = pointer
                ? { operation: 'move' as const, from: pointer }
                : { operation: 'new' as const, source }

            dispatch({
                type: 'UPDATE_SELECTED_PROPERTY',
                payload: { to, limit, ...options, semanticTypeById },
            })
        }
    }

    const handleRemove = (index: number) => {
        dispatch({ type: 'SET_SELECTED_FIELD', payload: null })

        const newConfig = produce(config, draft => {
            if (isPivot) {
                const valueFields = draft[configKey].PivotFieldConfig.ValueFields
                valueFields.splice(index, 1)
                if (valueFields.length === 0) {
                    draft[configKey].PivotFieldConfig.ValueFields = null
                }
            } else {
                const series = draft[configKey][seriesKey]
                series.splice(index, 1)
                if (series.length === 0) {
                    draft[configKey][seriesKey] = null
                }
            }
        })

        dispatch({ type: 'UPDATE_CONFIG', payload: newConfig })
    }

    const handleFieldSelect = (i: number) => {
        if (isPivot) {
            dispatch({
                type: 'SET_SELECTED_FIELD',
                payload: { ...flatSeries[i].pointer, type: 'series', subSeries: 'pivot' },
            })
        } else
            dispatch({
                type: 'SET_SELECTED_FIELD',
                payload: { ...flatSeries[i].pointer, type: 'series' },
            })
    }

    const handleAggregationChange = (index: number, value: Aggregation) => {
        const payload = produce(config.series[index], draft => {
            draft.values[0].aggregation = value
        })
        dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload })
    }

    return (
        <DnDPropertySelector
            label={label}
            accept={[PropertyType.DEFINING, PropertyType.QUALITATIVE, PropertyType.QUANTITATIVE]}
            limit={limit}
            properties={flatSeries}
            onFieldSelect={handleFieldSelect}
            onAggregationChange={handleAggregationChange}
            onAdd={handleAdd}
            onRemove={handleRemove}
        />
    )
}
