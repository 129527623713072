import { ArrowDropDown, ContentCopy, Warning } from '@mui/icons-material'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import InsertPhotoRoundedIcon from '@mui/icons-material/InsertPhotoRounded'
import DownloadIcon from '@mui/icons-material/SaveAlt'
import {
    Box,
    Button,
    Divider,
    FormHelperText,
    IconButton,
    Input,
    InputAdornment,
    Menu,
    MenuItem,
    Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { isEqual } from 'lodash'
import { useSnackbar } from 'notistack'
import { useContext, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { Spinner } from 'genesis-suite/components'
import { Clipboard, Xls } from 'genesis-suite/icons'
import { sleep } from 'genesis-suite/utils'
import buildRoute from 'genesis-suite/utils/buildRoute'
import { routePaths } from '../../lib/routes'
import { applicationSelectors, moduleSelectors } from '../../selectors'
import { PerspectiveWidgetContext } from '../contexts/PerspectiveWidgetContext'
import useWidgetExport from './hooks/useWidgetExport'
import { ContentType, copyToClipboard, downloadFile, getImageBlob } from './lib/widgetExporting'

const useStyles = makeStyles(({ palette, spacing }) => ({
    container: {
        width: '100%',
        height: '100%',
        marginBottom: spacing(2),
    },
    urlContainer: {
        marginBottom: spacing(3),
    },
    successText: {
        color: palette.status.success,
    },
    warningContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '15px',
    },
    warningIcon: {
        color: palette.status.warning,
        marginRight: spacing(2),
    },
    divider: {
        width: 1,
    },
    imageWrapper: {
        width: '100%',
        marginTop: '15px',
        height: '300px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    widgetImage: {
        width: '100%',
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'contain',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    wrapBottomBtns: {
        marginTop: '15px',
    },
    bottomBtn: {
        marginRight: '10px',
    },
}))

const WidgetShare = ({ widgetConfig, imgParams }) => {
    const { enqueueSnackbar: showSnackbar } = useSnackbar()

    const [anchorEl, setAnchorEl] = useState(null)

    const [copyStatus, setCopyStatus] = useState(false)
    const [widgetImageUrl, setWidgetImageUrl] = useState(false)
    const [widgetImage, setWidgetImage] = useState(null)
    const [loadingImg, setLoadingImg] = useState(false)
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const moduleName = useSelector(moduleSelectors.getModuleName)
    const classes = useStyles()
    const { Id: widgetId } = widgetConfig

    const {
        location: { protocol, hostname, port },
    } = window
    const url = `${protocol}//${hostname}${port ? `:${port}` : ''}`
    const path = buildRoute(routePaths.PERSPECTIVE, appName, moduleName, widgetId)
    const widgetUrl = new URL(`${url}${path}`)
    widgetUrl.searchParams.append('source', 'widget_share')

    const isTable = widgetConfig.Type.toUpperCase() === 'TABLE'
    const { exportWidget } = useWidgetExport(widgetConfig)
    const params = useRef(imgParams)
    if (!isEqual(params.current, imgParams)) params.current = imgParams
    const makeImage = Boolean(params.current.exportType)

    const dOptionsOpen = Boolean(anchorEl)

    const { setIsCopying } = useContext(PerspectiveWidgetContext)

    const handleCopy = () => {
        const { navigator } = window
        navigator.clipboard
            .writeText(widgetUrl)
            .then(() =>
                setCopyStatus({
                    message: 'Copied to clipboard!',
                })
            )
            .catch(() =>
                setCopyStatus({
                    error: true,
                    message: 'Could not copy url to clipboard',
                })
            )
    }

    const copyImage = async () => {
        try {
            let contentType = ContentType.PNG
            await copyToClipboard(widgetImage, contentType)
            showSnackbar('Image copied', { variant: 'success' })
        } catch (e) {
            console.error(e)
            showSnackbar('Failed to copy image', { variant: 'error' })
        }
        setAnchorEl(null)
    }

    async function handleExportImage(contentType) {
        const { title, Title } = widgetConfig

        try {
            await downloadFile(widgetImage, title || Title, contentType)
        } catch (e) {
            console.error(e)
            showSnackbar('Failed to export image', { variant: 'error' })
        }
        setAnchorEl(null)
    }

    const onGenerateImage = async () => {
        const { exportOptions, exportType, visualRef } = params.current
        try {
            setLoadingImg(true)

            if (isTable) {
                setIsCopying(true)
                await sleep(1500)
            }

            const imageBlob = await getImageBlob(exportType, visualRef, exportOptions)
            setWidgetImage(imageBlob)
            const imageURL = URL.createObjectURL(imageBlob)

            if (isTable) setIsCopying(false)

            setWidgetImageUrl(imageURL)
            setLoadingImg(false)
        } catch (e) {
            console.error(e)
            setLoadingImg(false)
        }
    }

    return (
        <div className={classes.container}>
            <div className={classes.urlContainer}>
                <Input
                    readOnly
                    fullWidth
                    value={widgetUrl}
                    sx={{ '&::before': { borderColor: 'text.primary' } }}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton sx={{ color: 'text.primary' }} onClick={handleCopy} size="large">
                                <Divider className={classes.divider} orientation="vertical" />
                                <Clipboard />
                            </IconButton>
                        </InputAdornment>
                    }
                />
                <FormHelperText error={copyStatus?.error} className={classes.successText}>
                    {copyStatus.message}
                </FormHelperText>
            </div>
            <div className={classes.warningContainer}>
                <Warning className={classes.warningIcon} />
                <Typography variant="body1" display="inline">
                    URL sharing does not currently support filters and other contextual information. Users you share
                    this url with will see this widget without any context applied and the data may be different than
                    shown during your browsing session.
                </Typography>
            </div>

            {makeImage ? (
                <>
                    <div className={classes.imageWrapper}>
                        <Spinner show={loadingImg} size={16}>
                            {widgetImageUrl ? (
                                <img onClick={copyImage} className={classes.widgetImage} src={widgetImageUrl} />
                            ) : (
                                !loadingImg && (
                                    <Button
                                        type="button"
                                        variant="contained"
                                        startIcon={<InsertPhotoRoundedIcon />}
                                        onClick={onGenerateImage}
                                    >
                                        Generate Image
                                    </Button>
                                )
                            )}
                        </Spinner>
                    </div>

                    <div className={classes.wrapBottomBtns}>
                        {isTable && (
                            <Button
                                type="button"
                                variant="contained"
                                startIcon={<Xls />}
                                className={classes.bottomBtn}
                                onClick={exportWidget}
                            >
                                Export
                            </Button>
                        )}
                        <Button
                            type="button"
                            variant="contained"
                            startIcon={<DownloadIcon />}
                            endIcon={<ArrowDropDown />}
                            onClick={event => setAnchorEl(event.currentTarget)}
                            className={classes.bottomBtn}
                            disabled={!widgetImageUrl}
                        >
                            Download Image
                        </Button>
                        <Menu
                            id="download-image-menu"
                            anchorEl={anchorEl}
                            open={dOptionsOpen}
                            onClose={() => setAnchorEl(null)}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={() => handleExportImage(ContentType.JPEG)}>Download as JPEG</MenuItem>
                            <MenuItem onClick={() => handleExportImage(ContentType.PNG)}>Download as PNG</MenuItem>
                        </Menu>
                        <Button
                            type="button"
                            variant="contained"
                            startIcon={<ContentCopy />}
                            className={classes.bottomBtn}
                            onClick={copyImage}
                            disabled={!widgetImageUrl}
                        >
                            Copy Image
                        </Button>
                    </div>
                    <Box display="flex" alignItems="center" gap={1} mt={2}>
                        <InfoIcon fontSize="small" />
                        <Typography variant="body2">{`${
                            isTable ? 'Export,' : ''
                        } Download Image, and Copy Image options will be removed soon as these options already available in widget Quick Actions for better accessibility`}</Typography>
                    </Box>
                </>
            ) : (
                <Box display="flex" alignItems="center" gap={1}>
                    <InfoIcon fontSize="small" />
                    <Typography>Image generating is not support for this widget</Typography>
                </Box>
            )}
        </div>
    )
}

export default WidgetShare
