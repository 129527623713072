import React, { useContext, useState } from 'react'
import BarIcon from '@mui/icons-material/BarChartRounded'
import AreaIcon from '@mui/icons-material/NetworkCell'
import LineIcon from '@mui/icons-material/ShowChart'
import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { DebouncedTextField } from 'genesis-suite/components'
import { PropertyType } from 'genesis-suite/types/architectureTypes'
import { ColumnSeries, LineSeries } from 'genesis-suite/types/visualTypes'
import { isEmpty } from 'lodash'
import LabeledToggleButton from '../../../LabeledToggleButton'
import AggregationSelect from '../../AggregationSelect'
import { ConfigContext } from '../../ConfigContext'
import DnDPropertySelector from '../../selectors/DnDPropertySelector'
import FieldLabel from '../FieldLabel'
import ChartFormatEditor from '../FormatEditor/ChartFormatEditor'
import SeriesFormatEditor from './SeriesFormatEditor'
import SeriesTooltipEditor from './SeriesTooltipEditor'
import TableCellFormatEditor from './TableCellFormatEditor'
import TableHyperlinkEdtor from './TableHyperlinkEdtor'
import { getAllProperties, getDataFieldProperty, getDisplayProperty } from '../../utils'
import FilterSelector from '../../selectors/FilterSelector'

const useStyles = makeStyles(({ spacing }) => ({
    group: { marginBottom: spacing() },
    stackingInput: { width: '85px' },
}))

type Shape = LineSeries['shape'] | ColumnSeries['type']

const comboOptions: Array<{ label: any; value: Shape; tip: string }> = [
    { label: <BarIcon />, value: 'bar', tip: 'Bar' },
    { label: <LineIcon />, value: 'spline', tip: 'Smooth' },
    { label: <LineIcon />, value: 'line', tip: 'Straight' },
    { label: <AreaIcon />, value: 'area', tip: 'Area' },
]

const NO_STACK = 'None'

export const typeConfigKeyMapping = {
    Chart: 'ChartSeriesConfig',
    Table: 'TableFieldConfig',
}

export const typeSeriesKeyMapping = {
    Chart: 'Series',
    Table: 'Fields',
}

export default function SeriesEditor2({ parentProperty }) {
    const { selectedField, dispatch, configStructure, resources, config, configKey } = useContext(ConfigContext)
    const fieldsConfigKey = typeConfigKeyMapping[config?.Type]
    const seriesKey = typeSeriesKeyMapping[config?.Type]
    const seriesConfig = configStructure[fieldsConfigKey]
    const classes = useStyles()

    const [p, setP] = useState<{ [key: string]: any[] }>({})
    const properties = getAllProperties(resources.byId)

    if (!seriesConfig || !config || !selectedField) return null

    let widgetConfig
    let activeSeries

    if (selectedField?.type === 'series') {
        if (selectedField?.subSeries === 'pivot') {
            widgetConfig = config[configKey]?.PivotFieldConfig
            activeSeries = widgetConfig?.ValueFields?.[selectedField.index]
        } else {
            widgetConfig = config[configKey]
            activeSeries = widgetConfig?.[seriesKey]?.[selectedField.index]
        }
    } else {
        return null
    }

    const seriesFields = seriesConfig.filter(field => field.DisplayGroup === parentProperty)

    if (isEmpty(seriesFields) || !activeSeries) return null

    const handleTypeChange = (e, val: Shape) => {
        const smoothCurves = val === 'spline'
        const type = val === 'spline' ? 'line' : val
        updateActiveSeries({ SeriesType: type, SmoothCurves: smoothCurves })
    }

    function updateActiveSeries(payload: Partial<LineSeries | ColumnSeries>) {
        if (selectedField?.type === 'series') {
            if (selectedField?.subSeries === 'pivot') {
                dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload: { ...payload, isPivot: true } })
            } else {
                dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload: { ...payload } })
            }
        }
    }

    function handleAdd(key, value) {
        const property = getDataFieldProperty({ name: value.name }, properties)
        const propVal = getDisplayProperty({ name: value }, property)
        setP(prev => ({
            ...prev,
            [key]: [propVal],
        }))
        if (selectedField?.type === 'series') {
            dispatch({
                type: 'UPDATE_ACTIVE_SERIES',
                payload: { [key]: value.name || '' },
            })
        }
    }

    function handleRemove(key, index: number) {
        setP(prev => {
            const updatedProperties = { ...prev }
            updatedProperties[key] = updatedProperties[key].filter((_, i) => i !== index)
            return updatedProperties
        })
        if (selectedField?.type === 'series') {
            if (selectedField?.subSeries === 'pivot') {
                dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload: { [key]: null, isPivot: true } })
            } else {
                dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload: { [key]: null } })
            }
        }
    }

    return (
        <>
            <Box overflow="auto" flex="1">
                {seriesFields.map((field, index) => {
                    if (field.Name === 'SeriesType') {
                        return (
                            <div className={classes.group} key={index}>
                                <FieldLabel field={field} />
                                <LabeledToggleButton
                                    value={
                                        activeSeries.SeriesType === 'line' && activeSeries.SmoothCurves
                                            ? 'spline'
                                            : activeSeries.SeriesType
                                    }
                                    label=""
                                    onChange={handleTypeChange}
                                    options={comboOptions}
                                />
                            </div>
                        )
                    }

                    if (field.IsDisplayName) {
                        return (
                            <Box py={1} key={index}>
                                <FieldLabel field={field} />
                                <DebouncedTextField
                                    size="small"
                                    variant="outlined"
                                    label={field.DisplayName}
                                    fullWidth
                                    value={activeSeries?.[field.Name] ?? ''}
                                    onChange={title => updateActiveSeries({ [field.Name]: title })}
                                />
                            </Box>
                        )
                    }

                    if (field.Name === 'AggregationType') {
                        return (
                            <div key={index}>
                                <AggregationSelect />
                            </div>
                        )
                    }

                    if (field.IsSourceProperty) {
                        return (
                            <div key={index}>
                                <FieldLabel field={field} />
                                <DnDPropertySelector
                                    key={index}
                                    accept={[
                                        PropertyType.DEFINING,
                                        PropertyType.QUALITATIVE,
                                        PropertyType.QUANTITATIVE,
                                    ]}
                                    properties={p[field.Name] || []}
                                    limit={field.IsMultiSelect ? null : 1}
                                    onAdd={value => handleAdd(field.Name, value)}
                                    onRemove={value => handleRemove(field.Name, value)}
                                />
                            </div>
                        )
                    }
                })}

                {/* {config?.Type === 'Chart' ? <FilterSelector /> : null} */}
                <SeriesFormatEditor parentProperty="ValueFormat" />
                <TableHyperlinkEdtor fieldsConfigKey="TableFieldConfig" />
                <TableCellFormatEditor fieldsConfigKey="CellFormat" />
                <ChartFormatEditor />
                <SeriesTooltipEditor />
            </Box>
        </>
    )
}
