import React, { useContext } from 'react'
import { Checkbox, Collapse, FormControlLabel, MenuItem, TextField } from '@mui/material'

import { Legend } from 'genesis-suite/types/visualTypes'
import { ConfigContext } from '../ConfigContext'
import EditorWrapper from './EditorWrapper'

export default function LegendEditor() {
    const { config, dispatch, configKey, configStructure } = useContext(ConfigContext)

    if (!configKey || !config[configKey]) return null

    const legendFields = configStructure[configKey]?.filter(field => field.DisplayGroup === 'Legend')

    if (legendFields?.length <= 0) return null

    const { ShowLegend, LegendLineCount, LegendLocation = 'top' } = config[configKey]

    function handleUpdate(payload) {
        dispatch({ type: 'UPDATE_LEGEND', payload })
    }

    return (
        <EditorWrapper header="Legend">
            <FormControlLabel
                control={
                    <Checkbox
                        checked={ShowLegend}
                        onChange={e => handleUpdate({ ShowLegend: e.target.checked, LegendLocation: 'top' })}
                    />
                }
                label="Enabled"
            />

            <Collapse in={ShowLegend}>
                <TextField
                    select
                    fullWidth
                    margin="dense"
                    label="Placement"
                    value={LegendLocation}
                    onChange={e => handleUpdate({ LegendLocation: e.target.value })}
                >
                    {placementOptions.map(({ value, label }) => (
                        <MenuItem key={value} value={value}>
                            {label}
                        </MenuItem>
                    ))}
                </TextField>

                <Collapse in={LegendLocation === 'top' || LegendLocation === 'bottom'}>
                    <TextField
                        fullWidth
                        type="number"
                        margin="dense"
                        inputProps={{ min: '1' }}
                        label="Max rows"
                        value={LegendLineCount}
                        onChange={e => handleUpdate({ LegendLineCount: parseInt(e.target.value) })}
                    >
                        {placementOptions.map(({ value, label }) => (
                            <MenuItem key={value} value={value}>
                                {label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Collapse>
            </Collapse>
        </EditorWrapper>
    )
}

const placementOptions: { value: Legend['placement']; label: string }[] = [
    { value: 'top', label: 'Top' },
    { value: 'left', label: 'Left' },
    { value: 'right', label: 'Right' },
    { value: 'bottom', label: 'Bottom' },
]
