import { Button, CircularProgress, IconButton, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { WidgetActionDynamicStatus } from 'genesis-suite/types/visualTypes'
import { useIsMobile } from '~/hooks/useIsMobile'
import useWidgetAction from './useWidgetAction'
import useWidgetActionCall from './useWidgetActionCall'

interface WidgetActionButtonProps {
    action: any
    onClick: (action: any) => void
    config: any
    filters: any
    inlineFilters: any
    networkContext: any
    hidden?: boolean
    isPopupWidget?: boolean
}

const useStyles = makeStyles(() => ({
    buttonText: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontWeight: 700,
    },
}))

const { DISABLE, HIDE } = WidgetActionDynamicStatus

export default function WidgetActionButton({
    action,
    onClick,
    filters,
    config,
    inlineFilters,
    networkContext,
    hidden,
    isPopupWidget,
}: WidgetActionButtonProps) {
    const [onActionClick, icon] = useWidgetAction(action, isPopupWidget)
    const handleClick = onClick ? () => onClick(action) : onActionClick
    const classes = useStyles()

    const [isLoading, actionStatus] = useWidgetActionCall({ action, config, filters, inlineFilters, networkContext })

    const title = action.ShortName
    const toolTip = action.ActionName ?? title
    const isMobile = useIsMobile()
    const sx = {
        zIndex: 999,
        mx: 0.5,
        border: 1,
        fontWeight: 'normal',
        bgcolor: 'secondary.light',
        borderColor: 'secondary.main',
        color: 'secondary.contrastText',
        minWidth: '100px',
        maxWidth: '150px',
        padding: '5px 8px',
        borderRadius: '20px',
        visibility: hidden ? 'hidden' : 'visible',
        '&:hover': {
            '.MuiSvgIcon-root, span': {
                color: 'text.primary',
            },
        },
        '&.Mui-disabled': {
            cursor: 'not-allowed',
            pointerEvents: 'auto',
        },
    }

    if (actionStatus === HIDE) return

    return (
        <Tooltip title={toolTip}>
            {title ? (
                <Button
                    disabled={actionStatus === DISABLE}
                    fullWidth={isMobile}
                    sx={sx}
                    onClick={handleClick}
                    startIcon={isLoading ? <CircularProgress size={20} /> : icon}
                >
                    <span className={classes.buttonText}>{isLoading ? 'Loading...' : title}</span>
                </Button>
            ) : (
                <IconButton sx={sx} disabled={actionStatus === DISABLE} onClick={handleClick} size="large">
                    {isLoading ? <CircularProgress size={20} /> : icon}
                </IconButton>
            )}
        </Tooltip>
    )
}
