import { messengerType } from '../actions/types'

//Todo: Add Types after finalization
const initialState: any = {
    messageList: [],
    userList: [],
    userChatList: [],
    archivedList: [],
    showLoader: false,
    hasMore: false,
    currentPage: 1,
    archivedCurrentPage: 1,
    hasMoreArchived: false,
}

export default (state = initialState, action): any => {
    const { payload } = action
    switch (action.type) {
        case messengerType.TOGGLE_LOADER:
            return { ...state, showLoader: !state.showLoader }
        case messengerType.UPDATE_USER_LIST:
            return { ...state, userList: payload }
        case messengerType.UPDATE_MESSAGES_LIST:
            return { ...state, messageList: payload }
        case messengerType.UPDATE_ARCHIVE_MESSAGES_LIST:
            return { ...state, archivedList: payload }
        case messengerType.UPDATED_CHAT_LIST:
            return { ...state, messageList: [...state.messageList, ...payload] }
        case messengerType.UPDATE_USER_CHAT_LIST:
            return { ...state, userChatList: payload }
        case messengerType.UPDATE_USER_MESSAGES:
            return {
                ...state,
                messageList: payload.messageList,
                userList: payload.userList,
            }
        case messengerType.UPDATE_PAGINATION:
            return {
                ...state,
                hasMore: payload.hasMore,
                currentPage: payload.currentPage,
            }
        case messengerType.UPDATE_ARCHIVED_PAGINATION:
            return {
                ...state,
                hasMoreArchived: payload.hasMoreArchived,
                archivedCurrentPage: payload.archivedCurrentPage,
            }
        default:
            return state
    }
}
