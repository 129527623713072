import { ButtonBase, Collapse, Skeleton, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import isEmpty from 'lodash/isEmpty'

import { ChopText } from 'genesis-suite/components'
import { NodeResource } from 'genesis-suite/types/networkTypes'
import { useState } from 'react'
import CollapsableTwist from '../CollapsableTwist'

const useStyles = makeStyles(({ spacing, palette }) => {
    return {
        profileRootNode: { margin: spacing(0.5) },
        title: { flex: 1, marginLeft: spacing() },
        grid: {
            display: 'grid',
            gridTemplateColumns: `repeat(auto-fit, minmax(240px, 1fr))`,
            overflow: 'auto',
            paddingLeft: spacing(),
        },
        propertyContainer: {
            background: palette.background.main,
            padding: spacing(0, 1),
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
            gap: spacing(),
        },
        icon: {
            color: palette.text.primary,
        },
        accordion: {
            background: palette.background.main,
            boxShadow: 'none',
            '&.Mui-expanded': {
                margin: 0,
            },
            '&::before': {
                height: 0,
            },
        },
        accordionSummary: {
            padding: spacing(0, 1),
            flexDirection: 'row',
            width: '100%',
            '&.MuiAccordionSummary-root': {
                minHeight: '24px',
            },
        },
        nodeKey: { overflow: 'visible' },
        rootHeader: { justifyContent: 'flex-start', width: '100%' },
    }
})

interface Props {
    node: NodeResource
    data: { [propertyName: string]: number | string }
    hasLinked: boolean
    loading: boolean
}

export default function ProfileRootNode({ node, data, hasLinked, loading }: Props) {
    const { name, properties, profile } = node || {}
    const [open, setOpen] = useState(true)

    const primaryPropertyName = properties?.find(p => p.isPrimary)?.name ?? `${name}Id`
    let displayNameField = properties?.find(p => p.name?.toLowerCase() === `${name}Name`.toLowerCase())
    if (!displayNameField)
        displayNameField = properties?.find(p => p.semanticType?.name?.toLowerCase().includes('name'))
    if (!displayNameField)
        displayNameField = properties?.find(p => p.semanticType?.name?.toLowerCase().includes('description'))
    const displayNameFieldName = displayNameField ? displayNameField.name : primaryPropertyName

    const displayData = profile?.properties
        .filter(p => data && p.name in data)
        .map(({ id, name }) => {
            const property = properties.find(p => p.id === id)
            return { name: property?.name, displayName: property?.displayName, value: data[name] }
        })
    const classes = useStyles({ hasLinked })

    const headerValue = displayData?.find(d => d.name === displayNameFieldName)?.value ?? ''

    const content = loading ? (
        <div className={classes.grid}>
            <SkeletonLoader />
        </div>
    ) : isEmpty(data) ? (
        <Typography>No profile data available.</Typography>
    ) : (
        <>
            <ButtonBase className={classes.rootHeader} onClick={() => setOpen(o => !o)}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        maxWidth: 'calc(100% - 35px)',
                    }}
                >
                    <CollapsableTwist open={open} />
                    <ChopText variant="subtitle1" fontWeight={600} showEllipsis tooltipProps={{ placement: 'top' }}>
                        {headerValue}
                    </ChopText>
                </div>
            </ButtonBase>
            <Collapse in={open}>
                <div className={classes.grid}>
                    {displayData?.map(({ displayName, value }) => (
                        <div key={name} className={classes.propertyContainer}>
                            <ChopText
                                variant="subtitle1"
                                fontWeight={600}
                                tooltipProps={{ placement: 'top' }}
                                className={classes.nodeKey}
                            >
                                {displayName} :
                            </ChopText>
                            <ChopText showEllipsis variant="subtitle1" tooltipProps={{ placement: 'bottom' }}>
                                {value}
                            </ChopText>
                        </div>
                    ))}
                </div>
            </Collapse>
        </>
    )

    return <div className={classes.profileRootNode}>{content}</div>
}

function SkeletonLoader() {
    return (
        <>
            <div>
                <Skeleton variant="text" width="60%" height={24} />
                <Skeleton variant="text" width="80%" height={28} />
            </div>
            <div>
                <Skeleton variant="text" width="30%" height={24} />
                <Skeleton variant="text" width="90%" height={28} />
            </div>
        </>
    )
}
