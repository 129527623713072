import React, { useState } from 'react'
import MoreIcon from '@mui/icons-material/MoreVertRounded'
import { MenuIcon } from 'genesis-suite/components'
import { ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import ExpandLess from '@mui/icons-material/ExpandLessRounded'
import ExpandMore from '@mui/icons-material/ExpandMoreRounded'
const styles = ({ palette, border }) => {
    const topBarColor = palette.background.topBar
    const topBarContrast = palette.getContrastText(topBarColor)

    return {
        menu: {
            backgroundColor: topBarColor,
            borderRadius: border.radius.round,
            minWidth: 150,
            color: topBarContrast,
        },
        icon: {
            color: topBarContrast,
        },
    }
}

/**
 * Renders a 3 dot menu and popover options
 * @param {array} props.items - array of items to be rendered into the menu in the form { name, icon, click, hide }
 */
const Menu = ({ classes, items, iconClass, iconProps, buttonStyle, onToggle }) => {
    const [open, setOpen] = useState(false)
    const [submenuOpen, setSubmenuOpen] = useState({})

    if (items.filter(item => !item.hide).length === 0) return null

    const closeMenu = () => setOpen(false)

    function handleToggle() {
        const updatedOpen = !open
        onToggle?.(updatedOpen)
        setOpen(updatedOpen)
    }
    const toggleSubmenu = name => {
        setSubmenuOpen(prev => ({
            ...prev,
            [name]: !prev[name],
        }))
    }

    return (
        <MenuIcon
            buttonProps={{ sx: buttonStyle }}
            open={open}
            onClick={handleToggle}
            onClose={handleToggle}
            icon={<MoreIcon {...iconProps} className={iconClass} />}
            tooltip="More Options"
            noPadding
        >
            <MenuList className={classes.menu}>
                {items
                    .filter(i => !i.hide)
                    .map(item => (
                        <React.Fragment key={item.name}>
                            <MenuItem
                                key={item.name}
                                onClick={() => {
                                    if (item.children && item.children.length > 0) {
                                        toggleSubmenu(item.name)
                                    } else {
                                        item.click()
                                        setOpen(false)
                                    }
                                }}
                            >
                                <ListItemIcon className={classes.icon}>{item.icon}</ListItemIcon>
                                <ListItemText>{item.name}</ListItemText>
                                {item.children &&
                                    item.children.length > 0 &&
                                    (submenuOpen[item.name] ? <ExpandLess /> : <ExpandMore />)}
                            </MenuItem>
                            {item.children && item.children.length > 0 && submenuOpen[item.name] && (
                                <MenuList sx={{ pl: 2 }}>
                                    {item.children.map(child => (
                                        <MenuItem
                                            key={child.name}
                                            onClick={() => {
                                                child.click()
                                                setSubmenuOpen({})
                                                setOpen(false)
                                            }}
                                        >
                                            {child?.icon ? <ListItemIcon>{child?.icon}</ListItemIcon> : null}
                                            <ListItemText>{child?.name}</ListItemText>
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            )}
                        </React.Fragment>
                    ))}
            </MenuList>
        </MenuIcon>
    )
}

export default withStyles(styles)(Menu)
