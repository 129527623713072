import { useContext } from 'react'
import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { SeriesConfig } from 'genesis-suite/types/visualTypes'

import validateConfig from './widgets2/utils/validateConfig'
import BasicFields from './edit_widget/config_fields/BasicFields'
import FieldEditor2 from './edit_widget/config_fields/FieldEditor2'
import { ConfigContext, DataFields, EditWidget } from './edit_widget'
import { TableStyleChangesProvider } from './widgets2/contexts/TableStyleChangesContext'

const columnWidth = 236

const useStyles = makeStyles(({ palette, spacing }) => {
    const baseColumn = {
        overflow: 'hidden',
        display: 'flex',
        flexFlow: 'column nowrap',
        paddingTop: spacing(),
    }

    return {
        controller: {
            position: 'relative',
            display: 'grid',
            gridTemplateColumns: `30% 40% 30%`,
            width: '60%',
        },
        dataColumn: { ...baseColumn, backgroundColor: palette.background.sideNav, zIndex: 2 },
        column: { ...baseColumn, paddingLeft: spacing(2) },
        widget: {
            width: '40%',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '8px',
            padding: spacing(),
        },
    }
})

interface Props {
    isNew: boolean
    onDone: (config?: SeriesConfig) => Promise<void>
}

export default function EditSeriesWidget({ isNew, onDone }: Props) {
    const classes = useStyles()
    const { config, isDirty, onReset } = useContext(ConfigContext)

    const isValid = validateConfig(config)

    return (
        <Box flex={1} display="flex" flexDirection="column" overflow="hidden">
            <TableStyleChangesProvider>
                <Box flex={1} display="flex" overflow="hidden" width="100%">
                    <div className={classes.controller}>
                        <DataFields className={classes.dataColumn} />
                        <BasicFields className={classes.column} />
                        <FieldEditor2 className={classes.column} />
                        {/* <AdvancedFilterEditorPanel /> */}
                        {/* <CalculatedPropertyEditor /> */}
                    </div>

                    <EditWidget className={classes.widget} />
                </Box>
            </TableStyleChangesProvider>
        </Box>
    )
}
