import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { DefaultErrorBoundary } from 'genesis-suite/components'
import { useWidgetData } from '../../hooks/useWidgetData'
import { filterSelectors, widgetSelectors } from '../../../../selectors'
import { createFetchConfig } from '../../../../lib/utils'
import TopLabelWidget from './TopLabelWidget'
import { useIsMobile } from '~/hooks/useIsMobile'
import MobileTopLabelWidget from './MobileTopLabelWidget'

const labelHeight = 90

export default function TopLabels({ configs, isCompare, isEdit, orientation = 'horizontal' }) {
    const normalContext = useSelector(filterSelectors.getCoord)
    const compareContext = useSelector(filterSelectors.getCompareCoord)
    const normalFilters = useSelector(filterSelectors.currentFiltersConfiguration)
    const compareFilters = useSelector(filterSelectors.currentCompareFiltersConfiguration)
    const inlineFilters = useSelector(filterSelectors.getInlineFilters)

    const filters = isCompare ? compareFilters : normalFilters
    const networkContext = isCompare ? compareContext : normalContext
    const topLabelWidgets = useSelector(state => state.widgets.topLabelWidgets)
    const isMobile = useIsMobile()
    const isLandscapeMode = window.innerWidth > window.innerHeight

    const props = { networkContext, filters, inlineFilters }

    if (!configs?.length) return null

    return (
        <DefaultErrorBoundary>
            {configs.map(c => (
                <Labels key={c.Id} {...props} config={c} />
            ))}
            {isMobile && !isLandscapeMode ? (
                <MobileTopLabelWidget configs={configs} />
            ) : (
                <TopLabelWidget
                    configs={configs}
                    topLabelWidgets={topLabelWidgets}
                    isEdit={isEdit}
                    orientation={orientation}
                />
            )}
        </DefaultErrorBoundary>
    )
}

const useLabelsStyles = makeStyles({
    label: { minWidth: '125px', maxWidth: '200px' },
})

function Labels({ config, networkContext, filters, inlineFilters }) {
    const searchFilters = useSelector(filterSelectors.getAppliedSearchFilters)
    const fetchConfig = useMemo(
        () => createFetchConfig(config, networkContext, filters, inlineFilters, null, searchFilters),
        [config, networkContext, filters, inlineFilters]
    )
    const { informationConfig, data, status, refresh } = useWidgetData(fetchConfig)

    const refreshWidgets = useSelector(widgetSelectors.getRefreshWidgets)
    useEffect(() => {
        if (refreshWidgets.includes(config?.Id)) {
            refresh()
        }
    }, [refreshWidgets])

    const classes = useLabelsStyles()

    const labelsConfig = informationConfig?.LabelConfig ?? config?.LabelConfig
    const props = { labelsConfig, content: data, status }

    return <></>
}
