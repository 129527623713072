import { Button, ButtonProps, Popover, Tooltip, Typography } from '@mui/material'
import { useContext, useRef, useState } from 'react'

import AggregateMenu from 'genesis-suite/components/aggregation_selector/AggregateMenu'
import { aggregationDetailDictionary } from 'genesis-suite/components/aggregation_selector/aggregationOptions'
import { ConfigContext } from '../edit_widget'
import { typeSeriesKeyMapping } from './config_fields/SeriesEditor/SeriesEditor2'

export default function AggregationSelect(props: ButtonProps) {
    const { dispatch, selectedField, config, configKey, ...rest } = useContext(ConfigContext)
    const [open, setOpen] = useState(false)
    const anchorEl = useRef()

    const seriesKey = typeSeriesKeyMapping[config?.Type]
    let widgetConfig
    let activeSeries

    if (selectedField?.type === 'series') {
        if (selectedField?.subSeries === 'pivot') {
            widgetConfig = config[configKey]?.PivotFieldConfig
            activeSeries = widgetConfig?.ValueFields?.[selectedField.index]
        } else {
            widgetConfig = config[configKey]
            activeSeries = widgetConfig?.[seriesKey]?.[selectedField.index]
        }
    } else {
        return null
    }
    if (!activeSeries) return null

    const value = activeSeries?.AggregationType ?? 'unknown'
    const { label, Icon } = aggregationDetailDictionary[value.toLowerCase()]

    function handleChange(e, aggregation) {
        dispatch({
            type: 'UPDATE_ACTIVE_SERIES',
            payload: { AggregationType: aggregation },
        })
        setOpen(false)
    }

    return (
        <>
            <Tooltip title="Change aggregation">
                <Button ref={anchorEl} variant="outlined" onClick={() => setOpen(true)} sx={{ mb: 1 }} {...props}>
                    <Typography variant="caption">Aggregation: </Typography>
                    <Icon fontSize="small" />
                    <Typography variant="subtitle2">{label}</Typography>
                </Button>
            </Tooltip>

            <Popover
                anchorEl={anchorEl.current}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={open}
                onClose={() => setOpen(false)}
            >
                <AggregateMenu value={value} onChange={handleChange} />
            </Popover>
        </>
    )
}
