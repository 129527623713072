import { Autocomplete, Box, Checkbox, TextField } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { DebouncedTextField } from 'genesis-suite/components'
import { Base } from 'genesis-suite/types/visualTypes'
import { useContext } from 'react'
import { ConfigContext } from '../../ConfigContext'
import { getAllProperties } from '../../utils'
import EditorWrapper from '../EditorWrapper'
import FieldLabel from '../FieldLabel'
import { typeSeriesKeyMapping } from './SeriesEditor2'

const baseOptions = [
    { value: Base.K, label: 'K', tip: 'Thousands' },
    { value: Base.M, label: 'M', tip: 'Millions' },
    { value: Base.B, label: 'B', tip: 'Billions' },
    { value: Base.DYNAMIC, label: 'D', tip: 'Dynamic' },
]

const useStyles = makeStyles(({ spacing, palette }) => ({
    actionContainer: {
        border: `2px solid ${palette.grayscale.light}`,
        padding: spacing(0.5),
        borderRadius: spacing(0.5),
        marginTop: spacing(0.5),
    },
}))

export default function TableHyperlinkEditor({ fieldsConfigKey }) {
    const { selectedField, config, configStructure, dispatch, resources, configKey } = useContext(ConfigContext)
    const seriesConfig = configStructure[fieldsConfigKey]
    const properties = getAllProperties(resources.byId)

    const classes = useStyles()
    // const widgetConfig = config[configKey]
    // const activeSeries = widgetConfig.Fields?.[selectedField.index]
    const seriesKey = typeSeriesKeyMapping[config?.Type]
    let widgetConfig
    let activeSeries

    if (selectedField?.type === 'series') {
        if (selectedField?.subSeries === 'pivot') {
            widgetConfig = config[configKey]?.PivotFieldConfig
            activeSeries = widgetConfig?.ValueFields?.[selectedField.index]
        } else {
            widgetConfig = config[configKey]
            activeSeries = widgetConfig?.[seriesKey]?.[selectedField.index]
        }
    } else {
        return null
    }
    if (!seriesConfig || !activeSeries) return null

    // const updateActiveSeries = (key, value) => {
    //     dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload: { [key]: value } })
    // }
    function updateActiveSeries(key, value) {
        if (selectedField?.type === 'series') {
            if (selectedField?.subSeries === 'pivot') {
                dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload: { [key]: value, isPivot: true } })
            }
            if (!selectedField?.subSeries) {
                dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload: { [key]: value } })
            }
        } else {
            return
        }
    }
    const sortedSeriesConfig = [...seriesConfig].sort(
        (a, b) => (a.DisplayOrder ?? Infinity) - (b.DisplayOrder ?? Infinity)
    )
    return (
        <EditorWrapper header="Hyperlink Settings">
            <Box className={classes.actionContainer}>
                <Box display="flex" flexDirection="column">
                    {sortedSeriesConfig.map((field, index) => {
                        const isHyperlinkChecked = activeSeries['IsHyperlink']
                        const isHyperlinkPropertyChecked = activeSeries['HyperlinkProperty']

                        if (
                            field.Type.includes('Boolean') &&
                            field.PropertyGroupName === 'Hyperlink Settings' &&
                            field.Name === 'IsHyperlink'
                        ) {
                            return (
                                <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                    <div
                                        key={index}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Checkbox
                                            onChange={e => updateActiveSeries(field.Name, e.target.checked)}
                                            name={field.Name}
                                            color="primary"
                                            defaultChecked={false}
                                            checked={isHyperlinkChecked ?? false}
                                            inputProps={{ 'aria-label': `switch-${index}` }}
                                            disabled={isHyperlinkPropertyChecked}
                                        />
                                        <FieldLabel field={field} />
                                    </div>
                                </div>
                            )
                        } else if (field.PropertyGroupName === 'Hyperlink Settings' && field.Name === 'HyperlinkUrl') {
                            return (
                                <div key={index}>
                                    <FieldLabel field={field} />
                                    <DebouncedTextField
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        label="Hyperlink URL"
                                        value={activeSeries[field.Name] || ''}
                                        onChange={e => updateActiveSeries(field.Name, e)}
                                        disabled={isHyperlinkPropertyChecked} // Disable if HyperlinkProperty is true
                                    />
                                </div>
                            )
                        } else if (
                            field.Type.includes('Boolean') &&
                            field.PropertyGroupName === 'Hyperlink Settings' &&
                            field.Name === 'HyperlinkProperty'
                        ) {
                            return (
                                <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                    <div
                                        key={index}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Checkbox
                                            onChange={e => updateActiveSeries(field.Name, e.target.checked)}
                                            name={field.Name}
                                            color="primary"
                                            defaultChecked={false}
                                            checked={isHyperlinkChecked ?? false}
                                            inputProps={{ 'aria-label': `switch-${index}` }}
                                            disabled={isHyperlinkChecked}
                                        />
                                        <FieldLabel field={field} />
                                    </div>
                                </div>
                            )
                        } else if (field.IsSourceProperty && field.PropertyGroupName === 'Hyperlink Settings') {
                            return (
                                <div key={index}>
                                    <FieldLabel field={field} />
                                    <Autocomplete
                                        options={properties}
                                        value={properties?.find(p => p.name === activeSeries[field.Name]) ?? null}
                                        isOptionEqualToValue={(option, value) => option?.name == value?.name}
                                        getOptionLabel={option => option.name}
                                        onChange={(_, newValue) => {
                                            updateActiveSeries(field.Name, newValue?.name)
                                        }}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                placeholder="Select Display Property"
                                                size="small"
                                                margin="normal"
                                                sx={{ my: 0 }}
                                            />
                                        )}
                                    />
                                </div>
                            )
                        }
                    })}
                </Box>
            </Box>
        </EditorWrapper>
    )
}
