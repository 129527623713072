import { Divider, Box } from '@mui/material'
import { ChopText } from 'genesis-suite/components'
import Widget from '../../components/widgets/Widget'
import { useRef } from 'react'

export default function AIWidgetBox({ widget }) {
    const widgetHeight = 300
    const widgetWidth = 500
    const d3WidgetBlowsUpOtherwiseRef = useRef(null)

    return (
                <div>
                    <Divider />
                    <Box sx={{ display: 'flex', alignItems: 'center', p: 1 }}>
                        <Box
                            sx={{
                                flex: 1,
                                overflow: 'hidden',
                            }}
                        >
                            <ChopText variant="h6" showEllipsis>
                                {widget.Title}
                            </ChopText>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            width: widgetWidth,
                            height: widgetHeight + 'px',
                            display: 'flex',
                            alignItems: 'center',
                            p: 1,
                        }}
                    >
                        {/* @ts-ignore */}
                        <Widget config={widget} ref={d3WidgetBlowsUpOtherwiseRef} />
                    </Box>
                </div>
    )
}
