import {
    Aggregation,
    Basket,
    BulletSeries,
    ChartType,
    ColorGradient,
    CustomTooltip,
    DataSource,
    MapSeries,
    OneSeries,
    TooltipType,
} from 'genesis-suite/types/visualTypes'
import { getBoxPlotAggregations } from '../../edit_widget/utils'

export function getDefaultColorGradient(): ColorGradient {
    return [
        { position: 0, color: '#EEEEEE' },
        { position: 1, color: '#222222' },
    ]
}

export function makeDefaultTooltip(chartType: ChartType, series: OneSeries): CustomTooltip {
    if (!chartType || !series) return emptyCustomTooltip

    switch (chartType) {
        case ChartType.BOX_PLOT: {
            const totalBoxPlotAggregations = getBoxPlotAggregations().length
            const seriesValues: DataSource[] = series?.values
            const configs = seriesValues?.map(value => ({ source: value }))
            const markdown = configs.reduce((markDownAccumulator, currentValue, index) => {
                let aggregation: string = currentValue.source.aggregation
                aggregation =
                    aggregation === 'q1' ? 'Lower Percentile' : aggregation === 'q3' ? 'Upper Percentile' : aggregation
                aggregation = aggregation.charAt(0).toUpperCase() + aggregation.slice(1)
                return seriesValues.length === totalBoxPlotAggregations
                    ? markDownAccumulator + `${aggregation}: {{${index}}}\n`
                    : `{{${index}}}`
            }, '')

            return {
                type: TooltipType.CUSTOM,
                markdown,
                configs,
            }
        }
        case ChartType.BULLET: {
            const bulletSeries = series as BulletSeries

            const actualValue = series?.values[0]
            const actualValueName = series?.values[0].field.name

            const targetValue = series?.values?.[1]
            const targetValueName = series?.values?.[1]?.field?.name

            const configs = targetValue ? [{ source: actualValue }, { source: targetValue }] : [{ source: actualValue }]

            const actualValueMarkdown = `Actual (${actualValueName}): {{0}}`

            const targetValueMarkdown =
                configs.length === 2
                    ? `Target (${targetValueName}): {{1}}`
                    : bulletSeries?.target
                    ? `Target (Custom): ${bulletSeries?.target}`
                    : ''

            const deltaValueMarkdown =
                configs.length === 2
                    ? `Delta: Subtract({{0}},{{1}})`
                    : bulletSeries?.target
                    ? `Delta Subtract({{0}},${bulletSeries.target})`
                    : ''

            return {
                type: TooltipType.CUSTOM,
                markdown: `${actualValueMarkdown}\n ${targetValueMarkdown}\n ${deltaValueMarkdown}`,
                configs,
            }
        }
        case ChartType.MAP: {
            const mapSeries = series as MapSeries
            switch (mapSeries.type) {
                case 'marker': {
                    let markdown = ''
                    const configs: CustomTooltip['configs'] = []
                    let index = 0

                    const markerIndex = series?.values.findIndex(v => v.basket === Basket.ID)
                    if (markerIndex > -1) {
                        markdown = `Marker: {{${index++}}}`
                        configs.push({ source: series?.values[markerIndex] })
                    }

                    const locationIndex = series?.values.findIndex(v => v.basket === Basket.LOCATION)
                    if (locationIndex > -1) {
                        markdown += `${markdown ? '\n' : ''}Location: {{${index++}}}`
                        configs.push({ source: series?.values[locationIndex] })
                    }

                    const latitudeIndex = series?.values.findIndex(v => v.basket === Basket.LATITUDE)
                    if (latitudeIndex > -1) {
                        markdown += `${markdown ? '\n' : ''}Latitude: {{${index++}}}`
                        configs.push({ source: series?.values[latitudeIndex] })
                    }

                    const longitudeIndex = series?.values.findIndex(v => v.basket === Basket.LONGITUDE)
                    if (longitudeIndex > -1) {
                        markdown += `${markdown ? '\n' : ''}Longitude: {{${index++}}}`
                        configs.push({ source: series?.values[longitudeIndex] })
                    }

                    return { type: TooltipType.CUSTOM, markdown, configs }
                }

                case 'heat': {
                    const field = series.subSeries.field
                    const source: DataSource = { field, aggregation: Aggregation.NONE }
                    return {
                        type: TooltipType.CUSTOM,
                        markdown: `${field?.name || ''}: [sub-series]`,
                        configs: [{ source }],
                    }
                }
                case 'connection':
                    return emptyCustomTooltip
            }
            break
        }
        default:
            return {
                type: TooltipType.CUSTOM,
                TooltipText: '',
                TooltipConfigs: [],
            }
    }
}

const emptyCustomTooltip: CustomTooltip = { type: TooltipType.CUSTOM, TooltipText: '', TooltipConfigs: [] }

export const tablePageSize = 200
export const defaultRowsPerRequest = 100

// const defaultHighChartsConfigs = {
//     chart: {
//         panning: true,
//         pinchType: 'x',
//         zoom: 'x',
//         style: { fontFamily: "'NunitoSans', sans-serif", fontSize: '16px' },
//     },
//     pane: { size: '0%' },
//     legend: {
//         enabled: true,
//         alignColumns: true,
//         layout: 'horizontal',
//         margin: 6,
//         padding: 4,
//         verticalAlign: 'top',
//         x: 50,
//         y: 10,
//         maxHeight: 35,
//         navigation: {
//             activeColor: '#3E576F',
//             animation: true,
//             arrowSize: 10,
//             inactiveColor: '#CCC',
//             style: { fontWeight: 'bold', color: '#333', fontSize: '12px' },
//         },
//     },
//     plotOptions: { line: { lineWidth: 1 }, series: {} },
//     xAxis: {
//         title: { text: null, style: { fontSize: '12px' } },
//         labels: { style: { fontWeight: 700 } },
//         gridLineWidth: 1,
//     },
//     yAxis: [
//         {
//             lineWidth: 1,
//             title: { style: { fontSize: '12px' } },
//             opposite: false,
//             softMin: 0,
//             visible: true,
//             gridLineWidth: 1,
//             labels: { enabled: true, style: { fontWeight: 700 } },
//             stackLabels: { enabled: false, style: { fontWeight: 'normal' } },
//             useHTML: true,
//             reversedStacks: false,
//         },
//     ],
//     credits: { enabled: false },
//     tooltip: {
//         followTouchMove: false,
//         snap: 0,
//         useHTML: true,
//         style: { pointerEvents: 'auto' },
//         fontSize: '20px',
//         animation: true,
//         enabled: false,
//         visibility: false,
//         headerFormat: '',
//     },
// }
