import { Box, useTheme, Switch } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useSelector, useDispatch } from 'react-redux'

import { appearanceConstants } from '../constants'
import NavTitle from '../containers/NavTitle'
import PerspectiveCompareFilterTrigger from '../containers/PerspectiveCompareFilterTrigger'
import PerspectiveFilterController from '../containers/PerspectiveFilterController'
import { useIsMobile } from '../hooks/useIsMobile'
import { TourTag } from '../lib/tourSteps'
import { authSelectors, perspectiveSelectors, widgetSelectors, navigationSelectors } from '../selectors'
import ContextPanel from './ContextPanel'
import EditPerspectiveTools from './EditPerspectiveTools'
import EditPerspectiveTrigger from './EditPerspectiveTrigger'
import FilterString from './FilterString'
import InteractionToggle from './InteractionToggle'
import LayoutToggle from './LayoutToggle'
import MobileFiltersController from './MobileFiltersController'
import PerspectiveExport from './PerspectiveExport'
import PerspectiveShare from './PerspectiveShare'
import PerspectiveToolbarMenuItems from './PerspectiveToolbarMenuItems'
import RootPerspectiveController from './RootPerspectiveController'
import SlideshowToggle from './SlideshowToggle'
import { NetworkFiltersProvider } from './network-filters/NetworkFiltersContext'
import FocalPointSelect from './widgets/FocalPointSelect'
import PerspectiveSearchFilter from './widgets/PerspectiveSearchFilter'
import PerspectiveRefresh from './PerspectiveRefresh'
import { perspectiveCreators } from '~/actions/creators/perspective.creators'
import { widgetCreators } from '~/actions/creators'
import PerspectiveSummaryController from './PerspectiveSummaryController'

const { ScrollBarWidth } = appearanceConstants

const useStyles = makeStyles(({ spacing, breakpoints, palette, border }) => ({
    mobileSearchInput: { width: '425px', marginTop: spacing() },
    tools: {
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        [breakpoints.down('md')]: {
            padding: spacing(1),
            justifyContent: 'space-between',
        },
    },
    leftTools: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
    },
    divider: {
        width: 2,
        height: 20,
        backgroundColor: palette.grayscale.lighter,
        margin: spacing(1),
    },
    rightTools: {
        alignSelf: 'center',
        backgroundColor: p => (p.isEditing ? palette.primary.main : palette.background.sideNav),
        borderRadius: border.radius.round,
        display: 'flex',
        justifyContent: 'flex-end',
        padding: spacing(0.25),
    },
    title: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    navTitle: {
        [breakpoints.down('sm')]: {
            fontSize: 'medium',
        },
    },
    filterStringMobile: {
        padding: spacing(0, 1),
    },
}))

export default function PerspectiveToolbar({
    perspectiveID,
    handleResetWidgets,
    hasFormWidget,
    configs,
    layouts,
    currentLayout,
    id,
}) {
    const editingId = useSelector(widgetSelectors.getEditingPerspective)
    const disabled = !Boolean(useSelector(widgetSelectors.getCurrentPerspectiveId))
    const isPowerUser = useSelector(authSelectors.getIsPowerUser)
    const isPublic = useSelector(authSelectors.getIsPublicLogin)
    const dispatch = useDispatch()
    const autoRefresh = useSelector(perspectiveSelectors.getAutoRefresh)
    const widgetIds = useSelector(s => widgetSelectors.getWidgetsForPerspective(s, perspectiveID))
    const refreshPerspective = () => dispatch(widgetCreators.setShouldRefresh(widgetIds))
    const getRouter = useSelector(navigationSelectors.getRouter)

    const isEditing = editingId === perspectiveID

    const isMobile = useIsMobile()

    const classes = useStyles({ isEditing, isPowerUser })
    const { palette, custom } = useTheme()

    const defaultIconProps = { style: { fontSize: custom.perspectiveToolbar?.fontSize, color: palette.text.primary } }
    const buttonStyle = {
        padding: custom.perspectiveToolbar?.padding,
        '&.Mui-disabled': {
            cursor: 'default',
            pointerEvents: 'none',
            opacity: 0.5,
        },
    }

    if (isPublic)
        return (
            <Box sx={{ mt: 1, paddingX: `${ScrollBarWidth}px` }}>
                <PerspectiveTitle isPublic />
            </Box>
        )

    return isMobile ? (
        <div>
            <div className={classes.tools}>
                <PerspectiveTitle isMobile className={classes.navTitle} />
                <div>
                    <MobileFiltersController />
                    <PerspectiveShare disabled={disabled} iconProps={defaultIconProps} />
                </div>
            </div>
            <div className={classes.filterStringMobile}>
                <NetworkFiltersProvider perspectiveId={perspectiveID}>
                    <FilterString />
                </NetworkFiltersProvider>
            </div>
        </div>
    ) : (
        <Box
            className={`perspective-toolbar`}
            sx={{ backgroundColor: 'background.main', paddingX: `${ScrollBarWidth}px`, pt: 1, width: '100%' }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <PerspectiveTitle />
                <PerspectiveSearchFilter />
                {hasFormWidget && (
                    <div data-html2canvas-ignore id="formSwitch">
                        <Switch
                            checked={autoRefresh}
                            color="secondary"
                            size="small"
                            onChange={() => dispatch(perspectiveCreators.toggleAutoRefresh())}
                            inputProps={{ 'aria-label': 'controlled' }}
                            title="Auto Refresh Perspective on Form Updates"
                        />
                    </div>
                )}
                <div
                    data-tour={TourTag.PerspectiveOptions}
                    className={classes.rightTools}
                    data-html2canvas-ignore
                    id="perspective-toolbar"
                >
                    {isEditing ? (
                        <EditPerspectiveTools buttonStyle={buttonStyle} />
                    ) : (
                        <>
                            <EditPerspectiveTrigger
                                disabled={disabled}
                                iconProps={defaultIconProps}
                                buttonStyle={buttonStyle}
                            />
                            <ContextPanel disabled={disabled} iconProps={defaultIconProps} buttonStyle={buttonStyle} />
                            <InteractionToggle
                                disabled={disabled}
                                iconProps={defaultIconProps}
                                buttonStyle={buttonStyle}
                            />
                            <PerspectiveFilterController
                                disabled={disabled}
                                iconProps={defaultIconProps}
                                buttonStyle={buttonStyle}
                                handleResetWidgets={handleResetWidgets}
                            />
                            <PerspectiveCompareFilterTrigger
                                disabled={disabled}
                                iconProps={defaultIconProps}
                                buttonStyle={buttonStyle}
                            />
                            <SlideshowToggle
                                disabled={disabled}
                                iconProps={defaultIconProps}
                                buttonStyle={buttonStyle}
                            />
                            <LayoutToggle
                                disabled={disabled}
                                iconProps={defaultIconProps}
                                buttonStyle={buttonStyle}
                                handleResetWidgets={handleResetWidgets}
                            />
                            <PerspectiveShare
                                disabled={disabled}
                                iconProps={defaultIconProps}
                                buttonStyle={buttonStyle}
                            />
                            <PerspectiveExport
                                disabled={disabled}
                                iconProps={defaultIconProps}
                                buttonStyle={buttonStyle}
                                configs={configs}
                                layouts={layouts}
                                currentLayout={currentLayout}
                                id={id}
                            />
                            {hasFormWidget && (
                                <PerspectiveRefresh
                                    disabled={disabled || autoRefresh}
                                    iconProps={defaultIconProps}
                                    buttonStyle={buttonStyle}
                                    handleRefresh={refreshPerspective}
                                />
                            )}
                            <PerspectiveSummaryController
                                disabled={disabled}
                                iconProps={defaultIconProps}
                                buttonStyle={buttonStyle}
                            />
                            <PerspectiveToolbarMenuItems
                                disabled={disabled}
                                iconProps={defaultIconProps}
                                buttonStyle={buttonStyle}
                            />
                        </>
                    )}
                </div>
            </Box>
            <div className={classes.tools}>
                <div className={classes.leftTools}>
                    <RootPerspectiveController />
                </div>
            </div>
            <NetworkFiltersProvider perspectiveId={perspectiveID}>
                <FilterString />
            </NetworkFiltersProvider>
        </Box>
    )
}

function PerspectiveTitle({ isPublic, isMobile = false, className }) {
    return (
        <Box
            sx={{
                flex: 1,
                overflow: 'hidden',
                display: 'flex',
                flexDirection: `${isMobile ? 'column' : 'row'}`,
                alignItems: `${isMobile ? 'start' : 'center'}`,
                gap: 1,
            }}
        >
            <NavTitle className={className} isPublic={isPublic} />
            {!isPublic && <FocalPointSelect isCompare />}
        </Box>
    )
}
