import { Box } from '@mui/system'
import { isEmpty, isEqual } from 'lodash'
import { Dispatch, memo, useContext, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { Typography } from '@mui/material'
import { ChartType, SeriesConfig, WidgetType } from 'genesis-suite/types/visualTypes'
import { useSnackbar } from 'notistack'
import { useDispatch } from 'react-redux'
import { widgetCreators } from '~/actions/creators'
import { widgetService } from '~/lib/services'
import { ConfigContext } from '.'
import { applicationSelectors, moduleSelectors } from '../../selectors'
import { InteractionProps } from '../../types/WidgetTypes'
import EditWidgetHeader2 from '../EditWidgetHeader2'
import Widget from '../widgets/Widget'
import { BuilderAction } from './builderReducer'

const typeSeriesKeyMapping = {
    Chart: 'Series',
    Table: 'Fields',
}

export default function EditWidget({ className }) {
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const { config, dispatch, configKey } = useContext(ConfigContext)
    const [renderConfig, setRenderConfig] = useState(null)
    const [message, setMessage] = useState(null)
    const seriesKey = typeSeriesKeyMapping[config?.Type]

    const lastConfig = useRef(null)

    const validateConfig = config => {
        if (!config) return 'Widget title is required'

        if (!config.Title) return 'Widget title is required'

        if (isEmpty(config[configKey]?.[seriesKey])) return 'At least one series is required'

        if (config.Type === WidgetType.CHART && isEmpty(config[configKey]?.LabelField)) return 'Category is required'

        return null
    }

    useEffect(() => {
        const cleanedConfig = { ...config, appName }
        if (!cleanedConfig) return

        if (isEqual(cleanedConfig, lastConfig.current)) return
        lastConfig.current = cleanedConfig
        const validationMessage = validateConfig(cleanedConfig)
        setMessage(validationMessage)
        setRenderConfig(cleanedConfig)
    }, [config])

    return <EditWidgetRender className={className} message={message} config={renderConfig} dispatch={dispatch} />
}

const EditWidgetRender = memo<{
    className?: string
    message?: string
    config: SeriesConfig
    dispatch: Dispatch<BuilderAction>
}>(({ className, message, config, dispatch }) => {
    const reduxDispatch = useDispatch()
    const widgetRef = useRef(null)
    const modelName = useSelector(applicationSelectors.getCurrentAppName)
    const visorId = useSelector(moduleSelectors.getModuleId)
    const { enqueueSnackbar: showSnackbar } = useSnackbar()
    if (!config || !config.Type) return null

    function handlePointSelect(point: InteractionProps) {
        const { series = 0, groupNames } = point.indexes

        if (!groupNames) {
            dispatch({
                type: 'SET_SELECTED_FIELD',
                payload: { type: 'series', index: series },
            })
        } else {
            const categories = [...groupNames]
            const subSeries = config.series[series ?? 0].subSeries && categories.pop()
            dispatch({
                type: 'SET_SELECTED_FIELD',
                payload: { type: 'series', index: series, ...(subSeries && { subSeries }), categories },
            })
        }
    }

    const onSaveWidget = async () => {
        try {
            const data = { ...config, Context: null, CreationDate: new Date().toISOString() }
            await widgetService.saveUserWidget(modelName, visorId, data)
            reduxDispatch(widgetCreators.saveUserWidget(data))
            showSnackbar('Widget saved successfully', { variant: 'success' })
        } catch (e) {
            console.error(e)
            showSnackbar('Failed to save widget', { variant: 'error' })
        }
    }

    return (
        <div className={className}>
            <EditWidgetHeader2 showActions={!message} onSave={onSaveWidget} />
            <Box
                mt={1}
                overflow="hidden"
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexGrow={isFullHeight(config) ? 1 : 0}
            >
                {/* <Widget2
                        editing={true}
                        config={config}
                        interactions={{ onPointSelect: config.type !== ChartType.TABLE ? handlePointSelect : null }}
                        onData={res => dispatch({ type: 'SET_DATA_RESPONSE', payload: res })}
                    /> */}
                {message && (
                    <Typography variant="body1" color="text.primary">
                        {message}
                    </Typography>
                )}
                {/* @ts-ignore */}
                {!message && <RenderWidget config={config} dispatch={reduxDispatch} />}
            </Box>
        </div>
    )
})

function RenderWidget({ config, dispatch }) {
    const widgetRef = useRef(null)
    return (
        <>
            {/* @ts-ignore */}
            <Widget config={config} dispatch={dispatch} ref={widgetRef} showInlineActions={false} reload={true} />
        </>
    )
}

function isFullHeight(config: SeriesConfig) {
    if (config.type === ChartType.LABEL) return false

    return true
}
