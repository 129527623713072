import { ContentCopy } from '@mui/icons-material'
import CollectionsRoundedIcon from '@mui/icons-material/CollectionsRounded'
import InsertPhotoRoundedIcon from '@mui/icons-material/InsertPhotoRounded'
import DownloadIcon from '@mui/icons-material/SaveAlt'
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { SelectPopover, Spinner } from 'genesis-suite/components'
import { Close, Xls } from 'genesis-suite/icons'
import { sleep } from 'genesis-suite/utils'
import { isEqual } from 'lodash'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useRef, useState } from 'react'
import { PerspectiveWidgetContext } from '../contexts/PerspectiveWidgetContext'
import useWidgetExport from './hooks/useWidgetExport'
import { ContentType, copyToClipboard, downloadFile, getExportType, getImageBlob } from './lib/widgetExporting'

export default function WidgetExportController({ config, visualRef, iconStyle, buttonStyle }) {
    const isTable = config?.Type?.toUpperCase() === 'TABLE'
    const { exportWidget } = useWidgetExport(config)
    const exportType = getExportType(config)
    const [showExportModal, setShowExportModal] = useState(false)

    const options = [{ Icon: InsertPhotoRoundedIcon, value: 'image', tooltip: 'Export as Image' }]

    if (isTable) {
        //@ts-ignore
        options.push({ Icon: Xls, value: 'excel', tooltip: 'Export as Excel' })
    }

    const showExport = Boolean(exportType)
    const { palette } = useTheme()
    const defaultIconProps = { style: { fontSize: '18px', color: palette.text.primary } }

    const onChange = option => {
        if (option.value === 'excel') {
            exportWidget()
        } else {
            setShowExportModal(true)
        }
    }

    if (!showExport) return null

    return (
        <>
            {options.length > 1 ? (
                <SelectPopover
                    PlaceHolderIcon={DownloadIcon}
                    tooltip="Export Widget"
                    items={options}
                    iconProps={defaultIconProps}
                    onChange={onChange}
                    updateDefaultOnSelect={false}
                />
            ) : (
                <Tooltip title="Export Widget">
                    <IconButton sx={buttonStyle} onClick={() => setShowExportModal(true)}>
                        <DownloadIcon sx={iconStyle} />
                    </IconButton>
                </Tooltip>
            )}
            <ImageDownloadModal
                open={showExportModal}
                config={config}
                visualRef={visualRef}
                exportType={exportType}
                onClose={() => setShowExportModal(false)}
            />
        </>
    )
}

const useStyles = makeStyles(({ palette, spacing }) => ({
    imageWrapper: {
        width: '100%',
        marginTop: '15px',
        height: '300px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    widgetImage: {
        width: '100%',
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'contain',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    wrapBottomBtns: {
        marginTop: '15px',
    },
    bottomBtn: {
        marginRight: '10px',
    },
}))

function ImageDownloadModal({ open, config, visualRef, exportType, onClose }) {
    const widgetTitle = config?.version === '2' ? config?.title : config?.Title
    const exportOptions = { includeWatermark: true, title: widgetTitle }
    const imgParams = {
        exportType,
        visualRef,
        exportOptions,
    }

    const classes = useStyles()

    const isTable = config?.Type?.toUpperCase() === 'TABLE'

    const { enqueueSnackbar: showSnackbar } = useSnackbar()

    const [anchorEl, setAnchorEl] = useState(null)

    const [copyStatus, setCopyStatus] = useState(false)
    const [widgetImageUrl, setWidgetImageUrl] = useState('')
    const [widgetImage, setWidgetImage] = useState(null)
    const [loadingImg, setLoadingImg] = useState(false)

    const { setIsCopying } = useContext(PerspectiveWidgetContext)
    const params = useRef(imgParams)
    if (!isEqual(params.current, imgParams)) params.current = imgParams

    useEffect(() => {
        if (!open) return

        const { exportOptions, exportType, visualRef } = params.current
        if (!exportType) return
        ;(async () => {
            try {
                setLoadingImg(true)

                if (isTable) {
                    setIsCopying(true)
                    await sleep(3000)
                }

                const imageBlob = await getImageBlob(exportType, visualRef, exportOptions)
                setWidgetImage(imageBlob)
                const imageURL = URL.createObjectURL(imageBlob)

                if (isTable) setIsCopying(false)

                setWidgetImageUrl(imageURL)
                setLoadingImg(false)
            } catch (e) {
                console.error(e)
                setLoadingImg(false)
            }
        })()
    }, [params.current, open])

    const copyImage = async () => {
        try {
            const contentType = ContentType.PNG
            await copyToClipboard(widgetImage, contentType)
            showSnackbar('Image copied', { variant: 'success' })
        } catch (e) {
            console.error(e)
            showSnackbar('Failed to copy image', { variant: 'error' })
        }
        setAnchorEl(null)
    }

    async function handleExportImage(contentType) {
        const { title, Title } = config

        try {
            await downloadFile(widgetImage, title || Title, contentType)
        } catch (e) {
            console.error(e)
            showSnackbar('Failed to export image', { variant: 'error' })
        }
        setAnchorEl(null)
    }

    return (
        <Dialog open={open} maxWidth="xs" fullWidth onClose={onClose}>
            <DialogTitle>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h6">Download Image</Typography>
                    <Box display="flex" alignItems="center">
                        {widgetImageUrl && (
                            <Tooltip key="copyimage" title="Copy Image">
                                <IconButton sx={{ color: 'text.primary' }} onClick={copyImage} size="large">
                                    <ContentCopy />
                                </IconButton>
                            </Tooltip>
                        )}
                        <IconButton sx={{ color: 'text.primary' }} onClick={onClose} size="large">
                            <Close />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent>
                <>
                    <div className={classes.imageWrapper}>
                        <Spinner show={loadingImg}>
                            {widgetImageUrl && (
                                <img onClick={copyImage} className={classes.widgetImage} src={widgetImageUrl} />
                            )}
                        </Spinner>
                    </div>

                    <div className={classes.wrapBottomBtns}>
                        <Button
                            type="button"
                            variant="contained"
                            startIcon={<InsertPhotoRoundedIcon />}
                            onClick={() => handleExportImage(ContentType.JPEG)}
                            className={classes.bottomBtn}
                            disabled={!widgetImageUrl}
                        >
                            Download as JPEG
                        </Button>
                        <Button
                            type="button"
                            variant="contained"
                            startIcon={<CollectionsRoundedIcon />}
                            onClick={() => handleExportImage(ContentType.PNG)}
                            className={classes.bottomBtn}
                            disabled={!widgetImageUrl}
                        >
                            Download as PNG
                        </Button>
                    </div>
                </>
            </DialogContent>
        </Dialog>
    )
}
