import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { DebouncedTextField } from 'genesis-suite/components'
import { Base, ColumnSeries, LineSeries } from 'genesis-suite/types/visualTypes'
import { useContext } from 'react'
import LabeledToggleButton from '~/components/LabeledToggleButton'
import { ConfigContext } from '../../ConfigContext'
import EditorWrapper from '../EditorWrapper'
import WidgetColorPicker from '../WidgetColorPicker'
import CurrencySymbolEditor from '../CurrencySymbolEditor'
import FieldLabel from '../FieldLabel'
import { isEmpty } from 'lodash'
import { typeConfigKeyMapping, typeSeriesKeyMapping } from './SeriesEditor2'

const baseOptions = [
    { value: Base.K, label: 'K', tip: 'Thousands' },
    { value: Base.M, label: 'M', tip: 'Millions' },
    { value: Base.B, label: 'B', tip: 'Billions' },
    { value: Base.DYNAMIC, label: 'D', tip: 'Dynamic' },
]

const useStyles = makeStyles(({ spacing, palette }) => ({
    actionContainer: {
        border: `2px solid ${palette.grayscale.light}`,
        padding: spacing(0.5),
        borderRadius: spacing(0.5),
        marginTop: spacing(0.5),
    },
}))

export default function SeriesFormatEditor({ parentProperty }) {
    const { selectedField, config, configStructure, dispatch, configKey } = useContext(ConfigContext)
    const fieldsConfigKey = typeConfigKeyMapping[config?.Type]
    const seriesKey = typeSeriesKeyMapping[config?.Type]
    const seriesConfig = configStructure[fieldsConfigKey]
    const classes = useStyles()
    let widgetConfig
    let activeSeries

    if (selectedField?.type === 'series') {
        if (selectedField?.subSeries === 'pivot') {
            widgetConfig = config[configKey]?.PivotFieldConfig
            activeSeries = widgetConfig?.ValueFields?.[selectedField.index]
        } else {
            widgetConfig = config[configKey]
            activeSeries = widgetConfig?.[seriesKey]?.[selectedField.index]
        }
    } else {
        return null
    }
    const fieldsToDisplay = seriesConfig?.filter(field => field.DisplayGroup === parentProperty)

    // const activeSeries = widgetConfig?.[seriesKey]?.[selectedField.index]
    if (isEmpty(fieldsToDisplay) || !activeSeries) return null

    // function updateActiveSeries(payload: Partial<LineSeries | ColumnSeries>) {
    //     dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload })
    // }
    function updateActiveSeries(payload: Partial<LineSeries | ColumnSeries>) {
        if (selectedField?.type === 'series') {
            if (selectedField?.subSeries === 'pivot') {
                dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload: { ...payload, isPivot: true } })
            }
            if (!selectedField?.subSeries) {
                dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload: { ...payload } })
            }
        } else {
            return
        }
    }
    const sortedSeriesConfig = [...fieldsToDisplay].sort(
        (a, b) => (a.DisplayOrder ?? Infinity) - (b.DisplayOrder ?? Infinity)
    )
    return (
        <EditorWrapper header="Value Format">
            <Box className={classes.actionContainer}>
                <Box display="flex" flexDirection="column">
                    {sortedSeriesConfig.map((field, index) => {
                        if (field.Name === 'BaseFormat')
                            return (
                                <LabeledToggleButton
                                    label="Denomination:"
                                    value={activeSeries?.[field.Name]}
                                    onChange={(e, base) => updateActiveSeries({ [field.Name]: base })}
                                    options={baseOptions}
                                />
                            )
                        else if (field.IsColor)
                            return (
                                <Box display="grid" gridTemplateColumns="40px auto" gap="4px" my={1}>
                                    <Typography display="inline">{field.DisplayName}: </Typography>
                                    <WidgetColorPicker
                                        value={activeSeries?.[field.Name]}
                                        onChange={({ hex }) => updateActiveSeries({ [field.Name]: hex })}
                                    />
                                </Box>
                            )
                        else if (field.IsCurrencySymbol) {
                            return (
                                <CurrencySymbolEditor
                                    label={field.DisplayName}
                                    value={activeSeries?.[field.Name] ?? ''}
                                    onChange={symbol => updateActiveSeries({ [field.Name]: symbol })}
                                />
                            )
                        } else if (field.Type.includes('String')) {
                            return (
                                <Box py={1}>
                                    <DebouncedTextField
                                        variant="outlined"
                                        label={field.DisplayName}
                                        fullWidth
                                        value={activeSeries?.[field.Name] ?? ''}
                                        onChange={title => updateActiveSeries({ [field.Name]: title })}
                                    />
                                </Box>
                            )
                        } else if (!isEmpty(field.List) && field.PropertyGroupName === 'Display Settings') {
                            return (
                                <div key={index}>
                                    <FieldLabel field={field} />
                                    <FormControl
                                        fullWidth
                                        sx={{ my: 0 }}
                                        variant="outlined"
                                        size="small"
                                        margin="normal"
                                    >
                                        <Select
                                            value={activeSeries[field.Name] ?? 'None'}
                                            onChange={e => updateActiveSeries({ [field.Name]: e.target.value })}
                                            sx={theme => ({
                                                border: '1px solid #888',
                                                borderRadius: '4px',
                                                '&:hover': {
                                                    borderColor: `${theme.palette.text.primary}`,
                                                },
                                                '&.Mui-focused': {
                                                    borderColor: `${theme.palette.text.primary}`,
                                                },
                                            })}
                                        >
                                            {Object.entries(field.List as { [key: string]: string }).map(
                                                ([key, value]) => (
                                                    <MenuItem key={key} value={key}>
                                                        {value}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                            )
                        }
                    })}
                </Box>
            </Box>
        </EditorWrapper>
    )
}
