import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'

import makeStyles from '@mui/styles/makeStyles'
import { ExpandMoreRounded, ExpandLessRounded, GroupRounded, DeleteRounded, LaunchRounded } from '@mui/icons-material'
import Inventory2RoundedIcon from '@mui/icons-material/Inventory2Rounded'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import ArchiveRoundedIcon from '@mui/icons-material/ArchiveRounded'
import UnarchiveRoundedIcon from '@mui/icons-material/UnarchiveRounded'

import {
    Avatar,
    Box,
    IconButton,
    Typography,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Divider,
    Badge,
    styled,
    BadgeProps,
    Tooltip,
    TextField,
    InputAdornment,
    Popper,
    Fade,
    Paper,
    Button,
} from '@mui/material'

import { EditBox, Search } from 'genesis-suite/icons'
import { SwalContext } from 'genesis-suite/components'
import { ChatsDeleteConversationRequest } from 'genesis-suite/types/visualTypes'

import { messageService } from '~/lib/services'
import { decodeDataToHtml, stripHtmlDOM } from '~/lib/utils'
import { messengerSelectors } from '~/selectors/messenger.selectors'
import { messengerCreators } from '~/actions/creators/messenger.creators'
import { MessageConversationType, MessageParticipantType } from '~/types/messageTypes'
import { applicationSelectors, authSelectors, collaborationSelectors, widgetSelectors } from '~/selectors'

import { CircularSpinner } from './MessageBar'
import { navigationCreators } from '~/actions/creators'
import { debounce } from 'lodash'

const useStyles = makeStyles(({ palette }) => ({
    messageList: {
        maxHeight: '360px',
    },
    listContent: {
        color: palette.text.primary,
        alignItems: 'center',
        padding: '4px 12px',
        '& .MuiListItemText-secondary': {
            color: palette.text.secondary,
            overflow: 'hidden',
            display: '-webkit-box',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',
        },
    },
    chatUnread: {
        '& .MuiTypography-root': {
            fontWeight: 700,
        },
    },
    '@keyframes jump': {
        '0%': { transform: 'translateY(0)' },
        '30%': { transform: 'translateY(-10px)' },
        '60%': { transform: 'translateY(0)' },
        '100%': { transform: 'translateY(0)' },
    },
    jump: {
        animation: `$jump 0.5s ease-in-out 3`,
    },
}))

const UnreadBadge = styled(Badge)<BadgeProps>(() => ({
    '& .MuiBadge-badge': {
        top: '50%',
    },
}))

const MessagesBox = ({ classes, onNewChat, onChatOpen }) => {
    const chatClasses = useStyles()
    const dispatch = useDispatch()
    const { confirm } = useContext(SwalContext)
    const [search, setSearch] = useState('')
    const [isOpen, setIsOpen] = useState(false)
    const [animateKey, setAnimateKey] = useState(0)
    const [unReadCount, setUnReadCount] = useState(0)
    const chatRef = useRef(null)
    const [isArchiveMode, setIsArchiveMode] = useState(false)

    const userAlias = useSelector(authSelectors.getUserAlias)
    const userId = useSelector(authSelectors.getUserId)
    const messageList = useSelector(messengerSelectors.getMessageList)
    const archivedList = useSelector(messengerSelectors.getArchiveList)
    const userList = useSelector(messengerSelectors.getUserList)
    const loader = useSelector(messengerSelectors.getLoader)
    const users = useSelector(collaborationSelectors.collaborators)
    const unarchivePagination = useSelector(messengerSelectors.getPagination)
    const archivedPagination = useSelector(messengerSelectors.getArchivedPagination)
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const appDomainId = useSelector(state => applicationSelectors.getAppDomainId(state, appName))

    const chatList = isArchiveMode ? archivedList : messageList
    const [isLoading, setIsLoading] = useState(false)

    const isLoadingConversation = useRef(false)

    const handleScroll = () => {
        if (chatRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = chatRef.current

            const hasMore = isArchiveMode ? archivedPagination.hasMoreArchived : unarchivePagination.hasMore

            if (hasMore && scrollTop + clientHeight >= scrollHeight - 100) {
                getConversations()
            }
        }
    }
    async function getConversations() {
        if (!isLoadingConversation.current) {
            isLoadingConversation.current = true
            setIsLoading(true)

            try {
                if (isArchiveMode) {
                    await dispatch(messengerCreators.getArchivedConversations(true))
                } else {
                    await dispatch(messengerCreators.getConversations())
                }
            } catch (error) {
                console.error('Error fetching conversations:', error)
            } finally {
                isLoadingConversation.current = false
                setIsLoading(false)
                console.log('Finished loading conversations.')
            }
        }
    }

    useEffect(() => {
        if (chatRef.current) {
            const currentRef = chatRef.current
            currentRef.addEventListener('scroll', handleScroll)
            return () => {
                currentRef.removeEventListener('scroll', handleScroll)
            }
        }
    }, [chatRef, unarchivePagination, archivedPagination, isArchiveMode])

    const chats = chatList
        .map(chat => ({
            ...chat,
            createdAt: chat.latestMessage?.createdAt ? new Date(chat.latestMessage.createdAt).getTime() : 0,
        }))
        .sort((a, b) => b.createdAt - a.createdAt)
        .reduce((acc, chat) => {
            if (chat?.id) {
                let user = null
                const sender = chat.latestMessage?.senderId ? chat.latestMessage?.senderId : chat.latestMessage?.sender
                if (chat.type === MessageConversationType.DIRECTMESSAGE) {
                    const targetUser = chat?.participants.find(user => user.id !== userId)
                    user = userList.find(user => user.UserId === targetUser?.id)
                } else {
                    user = userList.find(user => user.UserId === sender)
                }

                // if (!user) return acc

                const processedMessage = decodeDataToHtml(
                    chat.latestMessage?.content,
                    chat.latestMessage?.tags ?? [],
                    chat.latestMessage?.mentions ?? []
                )

                const lastMessage = chat.latestMessage?.content
                    ? `${sender === userId ? 'You' : user?.FirstName}: ${processedMessage}`
                    : ''

                const cleanString = stripHtmlDOM(lastMessage)

                acc.push({
                    ...chat,
                    user: { id: user?.UserId, ScreenAlias: user?.ScreenAlias },
                    lastMessage: cleanString,
                    isUnread: chat?.latestMessage?.isUnread,
                    unreadCount: chat?.unreadMessagesCount,
                })
            }
            return acc
        }, [])
    // .filter(chats => isArchiveMode ? true : chats.isArchived)

    useEffect(() => {
        if (chats?.length > 0) {
            const unreadCount = chats.reduce((acc, chat) => acc + (chat.unreadCount || 0), 0)
            setUnReadCount(unreadCount)
        }
    }, [chats])

    useEffect(() => {
        // Update the key whenever unReadCount changes
        if (unReadCount > 0) {
            setAnimateKey(prevKey => prevKey + 1)
        }
    }, [unReadCount])

    const name = userAlias || userId || 'Empty'

    const toggleChatBox = () => {
        setIsOpen(!isOpen)
    }

    const handleChatOpen = chat => {
        onChatOpen({
            id: chat.id,
            type: chat.type,
            participants: chat.participants,
            user: chat.user,
            chatData: chat,
        })
    }

    const onDeleteChat = async chat => {
        const result = await confirm('Delete this chat? It will be lost forever!', { type: 'question' })
        if (result.dismiss) return

        const deleteRequest: ChatsDeleteConversationRequest = {
            domainId: appDomainId,
            id: chat?.id,
        }

        messageService
            .deleteConversation(deleteRequest)
            .then(response => {
                dispatch(messengerCreators.closeUserChat(chat?.id))
                dispatch(messengerCreators.getUserConversations())
            })
            .catch(error => console.error('Error during conversation delete:', error))
    }
    // const handleSearch = useCallback(
    //     debounce(query => {
    //         dispatch(messengerCreators.getUserConversations(false, query))
    //     }, 500),
    //     [dispatch]
    // )

    // const onSearchChange = e => {
    //     const query = e.target.value
    //     setSearch(query)
    //     handleSearch(query)
    // }

    const onArchiveChat = async chat => {
        const result = await confirm('Archive this chat?', { type: 'question' })
        if (result.dismiss) return

        const archiveRequest = {
            domainId: appDomainId,
            id: chat?.id,
        }

        messageService
            .getArchiveChats(archiveRequest)
            .then(response => {
                dispatch(messengerCreators.closeUserChat(chat?.id))
                dispatch(messengerCreators.getUserConversations())
            })
            .catch(error => console.error('Error during conversation archive:', error))
    }
    const onUnarchiveChat = async chat => {
        const result = await confirm('Unarchive this chat?', { type: 'question' })
        if (result.dismiss) return

        const unarchiveRequest = {
            domainId: appDomainId,
            id: chat?.id,
        }

        messageService
            .getUnarchiveChats(unarchiveRequest)
            .then(response => {
                dispatch(messengerCreators.closeUserChat(chat?.id))
                dispatch(messengerCreators.getUserConversations())
                dispatch(messengerCreators.getArchivedConversations())
            })
            .catch(error => console.error('Error during conversation Unarchive:', error))
    }

    const fetchArchivedChats = event => {
        // event.stopPropagation()
        setIsArchiveMode(true)
        dispatch(messengerCreators.getArchivedConversations())
    }

    return (
        <Box className={`${classes.chatBoxContainer} ${isOpen ? classes.chatBoxOpen : ''}`}>
            <Box className={classes.chatBoxToggle} onClick={toggleChatBox}>
                <Box className={classes.chatHeader} sx={{ display: 'flex', gap: 0.5, alignItems: 'center', ml: 0.5 }}>
                    <Avatar className={classes.avatar}>{name[0].toUpperCase()}</Avatar>
                    <Typography variant="body1" sx={{ fontSize: '0.85rem', fontWeight: 500, marginRight: '10px' }}>
                        Messages
                    </Typography>
                    <Badge
                        key={animateKey}
                        badgeContent={unReadCount}
                        color="secondary"
                        className={chatClasses.jump}
                    ></Badge>
                </Box>
                <Box sx={{ display: 'flex', gap: '10px' }}>
                    <Tooltip title={isArchiveMode ? 'Back' : 'Archived Chats'}>
                        <IconButton
                            onClick={e => {
                                e.stopPropagation()
                                if (isArchiveMode) {
                                    setIsArchiveMode(false)
                                } else {
                                    fetchArchivedChats(e)
                                }
                            }}
                        >
                            {isArchiveMode ? <ArrowBackIosNewRoundedIcon /> : <Inventory2RoundedIcon />}
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Start new chat">
                        <IconButton
                            className={classes.buttonStyle}
                            onClick={e => {
                                e.stopPropagation()
                                onNewChat()
                            }}
                            size="medium"
                            data-cy="edit-row"
                        >
                            <EditBox />
                        </IconButton>
                    </Tooltip>
                    <IconButton onClick={toggleChatBox} className={classes.buttonStyle} size="large" data-cy="edit-row">
                        {isOpen ? <ExpandMoreRounded /> : <ExpandLessRounded />}
                    </IconButton>
                </Box>
            </Box>
            <Box
                ref={chatRef}
                sx={{
                    overflow: 'auto',
                }}
                className={`${classes.chatBox} ${isOpen ? classes.chatBoxOpen : ''}`}
            >
                {/* <TextField
                    id="search-box"
                    placeholder="Search Messages"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={search}
                    onChange={onSearchChange}
                    className={classes.searchBox}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                        style: { borderRadius: 10, height: 30 },
                    }}
                /> */}
                {loader ? (
                    <CircularSpinner />
                ) : chats.length === 0 ? (
                    <Typography
                        sx={{
                            textAlign: 'center',
                            padding: '20px',
                            color: 'text.secondary',
                            fontSize: '0.9rem',
                        }}
                    >
                        {isArchiveMode ? (
                            'No archived chats'
                        ) : (
                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                marginTop="55%"
                            >
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: 'text.primary',
                                    }}
                                >
                                    No Conversation Found <br /> <br />
                                    Click <EditBox sx={{ marginX: 0.5 }} /> to start a new conversation
                                </Typography>
                            </Box>
                        )}
                    </Typography>
                ) : (
                    <List className={chatClasses?.messageList}>
                        {chats.map(chat => {
                            const isOnline = users.some(user => user.name === chat.user?.ScreenAlias)
                            return (
                                <React.Fragment key={chat?.id}>
                                    <ListItem
                                        key={chat.user?.UserId}
                                        onClick={() => handleChatOpen(chat)}
                                        className={classNames(chatClasses.listContent, {
                                            [chatClasses.chatUnread]: chat?.isUnread,
                                        })}
                                        sx={{
                                            cursor: 'pointer',
                                            alignItems: 'flex-start',
                                            position: 'relative',
                                            '&:hover .archive-button': {
                                                opacity: 1,
                                            },
                                        }}
                                    >
                                        <HoverListItemAvatar
                                            chat={chat}
                                            classes={classes}
                                            isOnline={isOnline}
                                            onDeleteChat={onDeleteChat}
                                        />
                                        <ListItemText
                                            sx={{
                                                marginBottom: 0,
                                                paddingBottom: 1,
                                                '& .MuiListItemText-secondary': {
                                                    fontWeight: chat.isUnread === true ? 'bold' : 'normal',
                                                },
                                            }}
                                            primary={
                                                chat.type === MessageConversationType.DIRECTMESSAGE ? (
                                                    <>{chat.user?.ScreenAlias}</>
                                                ) : (
                                                    <ConversationGroupName
                                                        participants={chat.participants}
                                                        chatType={chat.type}
                                                        groupName={chat.name}
                                                        chat={chat}
                                                    />
                                                )
                                            }
                                            secondary={chat.lastMessage}
                                        />
                                        {chat?.unreadCount > 0 && (
                                            <UnreadBadge
                                                badgeContent={chat?.unreadCount}
                                                color="secondary"
                                                sx={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    right: 16,
                                                    transform: 'translateY(-50%)',
                                                }}
                                            />
                                        )}
                                        <Tooltip title={isArchiveMode ? 'Unarchive' : 'Archive'}>
                                            <IconButton
                                                onClick={e => {
                                                    e.stopPropagation()
                                                    isArchiveMode ? onUnarchiveChat(chat) : onArchiveChat(chat)
                                                }}
                                                size="small"
                                                color="primary"
                                                aria-label={isArchiveMode ? 'unarchive chat' : 'archive chat'}
                                                className="archive-button"
                                                sx={{
                                                    position: 'absolute',
                                                    right: '8px',
                                                    top: '50%',
                                                    transform: 'translateY(-50%)',
                                                    opacity: 0,
                                                    transition: 'opacity 0.3s',
                                                }}
                                            >
                                                {isArchiveMode ? <UnarchiveRoundedIcon /> : <ArchiveRoundedIcon />}
                                            </IconButton>
                                        </Tooltip>
                                    </ListItem>
                                    <Divider sx={{ borderColor: 'border.main' }} />
                                </React.Fragment>
                            )
                        })}
                        {isLoading ? <CircularSpinner /> : null}
                    </List>
                )}
            </Box>
        </Box>
    )
}

export const ConversationGroupName = ({ participants, chatType, groupName = null, chat }) => {
    const dispatch = useDispatch()
    const userId = useSelector(authSelectors.getUserId)
    const perspectiveId = useMemo(
        () => participants.find(participant => participant.type === MessageParticipantType.ITEM)?.id,
        [participants]
    )
    const targetWidgetConfig = useSelector(state => widgetSelectors.getWidgetConfig(state, perspectiveId))
    const userList = useSelector(messengerSelectors.getUserList)

    const chatName = useMemo(() => {
        return participants
            .filter(participant => participant.type === MessageParticipantType.USER)
            .map(participant => {
                const user = userList.find(user => user.UserId === participant.id)
                if (user?.UserId === userId) return null
                return user?.ScreenAlias || null
            })
            .filter(Boolean)
            .join(', ')
    }, [participants, userList])

    const onLaunch = event => {
        event.stopPropagation()
        dispatch(
            navigationCreators.goToPerspective(targetWidgetConfig.Id, {
                carryOverFilters: true,
                ...JSON.parse(chat.context),
            })
        )
    }

    const displayName =
        chatType === MessageConversationType.PERSPECTIVEDISCUSSION ? targetWidgetConfig?.Title : chatName

    return (
        <>
            <Tooltip title={chatName}>{groupName ? groupName : displayName}</Tooltip>
            {chatType === MessageConversationType.PERSPECTIVEDISCUSSION && (
                <Tooltip title={'Launch Perspective'}>
                    <IconButton sx={{ marginLeft: '0.25rem' }} onClick={event => onLaunch(event)} size="small">
                        <LaunchRounded fontSize="small" />
                    </IconButton>
                </Tooltip>
            )}
        </>
    )
}

const useAvatarStyles = makeStyles(() => ({
    listItemAvatar: {
        '&:hover $hoverActions': {
            display: 'block',
        },
        '&:hover $badge': {
            display: 'none',
        },
    },
    hoverActions: {
        display: 'none',
    },
    badge: {
        display: 'block',
        '& .MuiBadge-dot': {
            right: '40%',
        },
    },
}))

const HoverListItemAvatar = ({ isOnline, chat, classes, onDeleteChat }) => {
    const styles = useAvatarStyles()
    const userId = useSelector(authSelectors.getUserId)

    const isOwner = chat.ownerId === userId

    return (
        <ListItemAvatar sx={{ minWidth: '40px' }} className={classNames({ [styles.listItemAvatar]: isOwner })}>
            {isOwner && (
                <div className={styles.hoverActions}>
                    <Tooltip title="Delete chat">
                        <IconButton
                            color="error"
                            onClick={event => {
                                event.stopPropagation()
                                onDeleteChat(chat)
                            }}
                            aria-label="Delete chat"
                        >
                            <DeleteRounded />
                        </IconButton>
                    </Tooltip>
                </div>
            )}
            <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                variant="dot"
                invisible={!isOnline || !(chat.type === MessageConversationType.DIRECTMESSAGE)}
                color={'success'}
                className={classNames({ [styles.badge]: isOwner })}
            >
                <Avatar className={classes.avatar}>
                    {chat.type === MessageConversationType.DIRECTMESSAGE ? (
                        chat.user?.ScreenAlias ? (
                            chat.user.ScreenAlias[0]?.toUpperCase()
                        ) : undefined
                    ) : (
                        <GroupRounded />
                    )}
                </Avatar>
            </Badge>
        </ListItemAvatar>
    )
}

export default MessagesBox
