import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { LoadingButton } from '@mui/lab'
import { CloseRounded } from '@mui/icons-material'

import { messageService } from '~/lib/services'
import { messengerSelectors } from '~/selectors/messenger.selectors'
import { messengerCreators } from '~/actions/creators/messenger.creators'
import { ChatsConversationRequest } from 'genesis-suite/types/visualTypes'
import { MessageConversationType, MessageParticipantType } from '~/types/messageTypes'
import { applicationSelectors, authSelectors, filterSelectors, widgetSelectors } from '~/selectors'

const useStyles = makeStyles(({ palette }) => ({
    icon: { color: palette.text.primary },
    textFieldStyle: {
        '& .MuiAutocomplete-endAdornment svg': {
            color: palette.text.primary,
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.text.primary,
        },
        '&:hover .MuiOutlinedInput-notchedOutline,': {
            borderColor: palette.text.primary,
        },
        '& .MuiSvgIcon-root': {
            color: palette.text.primary,
        },
    },
}))

interface Props {
    open: boolean
    onClose: () => void
    isPerspectiveChat?: boolean
    isWidgetChat?: boolean
    perspectiveName?: string
    widgetId?: string
}

const NewChatDialog = ({
    open,
    onClose,
    perspectiveName,
    isPerspectiveChat = false,
    widgetId,
    isWidgetChat = false,
}: Props) => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const userId = useSelector(authSelectors.getUserId)
    const loader = useSelector(messengerSelectors.getLoader)
    const chatList = useSelector(messengerSelectors.getMessageList)
    const allUsersList = useSelector(messengerSelectors.getUserList)
    const perspectiveId = useSelector(widgetSelectors.getCurrentPerspectiveId)
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const appDomainId = useSelector(state => applicationSelectors.getAppDomainId(state, appName))

    const inlineFilters = useSelector(filterSelectors.getInlineFilters)
    const perspectiveFilters = useSelector(filterSelectors.getSessionPerspectiveFilters)
    const searchFilters = useSelector(filterSelectors.getAppliedSearchFilters)

    const combinedFilters = { inlineFilters: inlineFilters, filters: perspectiveFilters, searchFilters: searchFilters }

    const [reqValue, setReqValue] = useState({
        name: perspectiveName ?? '',
        participants: [],
    })

    const usersList = useMemo(() => {
        return allUsersList
            .filter(user => user?.UserId.toLowerCase() !== userId && user.DomainId)
            .map(user => ({
                label: user?.ScreenAlias,
                value: user?.UserId,
                id: user?.UserId,
            }))
    }, [allUsersList, userId])

    const handleClose = () => {
        if (onClose) {
            onClose()
        }
    }

    const handleValueChange = change => {
        if (change.participants) {
            setReqValue(prev => ({
                ...prev,
                participants: change.participants.map(participant => {
                    return typeof participant === 'string' ? { label: participant, value: participant } : participant
                }),
            }))
        } else {
            setReqValue(prev => ({ ...prev, ...change }))
        }
    }

    const handleSubmit = () => {
        const allParticipants = reqValue.participants.map(users => ({
            id: users.id,
            type: MessageParticipantType.USER,
        }))
        allParticipants.push({
            id: userId,
            type: MessageParticipantType.USER,
        })

        if (isPerspectiveChat && perspectiveId) {
            allParticipants.push({
                id: perspectiveId,
                type: MessageParticipantType.ITEM,
            })
        }

        if (isWidgetChat && widgetId) {
            allParticipants.push({
                id: widgetId,
                type: MessageParticipantType.ITEM,
            })
        }

        const findMatchingChat = (chatList, allParticipants) => {
            const allParticipantsSorted = allParticipants.sort((a, b) => a.id.localeCompare(b.id))

            return chatList.find(chat => {
                if (chat.type === 0) return false

                const participantsSorted = chat.participants.sort((a, b) => a.id.localeCompare(b.id))
                return (
                    participantsSorted.length === allParticipantsSorted.length &&
                    participantsSorted.every(
                        (participant, index) =>
                            participant.id === allParticipantsSorted[index].id &&
                            participant.type === allParticipantsSorted[index].type
                    )
                )
            })
        }

        const isChatExist = findMatchingChat(chatList, allParticipants)

        if (!isChatExist) {
            const conversationRequest: ChatsConversationRequest = {
                domainId: appDomainId,
                ownerId: userId,
                name: reqValue?.name !== '' ? reqValue?.name : null,
                type: isPerspectiveChat
                    ? MessageConversationType.PERSPECTIVEDISCUSSION
                    : MessageConversationType.GROUPCHAT,
                participants: allParticipants,
                ...(isPerspectiveChat ? { context: JSON.stringify(combinedFilters) } : {}),
            }

            messageService
                .createNewConversation(conversationRequest)
                .then(response => {
                    const conversationObj = {
                        id: response.id,
                        participants: response.participants,
                        type: response.type,
                        user: {},
                        chatData: response,
                    }

                    dispatch(messengerCreators.openUserChat(conversationObj))
                    handleClose()
                })
                .catch(error => console.error('Error during conversation group creation:', error))
        } else {
            const conversationObj = {
                id: isChatExist.id,
                participants: isChatExist.participants,
                type: isChatExist.type,
                user: {},
                chatData: isChatExist,
            }

            dispatch(messengerCreators.openUserChat(conversationObj))
            handleClose()
        }
    }

    return (
        <Dialog
            PaperProps={{
                sx: {
                    width: 400,
                    overflow: 'hidden',
                },
            }}
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>
                Create New Group chat
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        color: 'inherit',
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseRounded />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        gap: 1.5,
                    }}
                >
                    <TextField
                        fullWidth
                        value={reqValue?.name}
                        label="Group Name"
                        className={classes.textFieldStyle}
                        onChange={e => handleValueChange({ name: e.target.value })}
                        InputLabelProps={{ sx: { color: 'text.primary' } }}
                    />
                    <Autocomplete
                        fullWidth
                        multiple
                        loading={loader}
                        disableClearable
                        options={usersList}
                        renderOption={(props, option, { selected }) => {
                            return (
                                <li {...props}>
                                    <Checkbox style={{ marginRight: 8 }} checked={selected} />
                                    {option.label}
                                </li>
                            )
                        }}
                        getOptionLabel={option => option.label}
                        value={reqValue?.participants}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label="Participants"
                                className={classes.textFieldStyle}
                                InputLabelProps={{ sx: { color: 'text.primary' } }}
                            />
                        )}
                        onChange={(_, value) => handleValueChange({ participants: value })}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" variant="contained" onClick={onClose}>
                    Cancel
                </Button>
                <LoadingButton
                    color="primary"
                    variant="contained"
                    // loading={loading}
                    onClick={handleSubmit}
                >
                    Create
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default NewChatDialog
