import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'

import makeStyles from '@mui/styles/makeStyles'
import { Search, CloseRounded, ForumRounded } from '@mui/icons-material'
import {
    Avatar,
    Badge,
    Box,
    Divider,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material'

import { messageService } from '~/lib/services'
import { messengerSelectors } from '~/selectors/messenger.selectors'
import { ChatsConversationRequest } from 'genesis-suite/types/visualTypes'
import { MessageConversationType, MessageParticipantType } from '~/types/messageTypes'
import { applicationSelectors, authSelectors, collaborationSelectors } from '~/selectors'

import { CircularSpinner } from './MessageBar'
import NewChatDialog from './NewChatDialog'

const useStyles = makeStyles(({ palette }) => ({
    messageList: {
        overflow: 'auto',
        maxHeight: '360px',
    },
    listContent: {
        color: palette.text.primary,
    },
    disabled: {
        opacity: 0.5,
        cursor: 'default',
    },
}))

const NewChatBox = ({ classes, onClose, onChatOpen }) => {
    const chatClasses = useStyles()
    const [isOpen, setIsOpen] = useState(true)

    const usersList = useSelector(messengerSelectors.getUserList)
    const loader = useSelector(messengerSelectors.getLoader)
    const userId = useSelector(authSelectors.getUserId)
    const users = useSelector(collaborationSelectors.collaborators)

    const chatList = useSelector(messengerSelectors.getMessageList)
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const appDomainId = useSelector(state => applicationSelectors.getAppDomainId(state, appName))

    const [search, setSearch] = useState('')
    const [animateClass, setAnimateClass] = useState(true)
    const [openClass, setOpenClass] = useState(true)
    const [createLoader, setCreateLoader] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)

    const toggleChatBox = () => {
        setIsOpen(!isOpen)
    }

    const handleClose = () => {
        if (isOpen) {
            setAnimateClass(false)
            setTimeout(() => onClose(), 500)
        } else {
            onClose()
        }
    }

    const handleOpenChat = user => {
        if (!user.DomainId) return

        const isChatExist = chatList.find(
            chat =>
                chat.type === MessageConversationType.DIRECTMESSAGE &&
                Array.isArray(chat.participants) &&
                chat.participants.some(participant => participant.id === user.UserId)
        )

        if (!isChatExist) {
            setCreateLoader(true)
            const conversationRequest: ChatsConversationRequest = {
                domainId: appDomainId,
                ownerId: userId,
                type: MessageConversationType.DIRECTMESSAGE,
                participants: [
                    {
                        id: user.UserId,
                        type: MessageParticipantType.USER,
                    },
                ],
            }

            messageService
                .createNewConversation(conversationRequest)
                .then(response => {
                    const conversationObj = {
                        id: response.id,
                        participants: response.participants,
                        type: MessageConversationType.DIRECTMESSAGE,
                        user: { id: user.UserId, ScreenAlias: user.ScreenAlias },
                        chatData: response,
                    }
                    setAnimateClass(false)
                    setTimeout(() => onChatOpen(conversationObj), 500)
                })
                .catch(error => console.error('Error during conversation Creation:', error))
                .finally(() => {
                    setCreateLoader(false)
                })
        } else {
            const conversationObj = {
                id: isChatExist.id,
                participants: isChatExist.participants,
                type: MessageConversationType.DIRECTMESSAGE,
                user: { id: user.UserId, ScreenAlias: user.ScreenAlias },
                chatData: isChatExist,
            }

            setAnimateClass(false)
            setTimeout(() => onChatOpen(conversationObj), 500)
        }
    }

    const handleDialogOpen = () => setOpenDialog(true)
    const handleDialogClose = () => setOpenDialog(false)

    useEffect(() => {
        setTimeout(() => setOpenClass(false), 700)
    }, [])

    return (
        <Box
            className={classNames(classes.chatBoxContainer, {
                [classes.chatBoxOpen]: isOpen,
                'slide-in-bottom': animateClass && openClass,
                'slide-out-bottom': !animateClass && isOpen,
            })}
        >
            <Box className={classNames(classes.chatBoxToggle, classes.chatHeader)} onClick={toggleChatBox}>
                <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center', ml: 0.5 }}>
                    <Typography variant="body1" sx={{ fontSize: '0.85rem', fontWeight: 500, marginRight: '10px' }}>
                        New Chat
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <IconButton className={classes.buttonStyle} size="small" onClick={handleClose} data-cy="close-chat">
                        <CloseRounded />
                    </IconButton>
                </Box>
            </Box>
            <Box className={`${classes.chatBox} ${isOpen ? classes.chatBoxOpen : ''}`}>
                <TextField
                    id="search-box"
                    placeholder="Search Users"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={search}
                    className={classes.searchBox}
                    onChange={e => setSearch(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                        style: { borderRadius: 10, height: 30 },
                    }}
                />
                {loader || createLoader ? (
                    <CircularSpinner />
                ) : (
                    <List className={chatClasses?.messageList}>
                        <Tooltip title={'Create a new Group chat'}>
                            <ListItem
                                key={'newGroup'}
                                // onClick={() => createNewGroupChat()}
                                onClick={handleDialogOpen}
                                className={chatClasses?.listContent}
                                sx={{ cursor: 'pointer', alignItems: 'flex-start' }}
                            >
                                <ListItemAvatar sx={{ margin: 0 }}>
                                    <IconButton size="small" sx={{ padding: 0.5 }}>
                                        <ForumRounded />
                                    </IconButton>
                                </ListItemAvatar>
                                <ListItemText primary={'Create new group chat'} />
                            </ListItem>
                        </Tooltip>
                        <Divider />
                        {usersList
                            .filter(user => user?.UserId.toLowerCase() !== userId)
                            .filter(user => user?.ScreenAlias.toLowerCase().includes(search.toLowerCase()))
                            .map(user => {
                                const isOnline = users.some(u => u.name === user?.ScreenAlias)
                                return (
                                    <>
                                        <Tooltip
                                            title={
                                                user?.DomainId
                                                    ? 'Start Chat'
                                                    : 'You cannot chat with this user as user does not have domain'
                                            }
                                        >
                                            <ListItem
                                                key={user?.UserId}
                                                onClick={() => handleOpenChat(user)}
                                                className={classNames(chatClasses?.listContent, {
                                                    [chatClasses?.disabled]: !user.DomainId,
                                                })}
                                                sx={{ cursor: 'pointer', alignItems: 'flex-start' }}
                                            >
                                                {/* Disable if user doesnt have domain */}
                                                <ListItemAvatar sx={{ margin: 0 }}>
                                                    <Badge
                                                        overlap="circular"
                                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                        variant="dot"
                                                        invisible={!isOnline}
                                                        color={'success'}
                                                    >
                                                        <Avatar className={classes.avatar}>
                                                            {user?.ScreenAlias[0].toUpperCase()}
                                                        </Avatar>
                                                    </Badge>
                                                </ListItemAvatar>
                                                <ListItemText primary={user?.ScreenAlias} />
                                            </ListItem>
                                        </Tooltip>
                                        <Divider />
                                    </>
                                )
                            })}
                    </List>
                )}
            </Box>
            {openDialog && <NewChatDialog open={openDialog} onClose={handleDialogClose} isPerspectiveChat={false} />}
        </Box>
    )
}

export default NewChatBox
