"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = (generator, {
  integration
}) => generator({
  getApps: () => ({
    baseURL: integration,
    method: 'get',
    url: `apps`
  }),
  getApplicationInfo: application => ({
    baseURL: integration,
    method: 'get',
    url: `services/${application}/info`
  }),
  getInsights: application => ({
    baseURL: integration,
    method: 'get',
    url: `services/${application}/insights`,
    then: data => data && data.map(({
      Id,
      ResourceName,
      Properties,
      Description,
      ShowInBuilder
    }) => ({
      id: Id,
      name: ResourceName,
      description: Description,
      showInBuilder: ShowInBuilder,
      properties: Properties && Properties.map(({
        Id,
        Name,
        DisplayName,
        Property
      }) => {
        const {
          AttributeType,
          AttributeContainerName,
          DataTypeClass
        } = Property;
        return {
          id: Id,
          name: Name,
          title: DisplayName,
          dataType: DataTypeClass,
          containerName: AttributeContainerName,
          containerType: AttributeType === 'Attribute' ? 'node' : AttributeType === 'RelationshipAttribute' ? 'link' : 'insight'
        };
      })
    }))
  }),
  updateApps: apps => ({
    baseURL: integration,
    method: 'put',
    url: `apps/${apps?.Id}`,
    data: apps
  })
});
exports.default = _default;