import { useContext } from 'react'

import { PropertyType } from 'genesis-suite/types/architectureTypes'
import { Aggregation, ChartType, TableConfig } from 'genesis-suite/types/visualTypes'
import { useSemanticTypeById } from '../../../hooks/useSemanticTypes'
import useWidgetColors from '../../../hooks/useWidgetColors'
import { DisplayProperty, DragField, FieldPointer } from '../builderTypes'
import { ConfigContext } from '../ConfigContext'
import { getAllProperties, getDataFieldProperty } from '../utils'
import DnDPropertySelector from './DnDPropertySelector'
import produce from 'immer'

interface Props {
    label?: string
    numericOnly?: boolean
    limit?: number
}
export const typeConfigKeyMapping = {
    Chart: 'ChartConfig',
    Table: 'TableConfig',
}
const CategorySelector = ({ label, numericOnly, limit }: Props) => {
    const semanticTypeById = useSemanticTypeById()
    const { config, dispatch, selectedField, resources, configKey, configStructure } = useContext(ConfigContext)
    const fieldsConfigKey = typeConfigKeyMapping[config?.Type]
    const widgetConfig = config[configKey]
    const { trellis } = config
    const colors = useWidgetColors()
    const categoryFields =
        configStructure?.[fieldsConfigKey]?.filter(field => field.DisplayGroup === 'LabelField') || []
    const labelField = widgetConfig?.LabelField
    const categoryField = widgetConfig?.PivotFieldConfig?.CategoryField
    const correctedIndex = trellis?.enabled ? 1 : 0

    const createFormattedCategory = (
        fieldName: string,
        selectedFieldType: 'category' | 'pivot',
        colorIndex: number
    ): DisplayProperty => {
        const properties = getAllProperties(resources.byId)
        const enrichedField = getDataFieldProperty({ name: fieldName }, properties)
        const field = {
            id: enrichedField?.id,
            name: enrichedField.name,
            resourceId: enrichedField?.container?.id,
            resourceName: enrichedField?.container?.name,
            resourceType: enrichedField?.container?.type,
        }

        return {
            type: enrichedField.semanticType?.type,
            title: enrichedField.displayName || enrichedField.name,
            field: field,
            aggregation: Aggregation.NONE,
            selected: selectedField?.type === selectedFieldType && selectedField?.index === correctedIndex,
            pointer: { type: selectedFieldType, index: correctedIndex },
            color: colors[colorIndex],
        }
    }

    const formattedCategories: DisplayProperty[] = labelField
        ? [createFormattedCategory(labelField, 'category', 0)]
        : categoryField
        ? [createFormattedCategory(categoryField, 'pivot', 0)]
        : []

    const handleAdd = (dragField: DragField, _index?: number) => {
        if (config.Type === 'Chart') {
            const index = trellis?.enabled ? 1 : 0
            const { pointer, type, ...field } = dragField
            const to: FieldPointer = { type: 'category', index }
            const source = { field, aggregation: Aggregation.NONE }
            const options = pointer
                ? {
                      operation: limit === 1 && formattedCategories.length ? ('swap' as const) : ('move' as const),
                      from: pointer,
                  }
                : { operation: 'new' as const, source }

            dispatch({ type: 'UPDATE_SELECTED_PROPERTY', payload: { to, limit, ...options, semanticTypeById } })
        } else if (config?.Type === 'Table') {
            const to: FieldPointer = { type: 'series' }
            const from: FieldPointer = { type: 'pivot' }
            dispatch({
                type: 'UPDATE_SELECTED_PROPERTY',
                payload: { to, from, isPivot: true, pivotFieldConfig: { CategoryField: dragField.name } },
            })
            dispatch({ type: 'SET_SELECTED_FIELD', payload: { type: 'pivot' } })
        }
    }

    const handleRemove = () => {
        if (config?.Type === 'Chart') {
            const payload = {
                LabelField: null,
            }

            // categoryFields.forEach(field => {
            //     payload[field.Name] = null
            // })

            dispatch({ type: 'UPDATE_CATEGORIES', payload })
            dispatch({ type: 'SET_SELECTED_FIELD', payload: null })
            if (trellis?.enabled) {
                dispatch({ type: 'UPDATE_TRELLIS', payload: { enabled: false } })
            }
        } else if (config?.Type === 'Table') {
            const newConfig = produce(config, draft => {
                delete draft[configKey].PivotFieldConfig
            })

            dispatch({ type: 'UPDATE_CONFIG', payload: newConfig })
            dispatch({ type: 'SET_SELECTED_FIELD', payload: null })
        }
    }

    return (
        <DnDPropertySelector
            label={label}
            accept={[PropertyType.DEFINING, PropertyType.QUALITATIVE, PropertyType.QUANTITATIVE]}
            limit={limit}
            onAdd={handleAdd}
            onRemove={handleRemove}
            properties={formattedCategories}
            onFieldSelect={index => {
                // const type = 'category'
                const type = config.Type === 'Table' ? 'pivot' : 'category'
                const adjustedIndex = trellis?.enabled ? index + 1 : index
                dispatch({
                    type: 'SET_SELECTED_FIELD',
                    payload: { type, index: adjustedIndex },
                })
            }}
        />
    )
}

export default CategorySelector
