import { Box, Typography } from '@mui/material'
import { useContext } from 'react'
import { WidgetType } from 'genesis-suite/types/visualTypes'

import { DefaultErrorBoundary } from 'genesis-suite/components'
import { ConfigContext } from '../ConfigContext'
import ChartSelector from '../selectors/ChartSelector'
import DescriptionEditor from './DescriptionEditor'
import WidgetBase from './WidgetBase'
import WidgetAction from '../selectors/WidgetAction'
import DynamicFiltersAction from '../selectors/DynamicFiltersAction'
import WidgetTitleEditor from './WidgetTitleEditor'
import WidgetDisplaySettings from '../selectors/WidgetDisplaySettings'
import PivotColumnEditor from './PivotColumnEditor'
import EditorWrapper from './EditorWrapper'
import LegendEditor from './LegendEditor'
import Orientation from './Orientation'
import FilterSelector from '../selectors/FilterSelector'

const BasicFields = ({ className }) => {
    const { config } = useContext(ConfigContext)
    const { Type } = config

    return (
        <DefaultErrorBoundary>
            <div className={className}>
                <Typography gutterBottom variant="h6">
                    Widget config
                </Typography>
                <Box overflow="auto" flex="1">
                    <WidgetTitleEditor key="0" />
                    <ChartSelector key="1" />
                    <WidgetBase key="3" />
                    {Type === WidgetType.CHART && (
                        <EditorWrapper key="4" header="Orientation">
                            <Orientation />
                        </EditorWrapper>
                    )}
                    {Type === WidgetType.TABLE && (
                        <EditorWrapper key="4" header="Pivot Column">
                            <PivotColumnEditor />
                        </EditorWrapper>
                    )}
                    {/* <FilterSelector root={true} key="2" /> */}
                    <WidgetAction key="5" />
                    <DynamicFiltersAction key="6" />
                    <WidgetDisplaySettings key="7" />
                    <LegendEditor key="8" />
                    <DescriptionEditor key="9" />
                </Box>
            </div>
        </DefaultErrorBoundary>
    )
}

export default BasicFields
