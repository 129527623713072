import { createSelector } from 'reselect'

const getUserList = (store: any) => store.messenger.userList || []

const getMessageList = (store: any) => store.messenger.messageList || []
const getArchiveList = (store: any) => store.messenger.archivedList || []

const getLoader = (store: any) => store.messenger.showLoader

const getPagination = (store: any) => ({
    hasMore: store.messenger.hasMore || false,
    currentPage: store.messenger.currentPage || 1,
})
const getArchivedPagination = (store: any) => ({
    hasMoreArchived: store.messenger.hasMoreArchived || false,
    archivedCurrentPage: store.messenger.archivedCurrentPage || 1,
})

const getAllUserChats = (store: any) => store.messenger.userChatList || []

const getChatByUserId = conversationId =>
    createSelector([getAllUserChats], allChat => {
        const chat = allChat.find(chat => chat?.conversationData?.id === conversationId)
        if (!chat || !chat.chatMessages) return []

        return chat.chatMessages
    })

const getChatByConversationId = (state, conversationId) => {
    const allChat = state.messenger.userChatList || []

    const chat = allChat.find(chat => chat?.conversationData?.id === conversationId)
    if (!chat || !chat.chatMessages) return []

    return chat.chatMessages
}

export const messengerSelectors = {
    getArchiveList,
    getArchivedPagination,
    getPagination,
    getLoader,
    getUserList,
    getMessageList,
    getAllUserChats,
    getChatByUserId,
    getChatByConversationId,
}
