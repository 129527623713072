import produce from 'immer'
import { isEqual } from 'lodash'

import { SemanticTypeById } from 'genesis-suite/types/architectureTypes'
import { Property } from 'genesis-suite/types/networkTypes'
import {
    Aggregation,
    BaseConfig,
    ChartType,
    DataField,
    DataGroup,
    DataSource,
    FilterGroup,
    OneSeries,
    SeriesConfig,
    Service,
    SortProps,
    TooltipType,
    WidgetType,
} from 'genesis-suite/types/visualTypes'
import { ParsedResponse } from '../../types/WidgetTypes'
import { AdvancedFilterEditor, CalculatedPropertyEditor, FieldPointer, SeriesFieldPointer } from './builderTypes'
import { getBoxPlotAggregations, getDefaultAggregation } from './utils'
import { baseUrls } from '~/lib/services'

export interface BuilderStore {
    advancedFilterEditor: AdvancedFilterEditor
    calculatedPropertyEditor: CalculatedPropertyEditor
    config: SeriesConfig
    dataResponse: ParsedResponse
    selectedField: FieldPointer
    service?: { selectedId: string; byId: { [id: string]: Service } }
    tooltipType: TooltipType
    configStructure?: any
    configKey: 'ChartConfig' | 'TableConfig'
    modelName: string
    accessKey: string
}

interface FlipYAxis {
    /** toggle yAxis and series values enforcing yAxis to [primary, secondary] */
    type: 'FLIP_Y_AXIS'
    /** axis to flip to */
    payload: number
}
interface Init {
    /** set/replace whole config and select first series */
    type: 'INIT'
    payload?: { config: SeriesConfig } & Partial<Omit<BuilderStore, 'config'>>
}
interface SetConfig {
    type: 'SET_CONFIG'
    payload: SeriesConfig
}
interface SetDataResponse {
    /** set/replace whole data response */
    type: 'SET_DATA_RESPONSE'
    payload: ParsedResponse
}
interface SetFilters {
    type: 'SET_FILTERS'
    payload: FilterGroup
}
interface SetService {
    type: 'SET_SERVICE'
    payload: { selectedId: string; byId: { [id: string]: Service } }
}
interface SetProperties {
    type: 'SET_PROPERTIES'
    payload: Array<Property>
}
interface SetProperty {
    type: 'SET_PROPERTY'
    payload: Property
}
interface SetSelectedField {
    type: 'SET_SELECTED_FIELD'
    payload: FieldPointer
}
interface SetTooltipType {
    type: 'SET_TOOLTIP_MODE'
    payload: TooltipType
}
interface RemoveProperty {
    type: 'REMOVE_PROPERTY'
    /** property id */
    payload: string
}
interface RemoveSeries {
    type: 'REMOVE_SERIES'
    payload: number
}
interface UpdateActiveSeries {
    type: 'UPDATE_ACTIVE_SERIES'
    payload: Partial<SeriesConfig['series'][0]> & { isPivot?: boolean }
}
interface UpdateAdvancedFilterEditor {
    type: 'UPDATE_ADVANCED_FILTER_EDITOR'
    payload: Partial<AdvancedFilterEditor>
}
interface UpdateCalculatedPropertyEditor {
    /** set/replace whole config and select first series */
    type: 'UPDATE_CALCULATED_PROPERTY_PANEL'
    payload: Partial<CalculatedPropertyEditor>
}
interface UpdateCategories {
    type: 'UPDATE_CATEGORIES'
    payload: any
}
interface UpdateConfig {
    type: 'UPDATE_CONFIG'
    payload: Partial<SeriesConfig>
}
interface UpdateLegend {
    type: 'UPDATE_LEGEND'
    payload: Partial<SeriesConfig['legend']>
}
interface UpdateSelectedProperty {
    type: 'UPDATE_SELECTED_PROPERTY'
    payload: ({ operation?: 'new'; source?: DataSource } | { operation?: 'move' | 'swap'; from?: FieldPointer }) & {
        to: FieldPointer
        limit?: number
        semanticTypeById?: SemanticTypeById
        aggregations?: Aggregation[]
        isPivot?: boolean
        pivotFieldConfig?: any
    }
}
interface UpdateSort {
    type: 'UPDATE_SORT'
    payload: { pointer: FieldPointer } & Partial<SortProps>
}

interface UpdateTrellis {
    type: 'UPDATE_TRELLIS'
    payload: Partial<BaseConfig['trellis']>
}

interface UpdateConfigStructure {
    type: 'UPDATE_CONFIG_STRUCTURE'
    payload: any
}

interface UpdateActions {
    type: 'UPDATE_ACTIONS'
    payload: any
}

interface UpdateSelectedAction {
    type: 'UPDATE_SELECTED_ACTION'
    payload: any
}

interface UpdateRootFiltersAction {
    type: 'UPDATE_ROOT_FILTERS'
    payload: any
}

interface UpdateSeriesFiltersAction {
    type: 'UPDATE_SERIES_FILTERS'
    payload: any
}

interface UpdateDynamicFiltersActions {
    type: 'UPDATE_DYNAMIC_FILTERS'
    payload: any
}

interface UpdateSelectedDynamicFiltersAction {
    type: 'UPDATE_SELECTED_DYNAMIC_FILTER'
    payload: any
}

interface UpdateWidgetConfig {
    type: 'UPDATE_WIDGET_CONFIG'
    payload: any
}

export type BuilderAction =
    | FlipYAxis
    | Init
    | SetConfig
    | SetDataResponse
    | SetFilters
    | SetService
    | SetProperties
    | SetProperty
    | SetSelectedField
    | SetTooltipType
    | RemoveProperty
    | RemoveSeries
    | UpdateActiveSeries
    | UpdateAdvancedFilterEditor
    | UpdateCalculatedPropertyEditor
    | UpdateCategories
    | UpdateConfig
    | UpdateLegend
    | UpdateSelectedProperty
    | UpdateSort
    | UpdateTrellis
    | UpdateConfigStructure
    | UpdateActions
    | UpdateSelectedAction
    | UpdateDynamicFiltersActions
    | UpdateSelectedDynamicFiltersAction
    | UpdateWidgetConfig
    | UpdateRootFiltersAction
    | UpdateSeriesFiltersAction

export default function builderReducer(state: BuilderStore, action: BuilderAction): BuilderStore {
    const activeSeriesIndex = (state.selectedField?.type === 'series' && state.selectedField?.index) || 0
    const activeSeriesPivotIndex =
        (state.selectedField?.type === 'series' &&
            state.selectedField?.subSeries === 'pivot' &&
            state.selectedField?.index) ||
        0
    const activeActionIndex = (state.selectedField?.type === 'action' && state.selectedField?.index) || 0
    const activeDynamicFiltersActionIndex =
        (state.selectedField?.type === 'dynamicFilter' && state.selectedField?.index) || 0
    const activeSeriesKey = state.configKey === 'ChartConfig' ? 'Series' : 'Fields'

    switch (action.type) {
        case 'FLIP_Y_AXIS': {
            return produce(state, draft => {
                if (draft.config.type !== ChartType.COMBO && draft.config.type !== ChartType.BOX_PLOT) return draft

                draft.config.series[activeSeriesIndex].yAxis = action.payload
                if (draft.config.yAxis.length === 1) {
                    if (draft.config.yAxis[0].opposite) draft.config.yAxis.unshift({ opposite: true })
                    else draft.config.yAxis.push({ opposite: true })
                }
            })
        }
        case 'INIT': {
            return { ...initialState, ...action.payload }
        }
        case 'SET_CONFIG': {
            return { ...state, config: action.payload }
        }
        case 'SET_DATA_RESPONSE': {
            const dataResponse = action.payload
            if (!dataResponse || state.selectedField?.type !== 'series') return { ...state, dataResponse }

            const index = state.selectedField?.type === 'series' ? state.selectedField.index ?? 0 : 0
            const categories = getGroups(dataResponse.data[index].data[0])
            const subSeries = state.config.series[index].subSeries && categories.pop()
            const selectedField = { ...state.selectedField, categories, subSeries, index }
            return { ...state, dataResponse, selectedField }
        }
        case 'SET_FILTERS': {
            return { ...state, config: { ...state.config, filters: action.payload } }
        }
        case 'SET_SERVICE': {
            return { ...state, service: action.payload }
        }
        case 'SET_SELECTED_FIELD': {
            const selectedField = action.payload
            if (!selectedField) return { ...state, selectedField: null }

            if (selectedField.type === 'dynamicFilter') return { ...state, selectedField }

            if (selectedField.type === 'action') return { ...state, selectedField }

            if (selectedField.type !== 'series' || !state.dataResponse) return { ...state, selectedField }

            const index = selectedField.index ?? 0
            if (!state.dataResponse?.data[index]) return state

            const categories = getGroups(state.dataResponse.data[index].data[0])
            const subSeries = state.config.series[index].subSeries && categories.pop()
            if (categories.length === selectedField.categories?.length) return { ...state, selectedField }

            return { ...state, selectedField: { ...selectedField, categories, subSeries } }
        }
        case 'SET_TOOLTIP_MODE': {
            return { ...state, tooltipType: action.payload }
        }
        case 'REMOVE_SERIES': {
            return produce(state, draft => {
                draft.config.series.splice(action.payload, 1)
                draft.selectedField = null
            })
        }
        case 'UPDATE_ACTIVE_SERIES': {
            const { payload } = action
            const isPivot = Boolean(payload.isPivot)

            return produce(state, draft => {
                // draft.config.series[activeSeriesIndex] = {
                //     ...draft.config.series[activeSeriesIndex],
                //     ...action.payload,
                // }
                if (isPivot) {
                    delete action.payload.isPivot
                    draft.config[draft.configKey].PivotFieldConfig.ValueFields[activeSeriesPivotIndex] = {
                        ...draft.config[draft.configKey].PivotFieldConfig.ValueFields[activeSeriesPivotIndex],
                        ...action.payload,
                    }
                } else
                    draft.config[draft.configKey][activeSeriesKey][activeSeriesIndex] = {
                        ...draft.config[draft.configKey][activeSeriesKey][activeSeriesIndex],
                        ...action.payload,
                    }
            })
        }
        case 'UPDATE_ROOT_FILTERS': {
            if (action.payload === null) {
                return produce(state, draft => {
                    draft.config[draft.configKey].Source.Filters = action.payload
                })
            }
            return produce(state, draft => {
                draft.config[draft.configKey].Source.Filters = {
                    ...draft.config[draft.configKey]?.Source?.Filters,
                    ...action.payload,
                }
            })
        }
        case 'UPDATE_SERIES_FILTERS': {
            if (action.payload === null) {
                return produce(state, draft => {
                    draft.config[draft.configKey][activeSeriesKey][activeSeriesIndex].Source.Filters = action.payload
                })
            }
            return produce(state, draft => {
                draft.config[draft.configKey][activeSeriesKey][activeSeriesIndex].Source.Filters = {
                    ...draft.config[draft.configKey][activeSeriesKey][activeSeriesIndex]?.Source?.Filters,
                    ...action.payload,
                }
            })
        }
        case 'UPDATE_ADVANCED_FILTER_EDITOR': {
            return { ...state, advancedFilterEditor: { ...state.advancedFilterEditor, ...action.payload } }
        }
        case 'UPDATE_CALCULATED_PROPERTY_PANEL': {
            return { ...state, calculatedPropertyEditor: { ...state.calculatedPropertyEditor, ...action.payload } }
        }
        case 'UPDATE_CATEGORIES': {
            return {
                ...state,
                config: { ...state.config, [state.configKey]: { ...state.config[state.configKey], ...action.payload } },
            }
        }
        case 'UPDATE_CONFIG': {
            //@ts-ignore
            return { ...state, config: { ...state.config, ...action.payload } }
        }
        case 'UPDATE_LEGEND': {
            return {
                ...state,
                config: { ...state.config, [state.configKey]: { ...state.config[state.configKey], ...action.payload } },
            }
        }
        case 'UPDATE_SELECTED_PROPERTY': {
            const { payload } = action
            const { semanticTypeById, to } = payload
            let config = state.config
            const configKey = state.configKey
            const isMulti =
                state.config.Type === WidgetType.CHART ||
                state.config.type === ChartType.BOX_PLOT ||
                state.config.type === ChartType.BULLET
            const isTable = state.config.Type === WidgetType.TABLE
            const isPivot = Boolean(payload.isPivot)

            let dataSource: DataSource
            let series: OneSeries

            if (payload.operation === 'new') {
                // init new source
                dataSource = payload.source
                series = {
                    service: state.service.byId[state.service.selectedId],
                    values: [dataSource],
                    title: dataSource.field.name,
                }
            } else {
                // remove property from its current location
                const { from } = payload

                // Moved property in place
                if (isEqual(to, from)) return state

                switch (from.type) {
                    case 'category': {
                        const { field } = config.categories[from.index]
                        const semanticType = semanticTypeById[field.dataTypeId]
                        const source = { field, aggregation: getDefaultAggregation(semanticType) }
                        series = { values: [source] }
                        dataSource = source

                        config = produce(config, draft => {
                            draft.categories.splice(from.index, 1)
                            if (to.type !== 'category') draft.trellis = { enabled: false }
                        })

                        if (to.type === 'category' && to.index > from.index) to.index--
                        break
                    }
                    case 'subseries': {
                        const { field } = config.series[from.index].subSeries || {}
                        const semanticType = semanticTypeById[field?.dataTypeId]
                        const source = { field, aggregation: getDefaultAggregation(semanticType) }
                        series = { values: [source] }
                        dataSource = source

                        config = produce(config, draft => {
                            delete draft.series[from.index].subSeries
                        })
                        break
                    }
                    case 'series': {
                        const { from, to } = payload
                        if (
                            from.index < 0 ||
                            to.index < 0 ||
                            (isMulti && from.index >= config[configKey].Series.length) ||
                            (isTable && !isPivot && from.index >= config[configKey].Fields.length) ||
                            (isPivot && from.index >= config[configKey].PivotFieldConfig.ValueFields.length) ||
                            (isMulti && to.index >= config[configKey].Series.length) ||
                            (isTable && !isPivot && to.index >= config[configKey].Fields.length) ||
                            (isPivot && to.index >= config[configKey].PivotFieldConfig.ValueFields.length)
                        ) {
                            console.warn('Invalid from/to index for series')
                            return state
                        }

                        config = produce(config, draft => {
                            if (isMulti) {
                                const seriesItem = draft[configKey].Series.splice(from.index, 1)[0]
                                const adjustedToIndex = to.index > from.index ? to.index - 1 : to.index
                                draft[configKey].Series.splice(adjustedToIndex, 0, seriesItem)
                            } else if (isTable && !isPivot) {
                                const fieldItem = draft[configKey].Fields.splice(from.index, 1)[0]
                                const adjustedToIndex = to.index > from.index ? to.index - 1 : to.index
                                draft[configKey].Fields.splice(adjustedToIndex, 0, fieldItem)
                            } else if (isPivot) {
                                const pivotFieldItem = draft[configKey].PivotFieldConfig.ValueFields.splice(
                                    from.index,
                                    1
                                )[0]
                                const adjustedToIndex = to.index > from.index ? to.index - 1 : to.index
                                draft[configKey].PivotFieldConfig.ValueFields.splice(adjustedToIndex, 0, pivotFieldItem)
                            }
                        })

                        return { ...state, config }
                    }
                }
            }

            let replacedField: DataField

            // add property to its new location
            switch (to.type) {
                case 'category': {
                    const { categories } = config
                    const { field } = dataSource

                    //replacedField = categories[categories.length - 1]?.field

                    config = produce(config, draft => {
                        // if (draft.categories.length === payload.limit) draft.categories.shift()
                        // draft.categories.splice(to.index, 0, { title: field.name, field })
                        const selectedService = state.service.byId[state.service.selectedId]
                        const source = {
                            id: state.service.selectedId,
                            ElementName: selectedService.ElementName,
                            ElementType: selectedService.ElementType,
                            Url: `${baseUrls.integration}/${state.modelName}/${selectedService.ElementName}?accessKey=${state.accessKey}`,
                        }
                        draft[configKey] = { ...draft[configKey], Source: source, LabelField: field.name }
                    })
                    break
                }
                case 'subseries': {
                    replacedField = config.series[to.index]?.subSeries?.field
                    config = produce(config, draft => {
                        if (!draft.series.length)
                            draft.series = [{ service: state.service.byId[state.service.selectedId], values: [] }]
                        draft.series[to.index].subSeries = { field: dataSource.field }
                    })

                    break
                }
                case 'series': {
                    if (config.type === ChartType.BOX_PLOT) {
                        if (payload.operation === 'new') {
                            config = produce(config, draft => {
                                let values = []
                                if (payload?.aggregations) {
                                    values = payload.aggregations.map(aggregation => {
                                        return { ...payload.source, aggregation }
                                    })
                                    const val = {
                                        service: state.service.byId[state.service.selectedId],
                                        values,
                                        title: payload.source.field.name,
                                    }
                                    draft.series.unshift(val)
                                } else {
                                    values = [{ ...payload.source }]
                                    const val = {
                                        service: state.service.byId[state.service.selectedId],
                                        values,
                                        title: payload.source.field.name,
                                    }
                                    draft.series.push(val)
                                }
                            })
                        } else {
                            config = produce(config, draft => {
                                const totalBoxPlotAggregations = getBoxPlotAggregations().length
                                const { from } = payload

                                if (series?.values.length === totalBoxPlotAggregations) {
                                    draft.series.splice(from.index, 0, series)
                                } else if (config.series[0]?.values.length === totalBoxPlotAggregations) {
                                    if (from.index > to.index) draft.series.splice(to.index + 1, 0, series)
                                    else draft.series.splice(to.index, 0, series)
                                } else if (from.index < to.index) draft.series.splice(to.index - 1, 0, series)
                                else draft.series.splice(to.index, 0, series)
                            })
                        }
                    } else if (isMulti) {
                        config = produce(config, draft => {
                            // if (draft.series.length === payload.limit) draft.series.shift()
                            // draft.series.splice(to.index, 0, series)
                            const seriesObj = [
                                {
                                    FieldName: series.values[0].field.name,
                                    SeriesName: series.values[0].field.name,
                                    AggregationType: series.values[0].aggregation,
                                    SeriesType: 'Bar',
                                    IsTrendLine: false,
                                    TargetSeriesName: '_Target',
                                    Source: {
                                        id: series.service.id,
                                        ElementName: series.service.ElementName,
                                        ElementType: series.service.ElementType,
                                        Url: `${baseUrls.integration}/${state.modelName}/${series.service.ElementName}?accessKey=${state.accessKey}`,
                                    },
                                    ShowDefaultTooltip: true,
                                    Tooltip: {
                                        TooltipConfigs: [],
                                        TooltipText: '',
                                        TotalRecords: true,
                                    },
                                },
                            ]

                            draft[configKey] = {
                                ...draft[configKey],
                                Series: [...(draft[configKey].Series || []), ...seriesObj],
                            }
                        })
                    } else if (isTable && !isPivot) {
                        config = produce(config, draft => {
                            const fieldObject = [
                                {
                                    FieldName: series?.values[0].field.name,
                                    HeaderName: series?.values[0].field.name,
                                    Id: series?.values[0].field.id,
                                    DefaultSort: 'Ascending',
                                    PropertyType: 'Property',
                                    Source: {
                                        id: series?.service.id,
                                        ElementName: series?.service.ElementName,
                                        ElementType: series?.service.ElementType,
                                    },
                                },
                            ]

                            draft[configKey] = {
                                ...draft[configKey],
                                Fields: [...(draft[configKey].Fields || []), ...fieldObject],
                                Source: fieldObject[0].Source,
                            }
                        })
                    } else if (isPivot) {
                        config = produce(config, draft => {
                            draft[configKey].PivotFieldConfig = {
                                ...draft[configKey].PivotFieldConfig,
                                // ValueFields: [...(draft[configKey].ValueFields || [])],
                                ...payload.pivotFieldConfig,
                            }
                        })
                    } else {
                        config = produce(config, draft => {
                            if (!draft.series.length)
                                draft.series = [{ service: state.service.byId[state.service.selectedId], values: [] }]
                            if (!draft.series[to.index].values) draft.series[to.index].values = []
                            if (draft.series[to.index].values.length === payload.limit)
                                draft.series[to.index].values.shift()
                            draft.series[to.index].values.splice(to.valueIndex, 0, dataSource)
                        })
                    }

                    break
                }
            }

            // move replaced property to target property's previous location
            if (payload.operation === 'swap' && replacedField) {
                config = produce(config, draft => {
                    switch (payload.from.type) {
                        case 'category': {
                            draft.categories.push({ field: replacedField })
                            break
                        }
                        case 'subseries': {
                            draft.series[payload.from.index].subSeries = { field: replacedField }
                            break
                        }
                    }
                })
            }

            const selectedField = payload.operation === 'new' && to.type !== 'subseries' ? to : state.selectedField

            return { ...state, config, selectedField }
        }
        case 'UPDATE_SORT': {
            const { pointer, ...sortProps } = action.payload
            const { type, index = 0 } = pointer
            if (!sortProps.sort) sortProps.sortIndex = null

            const lastSortIndex = () => {
                const categoriesIndexes = state.config.categories?.map(c => c.sortIndex)
                const seriesIndexes = state.config.series[0].values.map(s => s.sortIndex)
                const indexes = [...categoriesIndexes, ...seriesIndexes].filter(i => i !== undefined && i !== null)
                return indexes.length > 0 ? Math.max(...indexes) + 1 : 0
            }

            let newConfig: SeriesConfig
            switch (type) {
                case 'category':
                    //if it's a new sort item, set the sortIndex to the last sortIndex + 1
                    if (
                        sortProps.sort &&
                        (state.config.categories[index].sortIndex === null ||
                            state.config.categories[index].sortIndex === undefined)
                    )
                        sortProps.sortIndex = lastSortIndex()
                    newConfig = produce(state.config, draft => {
                        draft.categories[index] = { ...draft.categories[index], ...sortProps }
                    })
                    break
                case 'series':
                    const valueIndex = (pointer as SeriesFieldPointer).valueIndex
                    newConfig = produce(state.config, draft => {
                        for (let i = 0; i < draft.series.length; i++) {
                            if (i === index) {
                                //if it's a new sort item, set the sortIndex to the last sortIndex + 1
                                if (
                                    sortProps.sort &&
                                    (state.config.series[i].values[valueIndex].sortIndex === undefined ||
                                        state.config.series[i].values[valueIndex].sortIndex === null)
                                )
                                    sortProps.sortIndex = lastSortIndex()
                                draft.series[i].values[valueIndex] = {
                                    ...draft.series[i].values[valueIndex],
                                    ...sortProps,
                                }
                            }
                        }
                    })
                    break
                case 'subseries':
                    newConfig = produce(state.config, draft => {
                        draft.series[index].subSeries = { ...draft.series[index]?.subSeries, ...sortProps }
                    })
                    break
            }

            return { ...state, config: newConfig }
        }
        case 'UPDATE_TRELLIS': {
            return { ...state, config: { ...state.config, trellis: { ...state.config?.trellis, ...action.payload } } }
        }
        case 'UPDATE_CONFIG_STRUCTURE': {
            const { configStructure, configKey } = action.payload
            return produce(state, draft => {
                draft.configStructure = configStructure
                draft.configKey = configKey
            })
        }
        case 'UPDATE_ACTIONS': {
            return { ...state, config: { ...state.config, Actions: action.payload } }
        }
        case 'UPDATE_SELECTED_ACTION': {
            return produce(state, draft => {
                draft.config.Actions[activeActionIndex] = {
                    ...draft.config.Actions[activeActionIndex],
                    ...action.payload,
                }
            })
        }
        case 'UPDATE_DYNAMIC_FILTERS': {
            return { ...state, config: { ...state.config, DynamicFiltersConfig: action.payload } }
        }
        case 'UPDATE_SELECTED_DYNAMIC_FILTER': {
            return produce(state, draft => {
                draft.config.DynamicFiltersConfig[activeDynamicFiltersActionIndex] = {
                    ...draft.config.DynamicFiltersConfig[activeDynamicFiltersActionIndex],
                    ...action.payload,
                }
            })
        }
        case 'UPDATE_WIDGET_CONFIG': {
            return produce(state, draft => {
                draft.config[action.payload.key] = {
                    ...draft.config[action.payload.key],
                    ...action.payload.value,
                }
            })
        }
        default:
            return state
    }
}

function getGroups(data: DataGroup, groups: string[] = []): string[] {
    if (!data) return []

    groups.push(data.group)
    if (data.children?.length > 0) return getGroups(data.children[0], groups)
    return groups
}

const createGuid = () => {
    let d = new Date().getTime()
    const guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (d + Math.random() * 16) % 16 | 0
        d = Math.floor(d / 16)
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
    })
    return guid
}

export const initialState: BuilderStore = {
    advancedFilterEditor: { open: false },
    calculatedPropertyEditor: { open: false },
    config: {
        version: '2',
        Title: '',
        Type: WidgetType.CHART,
        NoDataMessage: 'No results found meeting selected criteria.',
        Id: createGuid(),
        Actions: [],
        DynamicFiltersConfig: [],
        Element: '',
    },
    dataResponse: null,
    selectedField: null,
    service: { byId: {}, selectedId: '' },
    tooltipType: TooltipType.DEFAULT,
    configStructure: {},
    configKey: 'ChartConfig',
    modelName: '',
    accessKey: '',
}
