"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _isEmpty = _interopRequireDefault(require("lodash/isEmpty"));
var _helpers = require("../../utils/helpers");
var _networkTypes = require("../../types/networkTypes");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var _default = (generator, {
  integration,
  orgWeb,
  visor
}) => generator({
  refreshCache: data => ({
    baseURL: visor,
    method: 'post',
    url: `cache/refreshCache`,
    data
  }),
  getEditorConfig: ({
    widgetType
  }) => ({
    baseURL: visor,
    method: 'get',
    url: `widget/editormodel`,
    config: {
      params: {
        widgetType
      }
    }
  }),
  updateArchitecture: (projectId, workspaceId, jobId, sessionKey) => ({
    baseURL: orgWeb,
    method: 'put',
    url: `projects/${projectId}/architecture`,
    data: {},
    config: {
      params: {
        workspaceId,
        jobId,
        accessKey: sessionKey
      }
    }
  }),
  getArchitectureChanges: (projectId, workspaceId, jobId) => ({
    baseURL: orgWeb,
    method: 'get',
    url: `projects/${projectId}/changes`,
    config: {
      params: {
        workspaceId,
        jobId
      }
    }
  }),
  getArchitectureDetails: projectId => ({
    baseURL: orgWeb,
    method: 'get',
    url: `projects/${projectId}/architecture`
  }),
  flush: (type, name, projectId, workspaceId, cloudId, modelId, modelPartId, accessKey) => ({
    baseURL: orgWeb,
    method: 'delete',
    url: `${type}s/${name}/data`,
    config: {
      params: {
        name,
        projectId,
        workspaceId,
        cloudId,
        modelId,
        modelPartId,
        accessKey
      }
    }
  }),
  getArchitectures: appName => ({
    baseURL: integration,
    method: 'get',
    url: `architectures`,
    config: {
      params: {
        modelName: appName
      }
    }
  }),
  getLinks: (application, backupId, onlyNodeNames) => ({
    baseURL: integration,
    method: 'get',
    url: `services/${application}/links`,
    config: {
      params: (0, _helpers.configParams)(onlyNodeNames ? {
        all: 0
      } : {}, backupId)
    }
  }),
  getNodes: (application, backupId) => ({
    baseURL: integration,
    method: 'get',
    url: `services/${application}/nodes`,
    config: {
      params: (0, _helpers.configParams)({}, backupId)
    }
  }),
  getInsights: (application, backupId) => ({
    baseURL: integration,
    method: 'get',
    url: `services/${application}/insights`,
    config: {
      params: (0, _helpers.configParams)({}, backupId)
    }
  }),
  getNetwork: application => ({
    baseURL: integration,
    method: 'get',
    url: `services/${application}/architecture`,
    then: body => {
      const {
        Elements,
        Layers,
        Networks
      } = body;
      const network = Networks?.[0]?.Connections;
      const layers = Layers?.map(layer => {
        return {
          id: layer.Id,
          name: layer.Name,
          elementIds: layer.Elements?.map(e => e.Id) ?? [],
          connectionIds: layer.Connections?.map(c => c.Id) ?? []
        };
      });
      return {
        elements: Elements,
        network,
        layers
      };
    }
  }),
  getNodesLinks: application => ({
    baseURL: integration,
    method: 'get',
    url: `services/${application}/architecture`,
    then: body => {
      const {
        Networks
      } = body;
      if (!Networks || !Networks[0]) return null;
      return Networks[0].Connections.reduce((acc, link) => {
        acc.links.push(link);
        if (link.ConnectionElements) link.ConnectionElements.forEach(n => {
          if (!acc.nodes.some(node => node.Id === n.Id)) acc.nodes.push(n);
        });
        return acc;
      }, {
        nodes: [],
        links: []
      });
    }
  }),
  getNeighborsByNodeName: (moduleName, appName, name) => ({
    baseURL: integration,
    method: 'get',
    url: `context`,
    config: {
      params: {
        cloudName: moduleName,
        modelName: appName,
        resourceType: _networkTypes.ResourceType.NODE,
        name,
        value: null
      }
    },
    then: body => {
      const neighbors = body.reduce((acc, item) => {
        if (!item.IsWidget) {
          acc.push({
            nodeName: item.Name,
            primaryProperty: item.KeyField.split(':')[0],
            nameProperty: item.NameField && item.NameField.split(':')[0]
          });
        }
        return acc;
      }, []);
      const perspectives = body.reduce((acc, item) => {
        if (item.IsWidget && item.Value && item.Value.ContainerConfig) acc.push(item.Value.ContainerConfig.Id);
        return acc;
      }, []);
      return {
        neighbors,
        perspectives
      };
    }
  }),
  getNodesAndLinks: contextName => ({
    baseURL: integration,
    method: 'get',
    url: `contexts`,
    config: {
      params: {
        contextName
      }
    }
  }),
  getResourceMeta: (application, nodeName, backupId) => ({
    baseURL: integration,
    method: 'get',
    url: `${application}/${nodeName}/meta`,
    config: {
      params: (0, _helpers.configParams)({}, backupId)
    }
  }),
  getNetworkFunctions: (modelPartName, resourceType, resourceName) => ({
    baseURL: integration,
    method: 'get',
    url: `/functions`,
    config: {
      params: {
        modelName: modelPartName,
        ...(resourceType === _networkTypes.ResourceType.NODE && {
          nodeName: resourceName
        }),
        ...(resourceType === _networkTypes.ResourceType.LINK && {
          linkName: resourceName
        }),
        ...(resourceType === _networkTypes.ResourceType.INSIGHT && {
          insightName: resourceName
        })
      }
    }
  }),
  getProfile: (application, nodeName, field, value) => ({
    baseURL: integration,
    method: 'get',
    url: `${application}/${nodeName}/profile`,
    config: {
      params: {
        cf: `${field}=${value}`
      }
    },
    then: data => {
      if ((0, _isEmpty.default)(data)) return {
        properties: [],
        linkedNodes: []
      };
      const {
        meta: {
          EntityProfileConfig
        },
        [nodeName]: [nodeData],
        ...linkedNodeByName
      } = data;
      const properties = EntityProfileConfig.ProfileProperties?.map(a => ({
        id: a.PropertyId,
        name: a.PropertyName,
        value: nodeData[a.PropertyName]
      }));
      const linkedNodes = EntityProfileConfig.LinkedEntities?.map(n => ({
        id: n.ChildNodeId,
        name: n.ChildNodeName,
        keyPropertyName: n.Properties[0].PropertyName,
        properties: n.Properties.map(p => ({
          id: p.PropertyId,
          name: p.PropertyName
        })),
        values: linkedNodeByName[n.ChildNodeName].map(d => {
          return n.Properties.reduce((acc, cur) => {
            return {
              ...acc,
              [cur.PropertyName]: d[cur.PropertyName]
            };
          }, {});
        })
      }));
      return {
        properties,
        linkedNodes
      };
    }
  }),
  getProfiles: (application, data, backupId, loadOnlyConnected) => ({
    baseURL: integration,
    method: 'post',
    url: `profiles`,
    config: {
      params: (0, _helpers.configParams)({
        modelName: application,
        ...(loadOnlyConnected && {
          loadOnlyConnected
        })
      }, backupId)
    },
    data
  }),
  getProperties: application => ({
    baseURL: integration,
    method: 'get',
    url: `services/${application}/properties`
  }),
  saveProfile: (appName, data) => ({
    baseURL: integration,
    method: 'post',
    url: `nodes`,
    data,
    config: {
      params: {
        modelName: appName
      }
    }
  }),
  setFunction: (modelPartName, resourceType, resourceName, data) => ({
    baseURL: integration,
    method: 'post',
    url: `/functions`,
    data,
    config: {
      params: {
        modelName: modelPartName,
        ...(resourceType === _networkTypes.ResourceType.NODE && {
          nodeName: resourceName
        }),
        ...(resourceType === _networkTypes.ResourceType.LINK && {
          linkName: resourceName
        }),
        ...(resourceType === _networkTypes.ResourceType.INSIGHT && {
          insightName: resourceName
        })
      }
    }
  }),
  deleteFunction: (modelPartName, id) => ({
    baseURL: integration,
    method: 'delete',
    url: `/functions`,
    config: {
      params: {
        modelName: modelPartName,
        id
      }
    }
  }),
  setTreeFunction: (modelPartName, id, data) => ({
    baseURL: integration,
    method: 'post',
    url: 'tree/functions',
    data,
    config: {
      params: {
        modelName: modelPartName,
        id
      }
    }
  }),
  deleteTreeFunction: (modelPartName, treeId, id) => ({
    baseURL: integration,
    method: 'delete',
    url: 'tree/functions',
    config: {
      params: {
        modelName: modelPartName,
        treeId,
        id
      }
    }
  }),
  setResourceFilter: (modelPartName, resourceType, resourceName, data) => ({
    baseURL: integration,
    method: 'post',
    url: `/filters`,
    data,
    config: {
      params: {
        modelName: modelPartName,
        ...(resourceType === _networkTypes.ResourceType.NODE && {
          nodeName: resourceName
        }),
        ...(resourceType === _networkTypes.ResourceType.INSIGHT && {
          insightName: resourceName
        })
      }
    }
  }),
  deleteResourceFilter: (modelPartName, resourceType, resourceName, id) => ({
    baseURL: integration,
    method: 'delete',
    url: `/filters`,
    config: {
      params: {
        modelName: modelPartName,
        ...(resourceType === _networkTypes.ResourceType.NODE && {
          nodeName: resourceName
        }),
        ...(resourceType === _networkTypes.ResourceType.INSIGHT && {
          insightName: resourceName
        })
      }
    },
    data: {
      Id: id
    }
  }),
  updateNodeProperty: (id, nodeId, modelName, data) => ({
    baseURL: integration,
    method: 'put',
    url: `nodes/properties`,
    data,
    config: {
      params: {
        id: id,
        nodeId: nodeId,
        modelName: modelName
      }
    }
  }),
  updateLinkProperty: (id, linkId, modelName, data) => ({
    baseURL: integration,
    method: 'put',
    url: `links/properties`,
    data,
    config: {
      params: {
        id: id,
        nodeId: linkId,
        modelName: modelName
      }
    }
  })
});
exports.default = _default;