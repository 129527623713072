export const messengerType = {
    UPDATE_USER_LIST: 'UPDATE_USER_LIST',
    UPDATE_MESSAGES_LIST: 'UPDATE_MESSAGES_LIST',
    UPDATE_USER_CHAT_LIST: 'UPDATE_USER_CHAT_LIST',
    UPDATE_USER_MESSAGES: 'UPDATE_USER_MESSAGES',
    TOGGLE_LOADER: 'TOGGLE_LOADER',
    UPDATE_PAGINATION: 'UPDATE_PAGINATION',
    UPDATED_CHAT_LIST: 'UPDATED_CHAT_LIST',
    UPDATE_ARCHIVE_MESSAGES_LIST: 'UPDATE_ARCHIVE_MESSAGES_LIST',
    UPDATE_ARCHIVED_PAGINATION: 'UPDATE_ARCHIVED_PAGINATION',
}
