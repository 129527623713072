import { useState, useEffect, createElement, forwardRef, useCallback, useMemo } from 'react'
import { createPortal } from 'react-dom'
import ReactTooltip from 'react-tooltip'

import { Box, ListItem, Popover, List, ListItemIcon, ListItemText, Button, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { DrillDown, InfiniteNav, InlineFilter, ShowDetails, Profile as ProfileIcon } from 'genesis-suite/icons'
import { FileCopyOutlined, CalculateOutlined, KeyboardArrowRightRounded } from '@mui/icons-material'

import { logEvent } from '../../lib/amplitudeClient'
import { useFeature } from '../../lib/featureFlags'
import useWidgetAction from '../WidgetActions/useWidgetAction'
import { widgetSelectors } from '~/selectors'
import { useSelector } from 'react-redux'
import getDefaultPerspective from '~/lib/getDefaultPerspective'

const useStyles = makeStyles(({ zIndex }) => ({
    root: {
        minHeight: 0, //keep this here so that tables stay in their container
        flexGrow: 3,
        flexShrink: 3,
        height: '100%',
        cursor: ({ canNavigate }) => (canNavigate ? 'pointer' : 'inherit'),
    },
    tooltip: { maxWidth: 'min(500px, 90vw)' },
    tooltipDisplay: {
        '& .__react_component_tooltip': {
            zIndex: zIndex.tooltip,
        },
    },
}))

/**
 * Adds tooltip storing tooltipConfig state and passing setTooltipConfig to Component (Widget)
 */
const withWidgetTooltip = Component =>
    forwardRef((props, ref) => {
        const [showProfiles] = useFeature('profiles')
        const [showDetails] = useFeature('show_details')

        const [tooltipConfig, setTooltipConfig] = useState(null)
        // tooltip data cached in order to maintain tooltipConfig when the interaction popover is open
        const [cached, setCached] = useState(null)
        const [desktopRightClickPosition, setDesktopRightClickPosition] = useState(null)

        useEffect(() => {
            if (tooltipConfig) setCached(tooltipConfig)
        }, [tooltipConfig])

        const tooltipId = props?.config.Id
        const isControlWidget = props?.config.Id === props.controlWidget?.Id
        const showCopyOption = props?.config?.Type?.toUpperCase() === 'TABLE'
        const showInlineActions = props?.showInlineActions ?? true
        const actions = showInlineActions && props?.config?.Actions ? props.config.Actions : []
        const showActionFilter = showInlineActions && actions?.length
        const widgetActions = showActionFilter ? actions.map(action => useWidgetAction(action)) : []
        const {
            html,
            mobileClickPosition,
            handleInteraction,
            inlineFilterFlag,
            seriesConfig,
            point,
            cellValue,
            buildCrumb,
        } = cached || {}
        const interactionPosition = mobileClickPosition
            ? { left: mobileClickPosition.pageX, top: mobileClickPosition.pageY - 20 }
            : desktopRightClickPosition || null

        const value = useSelector(widgetSelectors.getInteractionType)

        const classes = useStyles({ canNavigate: tooltipConfig?.canNavigate })

        const id = useMemo(() => {
            if (typeof buildCrumb !== 'function') return null
            const crumb = buildCrumb(seriesConfig, point, props?.config, cellValue)
            return getDefaultPerspective(crumb)
        }, [buildCrumb, seriesConfig, point, props?.config, cellValue])

        const perspective = useSelector(s => widgetSelectors.getWidgetConfig(s, id))

        function handleRightClick(e) {
            e.preventDefault()
            e.stopPropagation()
            if (!desktopRightClickPosition) return setDesktopRightClickPosition({ left: e.pageX, top: e.pageY })

            setTooltipConfig(null)
            setDesktopRightClickPosition(null)
        }

        const handleClick = event => () => {
            handleInteraction(event)
            handleClose()
        }

        const handleActionCB = useCallback(
            event => {
                if (typeof handleInteraction === 'function') {
                    const resultFilter = handleInteraction(event)
                    handleClose()
                    return resultFilter
                }
            },
            [handleInteraction, handleClose]
        )

        function handleClose() {
            setCached(null)
            setTooltipConfig(null)
            setDesktopRightClickPosition(null)
        }

        function openProfile() {
            logEvent('OPEN_PROFILE', { method: 'widget' })
            handleClick('onShowProfile')()
        }

        const processedActions = actions.map((action, index) => {
            const [handleActionOpen, icon] = widgetActions[index]
            const handleActionClick = () => {
                const filter = handleActionCB('onActionFilters')
                handleActionOpen(filter)
            }

            return {
                title: action?.ActionName,
                Icon: icon,
                onClick: handleActionClick,
            }
        })

        const interactionOptions = [
            {
                onClick: handleClick('onInfiniteNav'),
                title: 'Infinite Navigation',
                Icon: InfiniteNav,
                hide: true,
            },
            { onClick: handleClick('onDrillDown'), title: 'Drill Down', Icon: DrillDown, hide: true },
            {
                onClick: handleClick(value === 'infinite' ? 'onInfiniteNav' : 'onDrillDown'),
                title: `Go to ${perspective?.Name || ''}`,
                Icon: value === 'infinite' ? InfiniteNav : DrillDown,
                hide: false,
            },
            ...(!isControlWidget
                ? [
                      {
                          onClick: handleClick('onInlineFilters'),
                          title: inlineFilterFlag ? 'Reset Filter' : 'Inline Filter',
                          Icon: InlineFilter,
                          hide: false,
                      },
                  ]
                : []),
            ...(showDetails
                ? [
                      {
                          onClick: handleClick('onShowDetails'),
                          title: 'Show Details',
                          Icon: ShowDetails,
                          hide: false,
                      },
                  ]
                : []),
        ]

        const interactionMenu = mobileClickPosition ? (
            <MobileMenu
                html={html}
                profileInteraction={showProfiles && { onClick: openProfile, title: 'Profile', Icon: ProfileIcon }}
                interactions={interactionOptions?.filter(i => !i.hide)}
            />
        ) : (
            <DesktopMenu
                interactions={[
                    ...(showProfiles ? [{ onClick: openProfile, title: 'Profile', Icon: ProfileIcon }] : []),
                    ...(!isControlWidget
                        ? [
                              {
                                  onClick: handleClick('onInlineFilters'),
                                  title: inlineFilterFlag ? 'Reset Filter' : 'Inline Filter',
                                  Icon: InlineFilter,
                              },
                          ]
                        : []),
                    ...(!isControlWidget && showActionFilter
                        ? [
                              {
                                  title: 'Action Filter',
                                  Icon: InlineFilter,
                                  children: processedActions,
                              },
                          ]
                        : []),
                    ...(showDetails
                        ? [{ onClick: handleClick('onShowDetails'), title: 'Show Details', Icon: ShowDetails }]
                        : []),
                    { onClick: handleClick('onHighlightRow'), title: 'Highlight/Clear Row', Icon: ShowDetails },
                    { onClick: handleClick('onCalculator'), title: 'Use on Calculator', Icon: CalculateOutlined },
                    ...(showCopyOption
                        ? [{ onClick: handleClick('onCopy'), title: 'Copy', Icon: FileCopyOutlined }]
                        : []),
                ]}
            />
        )

        const { interactions = {} } = props
        const { disableContextMenu } = interactions

        return (
            <div
                className={classes.root}
                data-for={tooltipId}
                data-tip
                onContextMenu={!Boolean(cached) || disableContextMenu ? undefined : handleRightClick}
            >
                <Component setTooltipConfig={setTooltipConfig} {...props} ref={ref} />

                {props.interactions !== false && (
                    <>
                        {!disableContextMenu && (
                            <Popover
                                open={Boolean(interactionPosition)}
                                anchorReference="anchorPosition"
                                anchorPosition={interactionPosition || { top: 0, left: 0 }}
                                transformOrigin={{
                                    vertical: mobileClickPosition ? 'bottom' : 'top',
                                    horizontal: mobileClickPosition ? 'center' : 'left',
                                }}
                                onClose={handleClose}
                            >
                                {interactionMenu}
                            </Popover>
                        )}

                        {!mobileClickPosition && tooltipConfig?.html && (
                            <Tooltip classes={classes} id={tooltipId} html={tooltipConfig.html} />
                        )}
                    </>
                )}
            </div>
        )
    })

export default withWidgetTooltip

function Tooltip({ classes, id, html }) {
    const tooltip = (
        <div className={classes.tooltipDisplay}>
            <ReactTooltip id={id} place="bottom">
                <div className={classes.tooltip} dangerouslySetInnerHTML={{ __html: html }} />
            </ReactTooltip>
        </div>
    )

    return createPortal(tooltip, document.body)
}

const useMenuStyles = makeStyles(({ palette }) => {
    const topBarColor = palette.background.topBar || ''
    const topBarContrast = palette.getContrastText(topBarColor)
    return {
        list: { background: topBarColor },
        item: {
            '& svg': { color: topBarContrast },
        },
        optionText: {
            color: topBarContrast,
            '& span': { color: topBarContrast },
        },
    }
})

function DesktopMenu({ interactions }) {
    const classes = useMenuStyles({})
    const [submenuAnchor, setSubmenuAnchor] = useState(null)
    const [submenuItems, setSubmenuItems] = useState([])

    const handleOpenSubmenu = (event, children) => {
        setSubmenuAnchor(event.currentTarget)
        setSubmenuItems(children)
    }

    const handleCloseSubmenu = () => {
        setSubmenuAnchor(null)
        setSubmenuItems([])
    }
    return (
        <List className={classes.list}>
            {interactions.map(({ onClick, title, Icon, children }) => (
                <ListItem
                    button
                    key={title}
                    className={classes.item}
                    onClick={children ? event => handleOpenSubmenu(event, children) : onClick}
                >
                    <ListItemIcon>
                        <Icon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText className={classes.optionText}>{title}</ListItemText>
                    {children && <KeyboardArrowRightRounded />}
                </ListItem>
            ))}

            <Popover
                anchorEl={submenuAnchor}
                open={Boolean(submenuAnchor)}
                onClose={handleCloseSubmenu}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <List className={classes.list}>
                    {submenuItems.map(({ onClick: subOnClick, title: subTitle, Icon: SubIcon }) => (
                        <ListItem
                            button
                            key={subTitle}
                            onClick={() => {
                                handleCloseSubmenu()
                                if (subOnClick) subOnClick()
                            }}
                            className={classes.item}
                            sx={{ minWidth: 120 }}
                        >
                            <ListItemIcon>{SubIcon}</ListItemIcon>
                            <ListItemText className={classes.optionText}>{subTitle}</ListItemText>
                        </ListItem>
                    ))}
                </List>
            </Popover>
        </List>
    )
}

const useMobileMenuStyles = makeStyles(({ palette, spacing }) => {
    return {
        root: { border: `1px solid ${palette.primary.main}`, borderRadius: '4px' },
        header: {
            background: palette.primary.main,
            color: palette.primary.contrastText,
            padding: spacing(),
            display: 'flex',
            justifyContent: 'space-between',
        },
        profileIcon: { color: 'inherit' },
        button: { justifyContent: 'flex-start' },
        interactionText: {
            paddingLeft: spacing(),
            textAlign: 'left',
        },
        footer: { background: palette.primary.main, height: spacing() },
    }
})

function MobileMenu({ html, interactions, profileInteraction }) {
    const classes = useMobileMenuStyles()

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div dangerouslySetInnerHTML={{ __html: html }} />
                {profileInteraction &&
                    createElement(profileInteraction.Icon, {
                        onClick: profileInteraction.onClick,
                        className: classes.profileIcon,
                    })}
            </div>

            <Box display="grid" gridTemplateColumns="120px 94px" gridTemplateRows="50px 50px" gridAutoFlow="column">
                {interactions.map(({ onClick, title, Icon }) => (
                    <Button key={title} className={classes.button} onClick={onClick}>
                        <Icon fontSize="small" />
                        <Typography className={classes.interactionText}>{title}</Typography>
                    </Button>
                ))}
            </Box>

            <div className={classes.footer} />
        </div>
    )
}
