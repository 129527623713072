import MoreIcon from '@mui/icons-material/ArrowDropDownRounded'
import ExpandIcon from '@mui/icons-material/ExpandMore'
import { Skeleton } from '@mui/lab'
import { Box, Button, Popover, Typography } from '@mui/material'
import { isEqual, random } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import Measure from 'react-measure'
import { useDispatch, useSelector } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'

import { ChopText, CognitiveIcon, MenuIcon } from 'genesis-suite/components'
import ActiveIcon from 'genesis-suite/icons/Donut'
import { sleep } from 'genesis-suite/utils'
import { navigationCreators } from '../actions/creators'
import useIsItemAtRoute from '../hooks/useIsItemAtRoute'
import { routePaths } from '../lib/routes'
import { computeHiddenCount, openInNewTab } from '../lib/utils'
import { menuSelectors, moduleSelectors } from '../selectors'

export default function ClassicViews() {
    const modulePending = useSelector(moduleSelectors.getModulesPending)
    const views = useSelector(menuSelectors.getViews)

    const parentRef = useRef<HTMLDivElement>(null)
    const [hiddenCount, setHiddenCount] = useState(0)
    const [layingOut, setLayingOut] = useState(true)
    const [recompute, setRecompute] = useState(false)
    const [openHidden, setOpenHidden] = useState(false)

    const _360Items = views.map(({ subMenuConfig, ...rest }) => ({ items: subMenuConfig, ...rest }))
    const visibleItems = layingOut ? _360Items : _360Items.slice(0, _360Items.length - hiddenCount)
    const hiddenItems = _360Items.slice(-hiddenCount)

    const lastViews = useRef(null)
    useEffect(() => {
        if (isEqual(views, lastViews.current)) return

        lastViews.current = views
        resetLayout()
    }, [views])

    const handleResize = useDebouncedCallback(resetLayout, 500)

    async function resetLayout() {
        setLayingOut(true)
        setOpenHidden(false)
        await sleep()
        setRecompute(s => !s)
    }

    useEffect(() => {
        setHiddenCount(computeHiddenCount(parentRef, 1, 32))
        setLayingOut(false)
    }, [recompute])

    if (modulePending)
        return (
            <Box sx={{ display: 'flex', gap: 1, ml: 1 }}>
                {new Array(4).fill(0).map((_, i) => (
                    <Skeleton key={i} width={random(75, 150)} height="100%" />
                ))}
            </Box>
        )

    if (!views.length) return <Typography sx={{ ml: 1 }}>No views</Typography>

    return (
        <Measure client innerRef={parentRef} onResize={handleResize}>
            {({ measureRef }) => (
                <Box ref={measureRef} sx={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
                    {visibleItems.map((v, i) => (
                        <ViewItem key={i} item={v} hidden={layingOut} top />
                    ))}

                    <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                        <MenuIcon
                            buttonProps={{
                                sx: { visibility: hiddenCount ? 'visible' : 'hidden', color: 'text.primary' },
                            }}
                            icon={<MoreIcon fontSize="small" />}
                            onClick={() => setOpenHidden(true)}
                            onClose={() => setOpenHidden(false)}
                            open={openHidden}
                            tooltip="More"
                        >
                            {hiddenItems.map((v, i) => (
                                <ViewItem key={i} item={v} overflow onDone={() => setOpenHidden(false)} />
                            ))}
                        </MenuIcon>
                    </Box>
                </Box>
            )}
        </Measure>
    )
}

function ViewItem({ item, onDone = undefined, hidden = false, overflow = false, top = false }) {
    const { type, to, isExternal, text, items, icon } = item
    const atRoute = useIsItemAtRoute(item)
    const ref = useRef()
    const dispatch = useDispatch()

    const [openMenu, setOpenMenu] = useState(false)
    const isParent = Boolean(items?.length)

    function handleClick(e) {
        if (isParent) return setOpenMenu(true)

        onDone?.(e)
        if (!to || atRoute) return

        if (isExternal) openInNewTab(to)
        else if (type === 'viewId') dispatch(navigationCreators.goToPerspective(to))
        else if (type === 'widgetId') dispatch(navigationCreators.goTo(routePaths.WIDGET, to))
        else if (type === 'elementName') dispatch(navigationCreators.goTo(routePaths.ELEMENT, to))
        else if (type === 'treeName') dispatch(navigationCreators.goTo(routePaths.TREE, to))
    }

    return (
        <>
            <Box
                sx={{
                    alignItems: top ? 'center' : 'stretch',
                    display: 'flex',
                    flexDirection: 'column',
                    visibility: hidden ? 'hidden' : 'visible',
                    color: top ? '#FFF' : 'text.primary',
                }}
            >
                <Button color="inherit" onClick={handleClick} ref={ref} sx={{ gap: 0.5, justifyContent: 'flex-start' }}>
                    {icon ? <CognitiveIcon bgColor="#24292E" fontSize="small" icon={icon} /> : undefined}
                    <ChopText
                        showEllipsis
                        lineCount={2}
                        sx={{ maxWidth: '150px', fontWeight: top ? 'bold' : 'inherit' }}
                        tooltipProps={{ placement: 'bottom' }}
                    >
                        {text}
                    </ChopText>
                    {atRoute && !top && <ActiveIcon color="primary" />}
                    {isParent && <ExpandIcon fontSize="small" />}
                </Button>

                {top && (
                    <Box
                        sx={{
                            backgroundColor: atRoute ? 'primary.main' : undefined,
                            borderRadius: '2px',
                            height: '3px',
                            width: '30px',
                        }}
                    />
                )}
            </Box>

            {isParent && (
                <Popover
                    anchorEl={ref.current}
                    anchorOrigin={{ vertical: overflow ? 'top' : 'bottom', horizontal: 'left' }}
                    onClose={() => setOpenMenu(false)}
                    open={openMenu}
                    transformOrigin={{ vertical: 'top', horizontal: overflow ? 'right' : 'left' }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                        {items.map((v, i) => (
                            <ViewItem
                                item={v}
                                onDone={e => {
                                    setOpenMenu(false)
                                    onDone?.(e)
                                }}
                            />
                        ))}
                    </Box>
                </Popover>
            )}
        </>
    )
}
