import { Save } from '@mui/icons-material'
import PsychologyAltRoundedIcon from '@mui/icons-material/PsychologyAltRounded'
import { FormControlLabel, IconButton, Popover, Switch, Tooltip, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { MenuIcon } from 'genesis-suite/components'
import { ExpandWidget, Settings as SettingsIcon } from 'genesis-suite/icons'
import { isEmpty } from 'lodash'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { widgetCreators } from '~/actions/creators'
import { widgetService } from '~/lib/services'
import { useIsMobile } from '../../hooks/useIsMobile'
import { logEvent } from '../../lib/amplitudeClient'
import { applicationSelectors, authSelectors, moduleSelectors, widgetSelectors } from '../../selectors'
import Slideshow from '../Slideshow'
import { WidgetSummaryDialog } from '../WidgetSummaryDialog'
import { PerspectiveWidgetContext } from '../contexts/PerspectiveWidgetContext'
import { PopoutContext } from '../contexts/PopoutWidgetContext'
import KanbanColumnsSelector from '../widgets2/form/kanban/KanbanColumnsSelector'
import EditWidget from './EditWidget'
import SyncChartButton from './SyncChartButton'
import WidgetFilters from './WidgetFilters'
import WidgetGTMButton from './WidgetGTMButton'
import WidgetShareButton from './WidgetShareButton'
import WidgetExportController from './WidgetExportController'

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
}))

const WidgetToolbar = ({
    config,
    visualRef,
    perspectiveId,
    iconStyle,
    buttonStyle,
    canMultipleEdit,
    setCanMultipleEdit,
    isPopup,
    data,
}) => {
    const classes = useStyles()
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const isPublicLogin = useSelector(authSelectors.getIsPublicLogin)
    const disabled = !Boolean(useSelector(widgetSelectors.getCurrentPerspectiveId))
    const enableAIChat = useSelector(moduleSelectors.getEnableAIChat)
    const isAdmin = useSelector(authSelectors.getIsAdmin)
    const perspectiveWidgets = useSelector(s => widgetSelectors.getWidgetConfigsForPerspective(s, perspectiveId))
    const Model = useSelector(applicationSelectors.getCurrentAppName)
    const moduleId = useSelector(moduleSelectors.getModuleId)
    const isUserWidget = !config?.IsManaged
    const [transactionNodes, setTransactionNodes] = useState([])
    const [settingsAnchorEl, setSettingsAnchorEl] = useState(null)
    const showMultiEditToggle = false

    const { closePopout } = useContext(PopoutContext)

    const {
        showSave,
        enableSave,
        handleSave,
        setIsEdit,
        showSettings,
        settingsComponent: SettingsComponent,
        setOnSettingsApply,
        updateWidgetDataById,
    } = useContext(PerspectiveWidgetContext)

    const { Name, Description, Title } = config
    const widgetData = data?.seriesData ? data.seriesData : data?.data ? data.data : data
    const [slideShowIndex, setSlideshowIndex] = useState<number>(null)
    const [openSummaryDialog, setOpenSummaryDialog] = useState<boolean>(false)
    const { palette, custom } = useTheme()

    const defaultIconProps = { style: { fontSize: custom.perspectiveToolbar?.fontSize, color: palette.text.primary } }

    function startSlideshow(widgetId) {
        const startIndex = Math.max(
            perspectiveWidgets.findIndex(w => w.Id === widgetId),
            0
        )
        setSlideshowIndex(startIndex)
    }

    useEffect(() => {
        if (config?.Type?.toUpperCase() !== 'REPORTWIDGET' && config?.Type?.toUpperCase() !== 'FORM') {
            ;(async function getTransactionalNodes() {
                try {
                    const response = await widgetService.getGTMTransactionNodes(moduleId, Model, config.Id)
                    if (!isEmpty(response)) setTransactionNodes(response)
                } catch {
                    console.error('There was an error fetching widget GTM Data. Please try again later.')
                }
            })()
        }
        setOnSettingsApply?.(() => {
            return () => setSettingsAnchorEl(null)
        })

        updateWidgetDataById(Name, { Name, Description, Title, data: widgetData })
    }, [config, data])

    if (isPublicLogin) return null

    const onClickEdit = text => {
        const Id = config.Id
        if (text == 'Save') {
            dispatch(widgetCreators.LabelWidgetSave())
        }
        setIsEdit(item => ({
            ...item,
            [Id]: text,
        }))
    }

    const onExplainClick = () => {
        setOpenSummaryDialog(!openSummaryDialog)
    }

    const handleSummaryDialogClose = useCallback(() => {
        setOpenSummaryDialog(false)
    }, [])

    return (
        <div className={classes.root}>
            <KanbanColumnsSelector />
            {!isMobile && (
                <>
                    {showMultiEditToggle && !isUserWidget && config?.Type?.toUpperCase() === 'FORM' && (
                        <Tooltip title="Try multi edit">
                            <FormControlLabel
                                control={
                                    <Switch
                                        color="primary"
                                        checked={canMultipleEdit}
                                        onChange={() => {
                                            setCanMultipleEdit(!canMultipleEdit)
                                        }}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                                label="Multi edit"
                                labelPlacement="start"
                            />
                        </Tooltip>
                    )}

                    {showSettings && (
                        <Tooltip title="Settings">
                            <div>
                                <IconButton
                                    sx={{ padding: '10px', color: 'text.primary' }}
                                    size="large"
                                    onClick={e => setSettingsAnchorEl(e.currentTarget)}
                                >
                                    <SettingsIcon />
                                </IconButton>
                            </div>
                        </Tooltip>
                    )}

                    {showSave && (
                        <Tooltip title="Save">
                            <div>
                                <IconButton
                                    sx={{ padding: '10px', color: 'text.primary' }}
                                    size="large"
                                    disabled={!enableSave}
                                    onClick={() => handleSave?.()}
                                >
                                    <Save />
                                </IconButton>
                            </div>
                        </Tooltip>
                    )}

                    {isAdmin &&
                        !isUserWidget &&
                        (config.Type.toUpperCase() === 'LABEL' || config.Type.toUpperCase() === 'RICHTEXT') && (
                            <EditWidget
                                config={config}
                                buttonStyle={buttonStyle}
                                iconStyle={iconStyle}
                                onClick={onClickEdit}
                            />
                        )}
                    <SyncChartButton config={config} iconStyle={iconStyle} buttonStyle={buttonStyle} />

                    {transactionNodes.length > 0 && !isUserWidget && (
                        <WidgetGTMButton
                            buttonStyle={buttonStyle}
                            iconStyle={iconStyle}
                            widgetId={config.Id}
                            transactionNodes={transactionNodes}
                        />
                    )}
                    {!isUserWidget && (
                        <WidgetShareButton
                            config={config}
                            visualRef={visualRef}
                            buttonStyle={buttonStyle}
                            iconStyle={iconStyle}
                        />
                    )}

                    <WidgetExportController
                        config={config}
                        visualRef={visualRef}
                        buttonStyle={buttonStyle}
                        iconStyle={iconStyle}
                    />

                    {isAdmin && !isUserWidget && enableAIChat && (
                        <MenuIcon
                            buttonProps={{ sx: buttonStyle }}
                            closeButton
                            icon={<PsychologyAltRoundedIcon {...defaultIconProps} />}
                            disabled={disabled}
                            onClick={() => onExplainClick()}
                            title="Explains Widget"
                            tooltip="What am I looking at?"
                        ></MenuIcon>
                    )}

                    {!isPopup && !isUserWidget && (
                        <Tooltip title="Expand">
                            <IconButton
                                sx={buttonStyle}
                                onClick={() => {
                                    closePopout({ Id: perspectiveId })
                                    logEvent('START_SLIDESHOW_FROM_WIDGET')
                                    startSlideshow(config.Id)
                                }}
                            >
                                <ExpandWidget sx={iconStyle} />
                            </IconButton>
                        </Tooltip>
                    )}
                    <WidgetFilters
                        buttonStyle={buttonStyle}
                        filterConfigs={config.DynamicFiltersConfig}
                        iconProps={{ sx: { ...iconStyle } }}
                        widgetId={config.Id}
                    />
                </>
            )}

            {openSummaryDialog && (
                <WidgetSummaryDialog open={openSummaryDialog} onClose={handleSummaryDialogClose} config={config} />
            )}

            <Slideshow
                configs={perspectiveWidgets}
                open={slideShowIndex != null}
                onClose={() => setSlideshowIndex(null)}
                startIndex={slideShowIndex}
            />
            {showSettings && (
                <Popover
                    open={Boolean(settingsAnchorEl)}
                    anchorEl={settingsAnchorEl}
                    onClose={() => setSettingsAnchorEl(null)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    {SettingsComponent}
                </Popover>
            )}
        </div>
    )
}

export default WidgetToolbar
