import { AddRounded } from '@mui/icons-material'
import { Box, ClickAwayListener, FormLabel, IconButton, Paper, Popper, styled } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { DropZone, FILE_TYPES } from 'genesis-suite/components'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useSelector } from 'react-redux'

import { SemanticType } from 'genesis-suite/types/architectureTypes'
import { formsService } from '../../../../lib/services'
import { applicationSelectors } from '../../../../selectors'
import AttachmentButton from '../../../widgets2/form/AttachmentButton'

const useStyles = makeStyles(({ spacing, palette }) => ({
    DZPaper: {
        zIndex: 10,
        padding: spacing(1),
        '& > div': { padding: spacing(1.5) },
    },
    icon: {
        color: palette.text.primary,
    },
}))
const CustomPopper = styled(Popper)(() => ({
    zIndex: 2,
}))

interface FormAttachmentFieldProps {
    required: boolean
    editable?: boolean
    propertyId: string
    attachments: string[]
    dataType: SemanticType
    onChange: (attachmentIds: string) => void
    title?: string
}

export function FormAttachmentField({
    propertyId,
    dataType,
    attachments,
    onChange,
    title,
    required,
    editable = false,
}: FormAttachmentFieldProps) {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState(null)
    const [open, setOpen] = useState(false)

    const appName = useSelector(applicationSelectors.getCurrentAppName)

    const { enqueueSnackbar } = useSnackbar()
    const errSnack = message => enqueueSnackbar(message, { variant: 'error' })

    const { fileTypes, stringLength } = dataType

    const handleChange = (id: string) => onChange([...attachments, id].join(','))
    const handleRemove = (id: string) => onChange(attachments.filter(aId => aId !== id).join(',') || null)

    const toggleDZ = e => {
        setAnchorEl(e.currentTarget)
        setOpen(previousOpen => !previousOpen)
    }
    const onDrop = async fileArr => {
        const fileData = new FormData()
        fileArr.forEach(file => fileData.append('file', file))
        const fileNames = fileArr.map(f => f.name.substring(0, f.name.lastIndexOf('.')))
        try {
            const id = await formsService.saveAttachment(fileData, JSON.stringify(fileNames), appName, propertyId)
            handleChange(id)
            setAnchorEl(null)
        } catch (err) {
            console.error(err)
            errSnack(err.Message || err.message)
        }
    }

    const acceptedTypes = fileTypes
        .reduce((acc, type) => {
            const mimeType = FILE_TYPES[type.toUpperCase()]
            if (mimeType) acc.push(mimeType)
            return acc
        }, [])
        .join(',')

    const canAdd = editable && attachments.length < stringLength
    const handleClickAway = () => {
        setOpen(false)
    }
    const isPopperOpen = Boolean(anchorEl)
    return (
        <>
            {title && <FormLabel required={required}>{title}</FormLabel>}
            <ClickAwayListener onClickAway={handleClickAway}>
                <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', gap: 1 }}>
                    <AttachmentButton attachments={attachments} onRemove={editable ? handleRemove : null} />
                    {canAdd && (
                        <IconButton className={classes.icon} onClick={toggleDZ} size="large">
                            <AddRounded />
                        </IconButton>
                    )}
                    {isPopperOpen ? (
                        <CustomPopper
                            open={open}
                            anchorEl={anchorEl}
                            disablePortal={false}
                            className="ag-custom-component-popup"
                            sx={{ zIndex: 2000 }}
                        >
                            <Paper
                                elevation={4}
                                sx={{
                                    marginLeft: 0.5,
                                    zIndex: 10,
                                    padding: 1,
                                    '& > div': { padding: 1.5 },
                                }}
                            >
                                <DropZone
                                    onDrop={onDrop}
                                    accept={acceptedTypes}
                                    message="Drop a file or click to choose one. (Max 10 MB)"
                                />
                            </Paper>
                        </CustomPopper>
                    ) : null}
                </Box>
            </ClickAwayListener>
        </>
    )
}
