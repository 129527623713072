import { Autocomplete, TextField } from '@mui/material'
import currencyToSymbolMap from 'currency-symbol-map/map'

export default function CurrencySymbolEditor({ value, onChange, label }) {
    const handleChange = (event, newValue) => {
        if (newValue) {
            onChange(newValue.value) // Pass the currency symbol to the parent
        } else {
            onChange('') // Handle clearing the selection
        }
    }

    const options = Object.keys(currencyToSymbolMap).map(currency => ({
        label: `${currencyToSymbolMap[currency]} - ${currency}`,
        value: currencyToSymbolMap[currency],
    }))

    // Find the matching option for the current value
    const currentOption = options.find(option => option.value === value) || null

    return (
        <Autocomplete
            options={options}
            value={currentOption}
            onChange={handleChange}
            isOptionEqualToValue={(option, value) => option.value === value?.value}
            getOptionLabel={option => option.label || ''}
            renderInput={params => <TextField {...params} variant="outlined" label={label} />}
            sx={{ my: 1 }}
        />
    )
}
