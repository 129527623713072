import { messengerType } from '../types'
import { applicationSelectors, authSelectors } from '~/selectors'
import { messageService, userService } from '~/lib/services'
import { ChatMessagesRequest, ChatsRequest, UsersRequest } from 'genesis-suite/types/visualTypes'
import { messengerSelectors } from '~/selectors/messenger.selectors'
import { MessageConversationType, MessageLoadDirection, MessageParticipantType } from '~/types/messageTypes'

interface UserList {
    id: string
    username: string
}

interface UserChat {
    ownerId: string
    id: string
    type: MessageConversationType
    lastUnreadMessage: any
    date: string
}

const MAX_OPEN_CHATS = 3
const DEFAULT_PAGE_SIZE = 10
let cancelTokenSource = null
const DEFAULT_CHAT_SIZE = 50

const setUserList = (userList: Array<UserList>) => {
    return { type: messengerType.UPDATE_USER_LIST, payload: userList }
}

const setUserMessages = (userChatList: Array<UserList>) => {
    return { type: messengerType.UPDATE_MESSAGES_LIST, payload: userChatList }
}
const setArchivedChatList = (userChatList: Array<UserList>) => {
    return { type: messengerType.UPDATE_ARCHIVE_MESSAGES_LIST, payload: userChatList }
}
const setUpdatedChatList = (userChatList: Array<UserList>) => {
    return { type: messengerType.UPDATED_CHAT_LIST, payload: userChatList }
}

const openUserChat = (conversation: any) => {
    return (dispatch, getState) => {
        const allUserChats = messengerSelectors.getAllUserChats(getState())
        const chatList = messengerSelectors.getMessageList(getState())

        if (!conversation || allUserChats?.some(chat => chat?.conversationData?.id === conversation?.id)) return

        const updatedChats =
            allUserChats.length >= MAX_OPEN_CHATS
                ? allUserChats.slice(1) // Remove the oldest chat
                : allUserChats

        dispatch({
            type: messengerType.UPDATE_USER_CHAT_LIST,
            payload: [
                ...updatedChats,
                {
                    conversationData: conversation,
                    chatMessages: [],
                },
            ],
        })
        if (!conversation.chatData.isArchived) {
            let existingChat = chatList.find(chat => chat.id === conversation.id)
            if (!existingChat) {
                existingChat = conversation?.chatData
            }
            let newChatList = chatList.filter(chat => !(chat.id === conversation?.id))

            const chatMessage = {
                ...existingChat,
                id: conversation?.id,
                participants: conversation.participants,
                type: conversation.type,
                latestMessage: { ...existingChat?.latestMessage, isUnread: false },
                unreadMessagesCount: 0,
            }

            newChatList = [chatMessage, ...newChatList]
            dispatch(messengerCreators.setUserMessages(newChatList))
        }
    }
}

const updateConversationParticipants = (conversationRes: any) => {
    return (dispatch, getState) => {
        const allUserChats = messengerSelectors.getAllUserChats(getState())
        const chatList = messengerSelectors.getMessageList(getState())
        const existingChat = chatList.find(chat => chat.id === conversationRes.id)

        if (!conversationRes && existingChat) return
        if (allUserChats?.some(chat => chat?.conversationData?.id === conversationRes?.id)) {
            const updatedUserChats = allUserChats?.map(chat => {
                if (chat?.conversationData?.id === conversationRes?.id) {
                    return {
                        ...chat,
                        conversationData: {
                            ...chat.conversationData,
                            participants: conversationRes.participants,
                        },
                    }
                }
                return chat
            })
            dispatch({
                type: messengerType.UPDATE_USER_CHAT_LIST,
                payload: updatedUserChats,
            })
        }

        const chatMessage = {
            ...existingChat,
            participants: conversationRes.participants,
        }

        let newChatList = chatList.filter(chat => !(chat.id === conversationRes.id))
        newChatList = [chatMessage, ...newChatList]
        dispatch(messengerCreators.setUserMessages(newChatList))
    }
}

const updateUserChatsList = (chatMessages: any, conversationId: string) => {
    return (dispatch, getState) => {
        if (!conversationId) return

        const allUserChats = messengerSelectors.getAllUserChats(getState())
        const updatedChats = allUserChats.map(chat => {
            if (chat?.conversationData?.id === conversationId) {
                return {
                    ...chat,
                    chatMessages: chatMessages,
                }
            }
            return chat
        })

        dispatch({
            type: messengerType.UPDATE_USER_CHAT_LIST,
            payload: updatedChats,
        })
    }
}

const pushMessageToUserChat = (message: any, conversationId: string) => {
    return (dispatch, getState) => {
        if (!message) return

        const allUserChats = messengerSelectors.getAllUserChats(getState())
        const updatedChats = allUserChats.map(chat => {
            if (chat?.conversationData?.id === conversationId) {
                return {
                    ...chat,
                    chatMessages: [...chat.chatMessages, message],
                }
            }
            return chat
        })

        dispatch({
            type: messengerType.UPDATE_USER_CHAT_LIST,
            payload: updatedChats,
        })
    }
}

const updateUserChatLastRead = (lastRead: any, conversationId: string) => {
    return (dispatch, getState) => {
        if (!lastRead) return

        const allUserChats = messengerSelectors.getAllUserChats(getState())
        const updatedChats = allUserChats.map(chat => {
            if (chat?.conversationData?.id === conversationId) {
                return {
                    ...chat,
                    conversationData: {
                        ...chat.conversationData,
                        chatData: {
                            ...chat.conversationData.chatData,
                            lastReadMessage: lastRead,
                        },
                    },
                }
            }
            return chat
        })

        dispatch({
            type: messengerType.UPDATE_USER_CHAT_LIST,
            payload: updatedChats,
        })
    }
}

const closeUserChat = (conversationId: any) => {
    return (dispatch, getState) => {
        if (!conversationId) return

        const allUserChats = messengerSelectors.getAllUserChats(getState())
        dispatch({
            type: messengerType.UPDATE_USER_CHAT_LIST,
            payload: allUserChats?.filter(chat => chat?.conversationData?.id !== conversationId),
        })
    }
}

const openChatById = (conversationId: any) => {
    return (dispatch, getState) => {
        if (!conversationId) return

        const state = getState()
        const userId = authSelectors.getUserId(state)
        const userList = messengerSelectors.getUserList(state)
        const conversationList = messengerSelectors.getMessageList(state)

        const chat = conversationList.find(conversation => conversation.id === conversationId)
        let user = null
        const sender = chat.latestMessage?.senderId ? chat.latestMessage?.senderId : chat.latestMessage?.sender
        if (chat.type === MessageConversationType.DIRECTMESSAGE) {
            const targetUser = chat?.participants.find(user => user.id !== userId)
            user = userList.find(user => user.UserId === targetUser?.id)
        } else {
            user = userList.find(user => user.UserId === sender)
        }

        const conversationObj = {
            id: chat.id,
            participants: chat.participants,
            type: chat.type,
            user: { id: user?.UserId, ScreenAlias: user?.ScreenAlias },
            chatData: chat,
        }

        dispatch(messengerCreators.openUserChat(conversationObj))
    }
}

const openDirectChat = (userId: any, userData: any) => {
    return (dispatch, getState) => {
        const state = getState()
        const conversationList = messengerSelectors.getMessageList(state)
        const appDomainId = applicationSelectors.getAppDomainId(state, applicationSelectors.getCurrentAppName(state))

        if (!userData?.DomainId) return

        const isChatExist = conversationList.find(
            conversation =>
                conversation.type === MessageConversationType.DIRECTMESSAGE &&
                conversation.participants.some(
                    participant => participant.id === userId && participant.type === MessageParticipantType.USER
                )
        )

        if (!isChatExist) {
            const conversationRequest = {
                domainId: appDomainId,
                ownerId: userId,
                type: MessageConversationType.DIRECTMESSAGE,
                participants: [
                    {
                        id: userData.UserId,
                        type: MessageParticipantType.USER,
                    },
                ],
            }

            messageService
                .createNewConversation(conversationRequest)
                .then(response => {
                    const conversationObj = {
                        id: response.id,
                        participants: response.participants,
                        type: MessageConversationType.DIRECTMESSAGE,
                        user: { id: userData.UserId, ScreenAlias: userData.ScreenAlias },
                        chatData: response,
                    }

                    dispatch(messengerCreators.openUserChat(conversationObj))
                })
                .catch(error => console.error('Error during conversation Creation:', error))
        } else {
            const conversationObj = {
                id: isChatExist.id,
                participants: isChatExist.participants,
                type: MessageConversationType.DIRECTMESSAGE,
                user: { id: userData.UserId, ScreenAlias: userData.ScreenAlias },
                chatData: isChatExist,
            }

            dispatch(messengerCreators.openUserChat(conversationObj))
        }
    }
}

const pushMessagesToUserChat = (messages: any, conversationId: string) => {
    return (dispatch, getState) => {
        if (!messages.length) return

        const allUserChats = messengerSelectors.getAllUserChats(getState())
        const updatedChats = allUserChats.map(chat => {
            if (chat?.conversationData?.id === conversationId) {
                return {
                    ...chat,
                    chatMessages: [...chat.chatMessages, ...messages],
                }
            }
            return chat
        })

        dispatch({
            type: messengerType.UPDATE_USER_CHAT_LIST,
            payload: updatedChats,
        })
    }
}

const addChatNotification = messageDict => {
    return async (dispatch, getState) => {
        if (cancelTokenSource) cancelTokenSource?.()

        const state = getState()
        const appDomainId = applicationSelectors.getAppDomainId(state, applicationSelectors.getCurrentAppName(state))

        const conversationList = messengerSelectors.getMessageList(state)
        const allOpenChats = messengerSelectors.getAllUserChats(state)

        const existingChat = conversationList.find(chat => chat.id === messageDict.conversationId)
        const isChatOpen = allOpenChats.find(chat => chat?.conversationData?.id === messageDict.conversationId)

        if (existingChat && isChatOpen) {
            const chatReq: ChatMessagesRequest = {
                loadCount: DEFAULT_PAGE_SIZE,
                domainId: appDomainId,
                conversationId: existingChat?.id,
                direction: MessageLoadDirection.DOWN,
                ...(isChatOpen?.chatMessages.length > 0 ? { messageId: isChatOpen?.chatMessages.at(-1)?.id } : {}),
            }

            messageService
                .getChatMessages(chatReq, cancel => {
                    cancelTokenSource = cancel
                })
                .then(res => {
                    const unreadMessages = res.map(item => ({ ...item, unRead: true }))
                    dispatch(messengerCreators.pushMessagesToUserChat(unreadMessages, existingChat?.id))
                    dispatch(messengerCreators.addCountToMessageBox(unreadMessages.at(-1), unreadMessages.length))
                })
        } else {
            dispatch(messengerCreators.getUserConversations(false))
        }
    }
}

const addCountToMessageBox = (message: any, addCount = 1) => {
    return async (dispatch, getState) => {
        const state = getState()
        const chatList = messengerSelectors.getMessageList(state)
        const existingChat = chatList.find(chat => chat.id === message.conversationId)

        const chatMessage = {
            ...existingChat,
            id: message.conversationId,
            participants: existingChat.participants,
            type: existingChat.type,
            latestMessage: { ...message, isUnread: true },
            unreadMessagesCount: (existingChat?.unreadMessagesCount || 0) + addCount,
        }

        let newChatList = chatList.filter(chat => !(chat.id === message.conversationId))
        newChatList = [chatMessage, ...newChatList]
        dispatch(messengerCreators.setUserMessages(newChatList))
    }
}

const toggleLoader = () => ({ type: messengerType.TOGGLE_LOADER })

const setUserChat = (userList: Array<UserList>, conversationList: Array<UserChat>) => {
    return {
        type: messengerType.UPDATE_USER_MESSAGES,
        payload: {
            messageList: conversationList,
            userList: userList,
        },
    }
}

const updatePagination = (hasMore: boolean, currentPage: number) => {
    return {
        type: messengerType.UPDATE_PAGINATION,
        payload: {
            hasMore: hasMore,
            currentPage: currentPage,
        },
    }
}
const updateArchivedPagination = (hasMoreArchived: boolean, archivedCurrentPage: number) => {
    return {
        type: messengerType.UPDATE_ARCHIVED_PAGINATION,
        payload: {
            hasMoreArchived: hasMoreArchived,
            archivedCurrentPage: archivedCurrentPage,
        },
    }
}

const getUserConversations = (runToggle = true) => {
    return (dispatch, getState) => {
        const state = getState()
        const user = authSelectors.getUser(state)
        const appName = applicationSelectors.getCurrentAppName(state)
        const appDomainId = applicationSelectors.getAuthorizedAppInfo(state, appName)?.DomainId

        const { domainId, userId } = user

        if (!(domainId && appName && appDomainId)) return

        if (runToggle) dispatch(toggleLoader())

        return messageService
            .getChats({
                domainId: appDomainId,
                participantId: userId,
                page: 1,
                pageSize: DEFAULT_CHAT_SIZE,
            })
            .then(userChats => {
                const newHasMore = !(userChats.length < DEFAULT_CHAT_SIZE)
                dispatch(setUserMessages(userChats))
                dispatch(updatePagination(newHasMore, 1))
            })
            .catch(() => {
                dispatch(setUserMessages([]))
                dispatch(updatePagination(false, 1))
            })
            .finally(() => {
                if (runToggle) dispatch(toggleLoader())
            })
    }
}

const getConversations = () => {
    return (dispatch, getState) => {
        const state = getState()
        const user = authSelectors.getUser(state)
        const appName = applicationSelectors.getCurrentAppName(state)
        const appDomainId = applicationSelectors.getAuthorizedAppInfo(state, appName)?.DomainId
        const currentPage = messengerSelectors.getPagination(state)?.currentPage
        const { domainId, userId } = user

        if (!(domainId && appName && appDomainId)) return

        return messageService
            .getChats({
                domainId: appDomainId,
                participantId: userId,
                page: currentPage + 1,
                pageSize: DEFAULT_CHAT_SIZE,
            })
            .then(conversationList => {
                const newHasMore = !(conversationList.length < DEFAULT_CHAT_SIZE)
                dispatch(setUpdatedChatList(conversationList))
                dispatch(updatePagination(newHasMore, currentPage + 1))
            })
            .catch(() => {
                dispatch(setUserMessages([]))
                dispatch(updatePagination(false, 1))
            })
    }
}

const getArchivedConversations = (isInitial = false) => {
    return (dispatch, getState) => {
        const state = getState()
        const user = authSelectors.getUser(state)
        const appName = applicationSelectors.getCurrentAppName(state)
        const appDomainId = applicationSelectors.getAuthorizedAppInfo(state, appName)?.DomainId
        const archivedCurrentPage = messengerSelectors.getArchivedPagination(state)?.archivedCurrentPage || 1
        const archiveList = messengerSelectors.getArchiveList(state)
        const { domainId, userId } = user

        if (!(domainId && appName && appDomainId)) return

        return messageService
            .getChats({
                domainId: appDomainId,
                participantId: userId,
                page: isInitial ? archivedCurrentPage + 1 : 1,
                pageSize: DEFAULT_CHAT_SIZE,
                showArchived: true,
            })
            .then(conversationList => {
                const newHasMore = !(conversationList.length < DEFAULT_CHAT_SIZE)

                const updatedArchiveList = isInitial ? [...archiveList, ...conversationList] : [...conversationList]

                dispatch(setArchivedChatList(updatedArchiveList))
                dispatch(updateArchivedPagination(newHasMore, isInitial ? archivedCurrentPage + 1 : 1))
            })
            .catch(() => {
                dispatch(setArchivedChatList([]))
                dispatch(updateArchivedPagination(false, 1))
            })
    }
}

const getUserMessages = () => {
    return (dispatch, getState) => {
        const state = getState()
        const user = authSelectors.getUser(state)
        const appName = applicationSelectors.getCurrentAppName(state)
        const appDomainId = applicationSelectors.getAuthorizedAppInfo(state, appName)?.DomainId
        const { domainId, userId } = user

        if (!(domainId && appName && appDomainId)) return
        dispatch({
            type: messengerType.UPDATE_USER_CHAT_LIST,
            payload: [],
        })
        const allUserList = new Promise<Array<UserList>>(resolve => {
            const usersFetchRequest: UsersRequest = {
                ModelName: appName,
                PageSize: 50,
                PageNumber: 1,
                loaddetails: true,
                superUsersOnly: false,
            }

            userService
                .getUsersV2(usersFetchRequest)
                .then(res => {
                    resolve(res.Users?.map(user => user.UserInfo))
                })
                .catch(() => resolve([]))
        })

        const conversationList = new Promise<Array<UserChat>>(resolve => {
            const conversationReq: ChatsRequest = {
                page: 1,
                pageSize: DEFAULT_CHAT_SIZE,
                domainId: appDomainId,
                participantId: userId,
            }
            messageService
                .getChats(conversationReq)
                .then(res => {
                    resolve(res)
                })
                .catch(() => resolve([]))
        })

        dispatch(toggleLoader())
        return Promise.all([allUserList, conversationList])
            .then(([userList, conversations]) => {
                const newHasMore = !(conversations.length < DEFAULT_CHAT_SIZE)
                dispatch(setUserChat(userList, conversations))
                dispatch(updatePagination(newHasMore, 1))
            })
            .catch(error => {
                dispatch(setUserChat([], []))
                dispatch(updatePagination(false, 1))
            })
            .finally(() => {
                dispatch(toggleLoader())
            })
    }
}

const updateUserConversation = (conversationId: string, messageId: string) => {
    return (dispatch, getState) => {
        const state = getState()
        const appName = applicationSelectors.getCurrentAppName(state)
        const appDomainId = applicationSelectors.getAuthorizedAppInfo(state, appName)?.DomainId
        const userChat = messengerSelectors.getChatByConversationId(state, conversationId)

        if (!(appName && appDomainId)) return

        return messageService
            .updateConversation({
                domainId: appDomainId,
                conversationId,
                messageId,
            })
            .then(() => {
                const lastReadMessage = userChat.find(item => {
                    item.unRead = false
                    return item.id === messageId
                })
                dispatch(messengerCreators.updateUserChatsList(userChat, conversationId))
                dispatch(messengerCreators.updateUserChatLastRead(lastReadMessage, conversationId))
                dispatch(messengerCreators.getUserConversations(false))
            })
    }
}

export const messengerCreators = {
    getArchivedConversations,
    getConversations,
    updatePagination,
    toggleLoader,
    setUserList,
    openUserChat,
    closeUserChat,
    setUserMessages,
    getUserMessages,
    addCountToMessageBox,
    updateUserChatsList,
    pushMessageToUserChat,
    updateConversationParticipants,
    getUserConversations,
    updateUserConversation,
    updateUserChatLastRead,
    pushMessagesToUserChat,
    addChatNotification,
    openDirectChat,
    openChatById,
}
