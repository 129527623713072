import React, { useContext } from 'react'
import { Autocomplete, Box, Checkbox, FormControl, MenuItem, Select, TextField } from '@mui/material'
import { ConfigContext } from '../ConfigContext'
import FieldLabel from './FieldLabel'
import { useSelector } from 'react-redux'
import { widgetSelectors } from '~/selectors'
import { DebouncedTextField } from 'genesis-suite/components'
import { isEmpty } from 'lodash'
import CategorySelector from '../selectors/CategorySelector'
import { FieldPointer } from '../builderTypes'
import SeriesSelector from '../selectors/SeriesSelector'

export default function PivotColumnEditor() {
    const { configStructure, dispatch, config, configKey } = useContext(ConfigContext)
    const pivotFields = configStructure?.PivotFieldConfig
    const perspectives = useSelector(widgetSelectors.getAllPerspectives)

    if (!pivotFields) return null

    const activeFilter = config?.[configKey]?.PivotFieldConfig

    const updateActiveSeries = (key, value) => {
        const to: FieldPointer = { type: 'series' }
        const from: FieldPointer = { type: 'pivot' }
        dispatch({
            type: 'UPDATE_SELECTED_PROPERTY',
            payload: { to, from, isPivot: true, pivotFieldConfig: { [key]: value } },
        })
    }

    const sortedDynamicConfig = [...pivotFields].sort(
        (a, b) => (a.DisplayOrder ?? Infinity) - (b.DisplayOrder ?? Infinity)
    )
    return (
        <>
            {sortedDynamicConfig.map((field, index) => {
                if (field.IsComplexType && field.IsList) {
                    return (
                        <div key={index}>
                            <FieldLabel field={field} />
                            <SeriesSelector key={field.Name} isPivot={true} />
                        </div>
                    )
                } else if (field.IsSourceProperty) {
                    return (
                        <div key={index}>
                            <FieldLabel field={field} />
                            <CategorySelector />
                        </div>
                    )
                } else if (field.Type.includes('Boolean')) {
                    return (
                        <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                            <div
                                key={index}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Checkbox
                                    onChange={e => updateActiveSeries(field.Name, e.target.checked)}
                                    name={field.Name}
                                    color="primary"
                                    defaultChecked={false}
                                    checked={activeFilter?.[field.Name] ?? false}
                                    inputProps={{ 'aria-label': `switch-${index}` }}
                                />
                                <FieldLabel field={field} />
                            </div>
                        </div>
                    )
                } else if (field.Type.includes('String') && field.PropertyGroupName === 'Display Settings') {
                    return (
                        <Box py={1}>
                            <DebouncedTextField
                                variant="outlined"
                                label={field.DisplayName}
                                fullWidth
                                value={activeFilter?.[field.Name] ?? ''}
                                onChange={title => updateActiveSeries(field.Name, title)}
                            />
                        </Box>
                    )
                } else if (field.IsPerspectiveProperty) {
                    return (
                        <div key={index}>
                            <FieldLabel field={field} />
                            <Autocomplete
                                fullWidth
                                options={perspectives}
                                isOptionEqualToValue={(option, value) => option?.Id == value?.Id}
                                getOptionLabel={option => option.Title}
                                value={perspectives.find(p => p.Id === activeFilter?.[field.Name]) ?? null}
                                onChange={(_, newValue) => {
                                    updateActiveSeries(field.Name, newValue?.Id)
                                }}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        size="small"
                                        placeholder="Select a perspective"
                                        margin="normal"
                                        sx={{ my: 0 }}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: <>{params.InputProps.endAdornment}</>,
                                        }}
                                    />
                                )}
                            />
                        </div>
                    )
                } else if (!isEmpty(field.List)) {
                    return (
                        <div key={index}>
                            <FieldLabel field={field} />
                            <FormControl fullWidth sx={{ my: 0 }} variant="outlined" size="small" margin="normal">
                                <Select
                                    value={activeFilter?.[field.Name] ?? 'None'}
                                    onChange={e => updateActiveSeries(field.Name, e.target.value)}
                                >
                                    {Object.entries(field.List as { [key: string]: string }).map(([key, value]) => (
                                        <MenuItem key={key} value={key}>
                                            {value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    )
                }

                return null
            })}
        </>
    )
}
