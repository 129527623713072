import { ArrowDropDown } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Box, List, ListItemButton, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { BusinessElement, _360 } from 'genesis-suite/icons'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ModelLastUpdated from '~/components/ModelLastUpdated'
import ThemeModeButton from '~/components/settings/ManageTheme/ThemeModeButton'
import { ThemeMode } from '~/types/ThemeTypes'
import { navigationCreators, themeCreators } from '../actions/creators'
import { routePaths } from '../lib/routes'
import { openInNewTab } from '../lib/utils'
import { authSelectors, menuSelectors, moduleSelectors, themeSelectors } from '../selectors'

const useStyles = makeStyles(({ palette, spacing }) => ({
    container: {
        color: '#000000',
        maxHeight: '450px',
        minWidth: '250px',
        display: 'flex',
        flexDirection: 'column',
        padding: spacing(1, 1, 0),
    },
    content: { display: 'flex', flexDirection: 'column', flex: 1 },
    nameWrapper: { display: 'flex', alignItems: 'center' },
    avatar: { backgroundColor: palette.primary.main, color: palette.primary.contrastText },
    name: { marginLeft: spacing(), lineHeight: 1.4, color: palette.text.primary },
    label: { color: palette.text.primary, margin: spacing(2, 0, 1) },
    itemText: { color: palette.text.primary },
    signout: { color: palette.text.primary, margin: spacing(1, 0) },
}))

export default function MenuView({ onClose = () => {} }) {
    const appOptions = JSON.parse(useSelector(moduleSelectors.getAppOptions) || null)
    const getHiddenBizElements = appOptions?.hideBusinessElements ?? []
    const getHidden360Views = appOptions?.hide360Views ?? []
    const modules = useSelector(moduleSelectors.getActiveModules)
    const moduleTitle = useSelector(moduleSelectors.getModuleTitle)

    const _360views = useSelector(menuSelectors.getViews)
    const updated360Views = _360views?.filter(el => !getHidden360Views.includes(el.text))
    const bizElements = useSelector(menuSelectors.getTopNav)
    const updatedBizElements = bizElements?.filter(el => !getHiddenBizElements.includes(el.text))
    const shortcuts = useSelector(authSelectors.getShortcuts)
    const themeMode = useSelector(themeSelectors.getThemeMode)

    const dispatch = useDispatch()

    const [expanded, setExpanded] = useState(null)

    const classes = useStyles()

    const orderedModules = modules && [...modules].sort((a, b) => a.title.localeCompare(b.title))

    const handleChange = panel => (e, isExpanded) => {
        setExpanded(isExpanded ? panel : null)
    }
    useEffect(() => {
        if (updatedBizElements?.length > 0) setExpanded('businessElements')
        else if (updated360Views?.length > 0) setExpanded('views')
        else if (modules?.length > 0) setExpanded('module')
        else if (shortcuts?.length > 0) setExpanded('shortcuts')
    }, [])

    const handleThemeClick = () => {
        const mode = themeMode === ThemeMode.LIGHT ? ThemeMode.DARK : ThemeMode.LIGHT
        dispatch(themeCreators.setThemeMode(mode))
    }

    const handleNavigation = (to, isExternal, type) => {
        if (isExternal && to) openInNewTab(to)
        else if (type === 'viewId') dispatch(navigationCreators.goToPerspective(to))
        else if (type === 'widgetId') dispatch(navigationCreators.goTo(routePaths.WIDGET, to))
        else if (type === 'elementName') dispatch(navigationCreators.goTo(routePaths.ELEMENT, to))

        return onClose()
    }

    return (
        <div className={classes.container}>
            <div className={classes.content}>
                {updatedBizElements && updatedBizElements.length > 0 && (
                    <div className={classes.label}>
                        <Panel
                            expanded={expanded === 'businessElements'}
                            title={'Business Elements'}
                            onChange={handleChange('businessElements')}
                            icon={<BusinessElement fontSize="small" />}
                        >
                            {updatedBizElements.map(el => {
                                return {
                                    text: el.text,
                                    onClick: () => handleNavigation(el.to, el.isExternal, el.type),
                                }
                            })}
                        </Panel>
                    </div>
                )}

                {updated360Views && updated360Views.length && (
                    <div className={classes.label}>
                        <Panel
                            expanded={expanded === 'views'}
                            title={'Views'}
                            onChange={handleChange('views')}
                            icon={<_360 fontSize="small" />}
                        >
                            {updated360Views.map(views => {
                                return {
                                    text: views.text,
                                    onClick: () => handleNavigation(views.to, views.isExternal, views.type),
                                    subMenu:
                                        views.subMenuConfig &&
                                        views.subMenuConfig.map(el => ({
                                            text: el.text,
                                            onClick: () => handleNavigation(el.to, el.isExternal, el.type),
                                        })),
                                }
                            })}
                        </Panel>
                    </div>
                )}

                {shortcuts && shortcuts.length > 0 && (
                    <Panel expanded={expanded === 'shortcuts'} title={'Shortcuts'} onChange={handleChange('shortcuts')}>
                        {shortcuts.map(el => {
                            return {
                                text: el.text,
                                onClick: () => handleNavigation(el.to, el.isExternal, el.type),
                            }
                        })}
                    </Panel>
                )}

                <Box className={classes.label}>
                    <Typography sx={{ marginBottom: '8px' }}>Applications</Typography>
                    <Panel
                        expanded={expanded === 'module'}
                        title={moduleTitle}
                        onChange={handleChange('module')}
                        style={{ paddingLeft: '16px' }}
                    >
                        {orderedModules &&
                            orderedModules
                                .filter(({ title }) => title !== moduleTitle)
                                .map(({ name, title }) => ({
                                    text: title,
                                    onClick: () => {
                                        dispatch(navigationCreators.goToModule(name))
                                        onClose()
                                    },
                                }))}
                    </Panel>
                </Box>
                <Box sx={({ palette }) => ({ display: 'flex', color: palette.text.primary, margin: '5px 0 8px' })}>
                    <ThemeModeButton buttonStyle={{ padding: '0px', marginRight: '8px' }} />
                    <Typography onClick={handleThemeClick}>{`Switch to ${
                        themeMode === 'dark' ? 'Light' : 'Dark'
                    } Mode`}</Typography>
                </Box>
                <ModelLastUpdated sx={{ mb: 1, ml: 0, mt: 2 }} />
                <Typography sx={{ color: 'text.disabled', flexShrink: 0, marginBottom: '8px' }}>
                    Powered by Digital Duplicate &trade;
                </Typography>
            </div>
        </div>
    )
}

const usePanelStyles = makeStyles(({ spacing, palette }) => ({
    panel: {
        boxShadow: 'inherit',
        '&:before': { height: 0 },
        border: `1px solid ${palette.divider}`,
        borderRadius: '10px !important',
        '&.Mui-expanded': {
            margin: 0,
        },
    },
    summary: {
        padding: spacing(0, 1),
        margin: 0,
        '&.MuiAccordionSummary-root': {
            minHeight: '36px',
        },
        '&.MuiAccordionSummary-root.Mui-expanded .MuiAccordionSummary-content': {
            margin: 0,
        },
    },
    summaryContent: { fontWeight: 'bold', margin: 0, paddingLeft: spacing(1) },
    details: { padding: 0 },
    list: { width: '100%', padding: spacing(0, 1.5) },
    menuItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: spacing(0.5, 2),
        border: `1px solid ${palette.border.main}`,
        borderRadius: '19px',
        margin: spacing(1, 0),
        minHeight: '36px',
    },
    subMenu: {
        background: 'transparent',
        boxShadow: 'none',
        border: `1px solid ${palette.border.main}`,
        borderRadius: '19px',
        margin: spacing(1, 0),
        '&:first-of-type': {
            borderRadius: '19px',
        },
        '&:last-of-type': {
            borderRadius: '19px',
        },
        '&::before': {
            display: 'none',
        },
        '&.Mui-expanded': {
            margin: 0,
        },
    },
    subMenuSummary: {
        padding: spacing(0, 1),
        margin: 0,
        '&.MuiAccordionSummary-root': {
            minHeight: '36px',
        },
        '&.MuiAccordionSummary-root.Mui-expanded .MuiAccordionSummary-content': {
            margin: 0,
        },
    },
    subMenuItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: spacing(0.5, 2),
        border: `1px solid ${palette.border.main}`,
        borderRadius: '19px',
        margin: spacing(1, 1),
        height: '38px',
    },
}))

function Panel({ expanded, title, onChange, icon, children, style = {} }) {
    const classes = usePanelStyles({ title })

    if (!children) return null
    return (
        <Accordion expanded={expanded} onChange={onChange} classes={{ root: classes.panel }}>
            <AccordionSummary
                sx={{ flexDirection: 'row', width: '100%' }}
                classes={{ root: classes.summary, content: classes.summaryContent }}
                expandIcon={<ArrowDropDown />}
            >
                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <span style={{ display: 'flex', marginRight: '10px' }}>{icon}</span>
                    <Typography color={title ? 'inherit' : 'error'}>{title || 'Select'}</Typography>
                </div>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
                <List className={classes.list}>
                    {children.map(({ text, onClick, subMenu }) => (
                        <div key={text}>
                            {subMenu ? (
                                <Accordion classes={{ root: classes.subMenu }}>
                                    <AccordionSummary
                                        expandIcon={<ArrowDropDown />}
                                        sx={{
                                            flexDirection: 'row',
                                            width: '100%',
                                        }}
                                        classes={{ root: classes.subMenuSummary, content: classes.summaryContent }}
                                    >
                                        <Typography>{text}</Typography>
                                    </AccordionSummary>
                                    <List>
                                        {subMenu.map(nestedItem => (
                                            <ListItemButton
                                                key={nestedItem.text}
                                                className={classes.subMenuItem}
                                                onClick={nestedItem.onClick}
                                            >
                                                {nestedItem.text}
                                            </ListItemButton>
                                        ))}
                                    </List>
                                </Accordion>
                            ) : (
                                <ListItemButton className={classes.menuItem} onClick={onClick} style={{ ...style }}>
                                    {text}
                                </ListItemButton>
                            )}
                        </div>
                    ))}
                </List>
            </AccordionDetails>
        </Accordion>
    )
}
