import { SemanticTypeById } from 'genesis-suite/types/architectureTypes'
import { InsightResource, Property, Resource, ResourceType } from 'genesis-suite/types/networkTypes'
import {
    Aggregation,
    ColorGradient,
    ComparisonOperator,
    ConditionalFormat,
    CustomTooltip,
    Legend,
    Tooltip,
    TooltipType,
} from 'genesis-suite/types/visualTypes'
import { makeField } from 'genesis-suite/utils'
import { fetchResource, ResourceGeneric } from '../../hooks/useResourceMeta'

export function makeAggregation(property: Property, visorAggregation): Aggregation {
    const { hasAggregates } = property
    if (hasAggregates) return Aggregation.UNKNOWN

    const aggregation = visorAggregation?.toLowerCase()
    if (!aggregation || aggregation === Aggregation.UNKNOWN) return Aggregation.SUM
    if (aggregation === 'countu') return Aggregation.COUNTU
    if (aggregation === 'stdev') return Aggregation.STDEV

    return aggregation
}

export function convertAggregation(visorAggregation: number): Aggregation {
    switch (visorAggregation) {
        case 0:
            return Aggregation.UNKNOWN
        case 1:
            return Aggregation.SUM
        case 2:
            return Aggregation.COUNT
        case 3:
            return Aggregation.AVERAGE
        case 4:
            return Aggregation.COUNTU
        case 5:
            return Aggregation.MAX
        case 6:
            return Aggregation.MIN
        case 7:
            return Aggregation.STDEV
        case 8:
            return Aggregation.VARIANCE
        case 10:
            return Aggregation.MEDIAN
        case 11:
            return Aggregation.Q1
        case 12:
            return Aggregation.Q3
        default:
            return Aggregation.UNKNOWN
    }
}

export function makeConditionalFormats(insight, formats): ConditionalFormat[] {
    return formats?.map(f => makeConditionalFormat(insight, f))
}

export function makeConditionalFormat(insight, format): ConditionalFormat {
    const { FormatOption, FormatRule, FormatValue, TableField, TargetProperty } = format
    const { Fill, BorderColor, ForegroundColor } = FormatOption[0]

    return {
        rule: convertConditionalFormatRule(FormatRule),
        format: { backgroundColor: Fill, borderColor: BorderColor, fontColor: ForegroundColor },
        ...(FormatValue && { target: Number(FormatValue) || FormatValue }),
        ...(TableField && {
            source: { field: makeField(insight.properties, TableField), aggregation: Aggregation.SUM },
        }),
        ...(TargetProperty && {
            targetSource: { field: makeField(insight.properties, TargetProperty), aggregation: Aggregation.SUM },
        }),
    }
}

export function convertConditionalFormatRule(rule): ComparisonOperator {
    switch (rule) {
        case 'GreaterThan':
            return ComparisonOperator.GREATER_THAN
        case 'LessThan':
            return ComparisonOperator.LESS_THAN
        case 'EqualTo':
            return ComparisonOperator.EQUAL
        case 'NotEqualTo':
            return ComparisonOperator.NOT_EQUAL
        case 'LessThanorEqualTo':
            return ComparisonOperator.LESS_THAN_OR_EQUAL
        case 'GreaterThanorEqualTo':
            return ComparisonOperator.GREATER_THAN_OR_EQUAL
        case 'Contains':
            return ComparisonOperator.CONTAINS
        case 'StartsWith':
            return ComparisonOperator.STARTS_WITH
        case 'EndsWith':
            return ComparisonOperator.ENDS_WITH
    }
}

export function convertBaseFormat(baseFormat) {
    if (!baseFormat || baseFormat === 'None') return
    return baseFormat
}

export function convertTooltip(insight: InsightResource, seriesConfig): Tooltip {
    const { Tooltip, TotalRecords, ShowDefaultTooltip } = seriesConfig
    if (!Tooltip?.TooltipText) {
        if (!ShowDefaultTooltip) {
            return { type: TooltipType.DEFAULT, disabled: true }
        } else if (!TotalRecords) {
            return { type: TooltipType.DEFAULT }
        } else {
            const markdown = '{{0}}\\n&#8291;# items: totalRecords()'
            const propertyName = seriesConfig.FieldName || seriesConfig.ValueField
            const property = getPropertyFromResource(insight, propertyName)
            const aggregation = makeAggregation(property, seriesConfig.AggregationType)
            const tooltipConfig: CustomTooltip['configs'][0] = {
                source: { field: makeField(insight.properties, propertyName), aggregation },
                format: { base: convertBaseFormat(seriesConfig.BaseFormat) },
            }

            return { type: TooltipType.CUSTOM, markdown, configs: [tooltipConfig] }
        }
    }

    const { TooltipText, TooltipConfigs } = Tooltip

    let markdown = TooltipText
    markdown.replace('{', '{{')
    markdown.replace('}', '}}')
    markdown.replace('<br/>', '\\n')
    if (TotalRecords) markdown += '\\n&#8291;# items: totalRecords()'

    return {
        type: TooltipType.CUSTOM,
        markdown,
        configs: TooltipConfigs.map(c => {
            const property = insight.properties.find(p => p.name === c.TipField)
            const aggregation = makeAggregation(property, c.AggregationType)
            const config: CustomTooltip['configs'][0] = {
                source: { field: makeField(insight.properties, c.TipField), aggregation },
                format: { base: convertBaseFormat(c.BaseFormat) },
            }
            return config
        }),
    }
}

/** Fetch and store insight data by name */
export class ResourceManager {
    private appName: string
    private semanticTypeById: SemanticTypeById
    private resourceByName: { [name: string]: Resource } = {}

    constructor(appName: string, semanticTypeById: SemanticTypeById) {
        this.appName = appName
        this.semanticTypeById = semanticTypeById
    }

    getInsight(name: string) {
        return this.get(ResourceType.INSIGHT, name)
    }

    getAppName() {
        return this.appName
    }

    async get<T extends ResourceType>(type: T, name: string) {
        if (this.resourceByName[name]) return this.resourceByName[name] as ResourceGeneric<T>

        try {
            const data = await fetchResource(this.appName, type, name, this.semanticTypeById)
            this.resourceByName[name] = data
            return data
        } catch (e) {
            console.error(e)
            if (e.status === 404) throw new Error(`Insight ${name} doesn't exist`)
        }
    }

    clearCache() {
        this.resourceByName = {}
    }
}

export function convertLegend(config): Legend {
    const { ShowLegend, LegendLocation, LegendLineCount } = config
    return { enabled: ShowLegend, placement: LegendLocation.toLowerCase(), maxRows: LegendLineCount }
}
export class Timer {
    private timer
    private ms: number
    public done = false

    constructor(ms: number) {
        this.ms = ms
        this.start()
    }

    start() {
        this.timer = setTimeout(() => (this.done = true), this.ms)
    }

    reset() {
        this.done = false
        this.start()
    }

    stop() {
        clearTimeout(this.timer)
    }
}

/** Make uniform color gradient */
export function makeColorGradient(palette): ColorGradient {
    if (!palette) return
    return palette.map((color, index) => ({ position: index / (palette.length - 1), color }))
}

export function getPropertyFromResource(resource: Resource, propertyName: string) {
    const property = resource.properties.find(p => p.name === propertyName)

    if (!property) throw `Property ${propertyName} doesn't exist in resource ${resource.name}`
    return property
}

export function makeNavigation(nodeName, perspectiveId, dashboardIdByOldId) {
    return {
        enabled: Boolean(nodeName || perspectiveId),
        ...(perspectiveId && { perspectiveId: dashboardIdByOldId?.[perspectiveId] ?? perspectiveId }),
    }
}
