import React, { ReactNode, useContext } from 'react'
import { Box } from '@mui/material'

import { PropertyType } from 'genesis-suite/types/architectureTypes'
import { DataField } from 'genesis-suite/types/visualTypes'
import Droppable from '../Droppable'
import { ConfigContext } from '../ConfigContext'
import { DragField } from '../builderTypes'

interface Props {
    accept?: PropertyType[]
    /** (default 'Property') */
    label?: string
    showPlaceholder?: boolean
    children?: ReactNode | ReactNode[]
    onDrop: (field: DragField) => void
    hideSelect?: boolean
    className?: string
}

export default function PropertyController({ children, accept, label = 'Property', hideSelect, ...rest }: Props) {
    const { resources, config, configKey } = useContext(ConfigContext)
    const { properties } = resources?.byId[resources.selectedId] ?? {}

    const insightHasCategories = config[configKey]?.LabelField
        ? properties?.some(p => p.name === config[configKey]?.LabelField)
        : true

    const options: { type: PropertyType; label: string; value: DataField }[] = properties?.map(
        ({ container, id, name, displayName, semanticType }) => ({
            type: semanticType.type,
            label: displayName,
            id,
            value: {
                resourceType: container.type,
                resourceId: container.id,
                resourceName: container.name,
                id,
                name,
                dataTypeId: semanticType.id,
            },
        })
    )

    const filtered = accept ? options?.filter(o => accept.includes(o.type)) : options

    return (
        <Droppable
            label={label}
            accept={accept}
            options={filtered}
            hideSelect={hideSelect || !insightHasCategories}
            {...rest}
        >
            {children && (
                <Box maxHeight="50%" overflow="auto">
                    {children}
                </Box>
            )}
        </Droppable>
    )
}
