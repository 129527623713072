"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertClientInsightAttributeAggregation = convertClientInsightAttributeAggregation;
exports.fillProperties = fillProperties;
exports.fillProperty = fillProperty;
exports.fillResource = fillResource;
exports.findNeighbors = findNeighbors;
exports.findResourcePathsBreadthFirst = findResourcePathsBreadthFirst;
exports.findResourcePathsDepthFirst = findResourcePathsDepthFirst;
exports.getInsightPropertyAggregation = getInsightPropertyAggregation;
exports.isValidInsight = isValidInsight;
exports.makeMetaProperties = makeMetaProperties;
exports.makeNodesAndLinks = makeNodesAndLinks;
exports.makeVisibleNodesAndLinks = makeVisibleNodesAndLinks;
var _lodash = require("lodash");
var _networkTypes = require("../types/networkTypes");
var _visualTypes = require("../types/visualTypes");
/** given all resource, property and semantic dictionaries, create a full resource given the id */
function fillResource(resourceById, propertyById, semanticById, id) {
  if (!id) return;
  if (!resourceById.hasOwnProperty(id)) return;
  const resource = resourceById[id];
  const {
    propertyIds,
    ...rest
  } = resource;
  const properties = fillProperties(propertyById, semanticById, propertyIds);
  if (resource.type !== _networkTypes.ResourceType.INSIGHT) return {
    ...rest,
    properties
  };
  const insightProperties = correctInsightProperties(properties, resource, resourceById);
  return {
    ...rest,
    properties: insightProperties
  };
}

/** Make all computed properties that belong to child nodes/links not computed */
function correctInsightProperties(properties, insight, resourceById) {
  const nodeIds = insight.nodeIds ?? [];
  const linkIds = insight.linkIds ?? [];
  const nodesAndLinks = [...nodeIds, ...linkIds].map(id => resourceById[id]).filter(r => Boolean(r));
  const nodeAndLinkPropertyIds = (0, _lodash.uniq)(nodesAndLinks.reduce((acc, r) => [...acc, ...r.propertyIds], []));
  return properties.map(p => {
    const computed = p.computed && nodeAndLinkPropertyIds.includes(p.id) ? false : p.computed;
    return {
      ...p,
      computed
    };
  });
}

/** Given property and semantic type dictionary, fill propertyIds */
function fillProperties(propertyById, semanticById, propertyIds) {
  if (!propertyIds) return [];
  if (!propertyById) return [];
  const properties = [];
  propertyIds.forEach(id => {
    if (id && propertyById[id]) {
      properties.push(fillProperty(propertyById, semanticById, id));
    }
  });
  return properties.sort((a, b) => a.name.localeCompare(b.name));
}

/** Fill property */
function fillProperty(propertyById, semanticById, id) {
  const {
    semanticId,
    ...bareProperty
  } = propertyById[id];
  const semanticType = semanticById?.[semanticId];
  return {
    ...bareProperty,
    semanticType
  };
}

/** given links, selected ids and focus level, determine which other nodes and links should be visible */
function makeVisibleNodesAndLinks(links, selectedIds, focus) {
  if (!links || focus === 0 || !selectedIds) return selectedIds;
  let visibleIds = selectedIds;
  links.forEach(link => {
    const linkNodeIds = link.nodes.map(node => node.id);
    if (focus !== 'all') {
      const includesVisibleNode = selectedIds.some(nodeId => linkNodeIds.includes(nodeId));
      if (includesVisibleNode) {
        visibleIds = (0, _lodash.union)(visibleIds, [link.id]);
      }
      const includesVisibleLink = selectedIds.includes(link.id);
      if (includesVisibleLink) visibleIds = (0, _lodash.union)(visibleIds, linkNodeIds);
    } else {
      visibleIds = (0, _lodash.union)(visibleIds, [link.id], linkNodeIds);
    }
  });
  if (focus === 'all') return visibleIds;
  return makeVisibleNodesAndLinks(links, visibleIds, focus - 1);
}

/** given links and selected ids, determine if an insight is valid (no gaps in network and ends with nodes) */
function isValidInsight(links, selectedIds) {
  if (!selectedIds || !selectedIds.length) return false;
  const selectedLinks = links.filter(l => selectedIds.includes(l.id));

  // Accept single nodes
  if (!selectedLinks.length) return selectedIds.length === 1;
  let foundIds = [];
  function findIds(focusLink, previousNodeId) {
    const {
      id,
      nodes
    } = focusLink;
    foundIds.push(id);
    let moreIds = [];
    let foundNodes = 0;
    for (let n of nodes) {
      if (!selectedIds.includes(n.id) || n.id === previousNodeId) continue;
      foundNodes++;
      foundIds.push(n.id);
      const newLinksWithNode = selectedLinks.filter(l => !foundIds.includes(l.id)).filter(l => l.nodes.map(n => n.id).includes(n.id));
      for (let link of newLinksWithNode) {
        const digDeeper = findIds(link, n.id);
        if (!digDeeper) return false;
        moreIds = [...moreIds, ...digDeeper];
      }
    }

    // bad - insights must end with a node (>2 nodes around first link and >1 around all remaining)
    //  not sure why this constraint exists?
    if (previousNodeId ? foundNodes < 1 : foundNodes < 2) return false;
    return (0, _lodash.union)(foundIds, moreIds);
  }
  const ids = findIds(selectedLinks[0]);
  return ids && ids.length === selectedIds.length;
}

/** given resources generate an array of path arrays from root to hunt ids using fast breadth-first algorithm */
function findResourcePathsBreadthFirst(links, rootId, huntId) {
  let tree = {
    [rootId]: null
  };
  let visited = [];
  let paths = [];
  function addLeaves(leafId, childrenIds) {
    function searchBranch(branch) {
      if (branch?.[leafId] === null) {
        branch[leafId] = {};
        childrenIds.forEach(id => {
          branch[leafId][id] = null;
        });
      } else if (branch) {
        const keys = Object.keys(branch);
        keys.forEach(key => {
          searchBranch(branch[key]);
        });
      }
    }
    searchBranch(tree);
  }
  function getShortestPath(huntId) {
    let shortestPath;
    function searchBranch(branch, path) {
      if (branch?.[huntId] === null) {
        shortestPath = [...path, huntId];
      } else if (branch) {
        const keys = Object.keys(branch);
        keys.forEach(key => {
          searchBranch(branch[key], [...path, key]);
        });
      }
    }
    searchBranch(tree, []);
    return shortestPath;
  }
  let queue = [rootId];
  while (queue.length) {
    let focusId = queue.pop();
    visited.push(focusId);
    let neighbors = findNeighbors(links, focusId).filter(id => !visited.includes(id));
    addLeaves(focusId, neighbors);
    if (neighbors.includes(huntId)) paths.push(getShortestPath(huntId));
    for (let n of neighbors) queue.unshift(n);
  }
  return (0, _lodash.uniqWith)(paths, _lodash.isEqual);
}

/** given resources generate an array of path arrays from root to hunt ids using exhaustive depth-first algorithm */
function findResourcePathsDepthFirst(links, rootId, huntId) {
  const linksWithNodeIds = links.map(l => ({
    id: l.id,
    nodeIds: l.nodes.map(n => n.id)
  }));
  const resourceIds = getAllResourceIds(links);

  /** paths can be this much bigger than minimum path */
  const bigger = 3;
  let minPath = 999;
  const maxIterations = 400000;
  let iteration = 0;
  function growPath(path) {
    const lastId = path[path.length - 1];

    // found a path!
    if (lastId === huntId) {
      minPath = Math.min(minPath, path.length);
      return [path];
    }

    // filter out paths that have two or more resources than the established minimum
    if (path.length > minPath + bigger) return [null];
    const link = linksWithNodeIds.find(l => l.id === lastId);
    const nextIds = link ? link.nodeIds : linksWithNodeIds.filter(l => l.nodeIds.includes(lastId)).map(l => l.id);

    // make sure ids are part of filtered resources and prevent path from double-backing on itself
    const nextFilteredIds = nextIds.filter(id => resourceIds.includes(id) && !path.includes(id));
    if (iteration++ > maxIterations) return [null];
    if (nextFilteredIds.length) return (0, _lodash.flatten)(nextFilteredIds.map(id => growPath([...path, id])));else return [null];
  }
  const validPaths = growPath([rootId]).filter(Boolean);
  const paths = validPaths.filter(p => p.length < minPath + bigger + 1).sort((a, b) => {
    if (a.length < b.length) return -1;
    if (a.length > b.length) return 1;
    return 0;
  });
  if (!paths.length || iteration > maxIterations) return;

  // neighbor resources only have one path
  return paths[0].length === 2 ? [paths[0]] : paths;
}
function getAllResourceIds(links) {
  const nodeIds = links.reduce((acc, cur) => [...acc, ...cur.nodes.map(n => n.id)], []);
  return (0, _lodash.uniq)([...links.map(l => l.id), ...nodeIds]);
}

/** Given links, return array of ids that are neighbor resources to id */
function findNeighbors(links, id) {
  const mappedLinks = links.map(l => ({
    id: l.id,
    nodeIds: l.nodes.map(n => n.id)
  }));
  let link = mappedLinks.find(l => l.id === id);
  return link ? link.nodeIds : mappedLinks.filter(l => l.nodeIds.includes(id)).map(l => l.id);
}
function getInsightPropertyAggregation(attributes) {
  return attributes.reduce((acc, cur) => {
    return {
      ...acc,
      ...(cur.AggregationType && {
        [cur.AttributeId]: convertServerInsightAttributeAggregation(cur.AggregationType)
      })
    };
  }, {});
}
function convertServerInsightAttributeAggregation(aggregationType) {
  switch (aggregationType) {
    case 0:
      return _visualTypes.Aggregation.NONE;
    case 1:
      return _visualTypes.Aggregation.SUM;
    case 2:
      return _visualTypes.Aggregation.COUNT;
    case 3:
      return _visualTypes.Aggregation.AVERAGE;
    case 4:
      return _visualTypes.Aggregation.COUNTU;
    case 5:
      return _visualTypes.Aggregation.MAX;
    case 6:
      return _visualTypes.Aggregation.MIN;
  }
}
function convertClientInsightAttributeAggregation(aggregation) {
  switch (aggregation) {
    case _visualTypes.Aggregation.NONE:
      return 0;
    case _visualTypes.Aggregation.SUM:
      return 1;
    case _visualTypes.Aggregation.COUNT:
      return 2;
    case _visualTypes.Aggregation.AVERAGE:
      return 3;
    case _visualTypes.Aggregation.COUNTU:
      return 4;
    case _visualTypes.Aggregation.MAX:
      return 5;
    case _visualTypes.Aggregation.MIN:
      return 6;
  }
}
function makeMetaProperties(meta) {
  return {
    Name: meta.name,
    RemoveDuplicates: meta.removeDuplicates,
    IsPersisted: meta.isPersisted,
    Description: meta.description,
    ShowInBuilder: meta.showInBuilder
  };
}
function makeNodesAndLinks(nodeIds, linkIds, propertyIds, aggregationByPropertyId, resources) {
  const selectedNodes = resources.filter(r => nodeIds.includes(r.id));
  const selectedLinks = resources.filter(r => linkIds.includes(r.id));
  const allTrees = resources.filter(r => r.type === _networkTypes.ResourceType.TREE);
  return {
    Nodes: selectedNodes.map(r => {
      const nodeTrees = allTrees.filter(t => r.trees?.find(nt => nt.id === t.id));
      return makeResourceProps(r, propertyIds, aggregationByPropertyId, nodeTrees);
    }),
    Links: selectedLinks.map(r => makeResourceProps(r, propertyIds, aggregationByPropertyId))
  };
}
function makeResourceProps(resource, propertyIds, aggregationByPropertyId, trees) {
  const Columns = (resource.properties || []).map(p => p.id).filter(id => propertyIds.includes(id)).reduce((acc, id) => {
    const agg = convertClientInsightAttributeAggregation(aggregationByPropertyId[id] ?? _visualTypes.Aggregation.NONE);
    return {
      ...acc,
      [id]: agg
    };
  }, {});
  const Trees = trees?.filter(t => t.properties.find(p => propertyIds.includes(p.id))).map(t => {
    const Properties = propertyIds.filter(id => t.properties.find(p => p.id === id));
    return {
      Id: t.id,
      Properties
    };
  });
  return {
    Id: resource.id,
    Columns,
    ...(Trees?.length ? {
      Trees
    } : undefined)
  };
}