import { Box, Button, Divider, IconButton, Popover, Tooltip, Typography, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classnames from 'classnames'
import DefaultErrorBoundary from 'genesis-suite/components/error_boundaries/DefaultErrorBoundary'
import { useHover } from 'genesis-suite/hooks'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMatch, useNavigate } from 'react-router-dom'
import { goBack } from 'redux-first-history'
import { navigationCreators } from '../actions/creators'
import NavTitle from '../containers/NavTitle'
import { useIsMobile } from '../hooks/useIsMobile'
import { useFeature } from '../lib/featureFlags'
import { routePaths } from '../lib/routes'
import { appearanceSelectors, applicationSelectors, authSelectors, moduleSelectors } from '../selectors'
import AppMenu from './AppMenu'
import ApplicationController from './ApplicationController'
import ApplicationLogo from './ApplicationLogo'
import Banner from './Banner'
import BusinessElementButtons from './BusinessElementButtons'
import DeploymentViewController from './DeploymentViewController'
import NeoClassicGlobalFiltersWrapper from './NeoClassicGlobalFiltersWrapper'
import QuickNavButton from './QuickNavButton'
import SecondTopBar from './SecondTopBar'
import ThirdTopBar from './ThirdTopBar'
import UserProfile from './UserProfile'
import NotificationController from './notifications/NotificationController'
import MobileSearchController from './search/MobileSearchController'
import SearchController from './search/SearchController'
import { AppearanceButton } from './settings/ManageAppearance'
// import { Search } from '@mui/icons-material'
import Arrow from '@mui/icons-material/ArrowDropDown'
import CloudQueueRoundedIcon from '@mui/icons-material/CloudQueueRounded'
import PersonIcon from '@mui/icons-material/Person'
import { Dialog, DialogContent } from '@mui/material'
import { ZendeskController } from 'genesis-suite/components'
import { useHotKey } from 'genesis-suite/hooks'
import { Close, House, Search } from 'genesis-suite/icons'
import { useDebouncedCallback } from 'use-debounce'
import { appearanceCreators } from '../actions/creators'
import { logEvent } from '../lib/amplitudeClient'
import { HotKeys } from '../lib/hotkeys'
import UserProfileButton from './UserProfileButton'
import FullScreenButton from './FullScreenButton'

const useTopNavStyles = makeStyles(({ palette, custom, breakpoints }) => ({
    topNavWrapper: {
        display: 'flex',
        flexDirection: 'column',
        borderTop: 0,
        borderLeft: 0,
        borderRight: 0,
    },
    flexContainer: ({ isMobile }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: isMobile ? `${custom.topNav.mobileHeight}px` : `${custom.topNav.height}px`,
        maxHeight: isMobile ? `${custom.topNav.mobileHeight}px` : `${custom.topNav.height}px`,
        padding: '0 8px',
        backgroundColor: palette.background.topBar,
        [breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    }),
    applogo: {
        height: '1.23rem',
    },
}))

const useSearchDialogStyles = makeStyles(theme => ({
    root: {
        zIndex: '999 !important',
    },
    paper: {
        position: 'absolute',
        top: '30%',
        borderRadius: '60px',
        boxShadow: 'none',
        background: 'transparent',
    },
    dialogContentRoot: {
        padding: '0 !important',
        overflow: 'visible !important',
    },
    controls: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    popover: {
        borderRadius: '60px',
    },
    smallIcon: { fontSize: '18px', margin: '5px' },
    moduleTitle: {
        overflow: 'hidden',
        lineHeight: '1.1',
        fontSize: '1.1rem',
        fontWeight: 700,
        textOverflow: 'ellipsis',
        maxWidth: '175px',
        paddingLeft: '4px',
        '-webkitLineClamp': '2',
        display: '-webkit-box',
        '-webkitBoxOrient': 'vertical',
        color: theme.palette.getContrastText(theme.palette.background.topBar),
    },
    mobileLogoContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    mobileLogo: {
        boxShadow: `4px 4px 4px 0px ${theme.palette.border.shadow}`,
        transition: 'all 0.2s ease-in-out',
        '&:active': {
            transform: 'scale(1.10)',
        },
    },
}))

const useTVStyles = makeStyles(({ palette }) => ({
    hoverContainer: {
        display: 'flex',
        width: '100%',
    },
    title: {
        color: palette.getContrastText(palette.background.topBar),
    },
    left: { flex: 1 },
    right: {
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-end',
    },
    exit: {
        // opacity: 0,
        //make this important to maintain color on hover/active states
        backgroundColor: `${palette.getContrastText(palette.background.topBar)} !important`,
        // transition: 'opacity 0.5s',
    },
    exitLabel: {
        color: palette.getContrastText(palette.getContrastText(palette.background.topBar)),
    },
    active: { opacity: 1 },
}))

const AppBar = () => {
    const dispatch = useDispatch()
    const isSuperUser = useSelector(authSelectors.getIsPowerUser)
    const isV2 = useSelector(moduleSelectors.getIsV2)
    const layout = useSelector(appearanceSelectors.getApplicationLayout)
    const showNeoClassic = useSelector(appearanceSelectors.getShowNeoClassicLayOut)
    const [openSearchModal, setOpenSearchModal] = useState(false)
    const [openSearchPopover, setOpenSearchPopover] = useState(false)
    const [openMobileNavPopover, setOpenMobileNavPopover] = useState(false)
    const [anchorUserProfile, setAnchorUserProfile] = useState(null)
    const [searchText, setSearchText] = useState('')
    const classes = useSearchDialogStyles()
    const enableSearch = useSelector(moduleSelectors.getEnableSearch)
    const showApplicationController = useSelector(appearanceSelectors.getShowApplicationController)
    const searchButtonRef = useRef()
    const topBarRef = useRef()
    const userAlias = useSelector(authSelectors.getUserAlias)
    const showHelp = useSelector(appearanceSelectors.getShowHelp)

    const atHome = Boolean(useMatch(routePaths.HOME))
    const atEdit = Boolean(useMatch(routePaths.EDIT))

    const openSearchBarModal = () => {
        setOpenSearchModal(true)
    }

    const handleKeyPressDebounce = useDebouncedCallback(openSearchBarModal, 100)
    /**In future we may need to show AI dialog randomly hence keeping this logic but commented */
    // useEffect(() => {
    //     const chatDialogShown = localStorage.getItem('chatDialogShown')
    //     const msFor3Days = 3 * 24 * 60 * 60 * 1000
    //     const now = new Date()
    //     if (!chatDialogShown && Math.random() < 0.5) {
    //         setOpenAiChat(true)
    //         localStorage.setItem('chatDialogShown', JSON.stringify({ value: true, expiry: now.getTime() + msFor3Days }))
    //         return
    //     }
    //     const { expiry } = JSON.parse(chatDialogShown)

    //     if (now.getTime() > expiry && Math.random() < 0.5) {
    //         setOpenAiChat(true)
    //         localStorage.setItem('chatDialogShown', JSON.stringify({ value: true, expiry: now.getTime() + msFor3Days }))
    //         return
    //     }
    // }, [])

    useEffect(() => {
        const handleKeyPress = e => {
            if (!enableSearch) return
            if (
                e.target.tagName === 'INPUT' ||
                e.target.tagName === 'TEXTAREA' ||
                e.target.isContentEditable ||
                e.target.classList?.contains('ag-cell-inline-editing')
            ) {
                return
            }

            if (e.ctrlKey || e.altKey || e.metaKey) {
                return
            }
            if (/^[a-zA-Z0-9]$/.test(e.key)) {
                setSearchText(p => p + e.key)
                if (!openSearchModal) {
                    handleKeyPressDebounce()
                }
            }
        }

        window.addEventListener('keydown', handleKeyPress)

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('keydown', handleKeyPress)
        }
    }, [enableSearch])

    useHotKey(HotKeys.Search, () => {
        if (enableSearch) setOpenSearchModal(true)
    })

    const { palette } = useTheme()
    const isMobile = useIsMobile()
    const contrastText = palette.getContrastText(palette.background.topBar)
    const menuIconProps = {
        buttonStyle: { padding: '6px' },
        iconStyle: {
            fontSize: '18px',
            color: palette.text.primary,
        },
        fullScreenIconStyle: {
            fontSize: '24px',
            color: palette.text.primary,
        },
    }

    const [enableDrafts] = useFeature('builder_drafts')
    const isBetaTurnedOn = useSelector(appearanceSelectors.getIsBetaTurnedOn)
    const enableNeoAppSelector = showNeoClassic && isBetaTurnedOn
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const moduleTitle = useSelector(moduleSelectors.getModuleTitle)
    const goHome = () => dispatch(navigationCreators.goTo(routePaths.HOME))

    const onSearchModalClose = () => {
        setSearchText('')
        setOpenSearchModal(false)
    }

    const onSearchPopoverClose = () => {
        setSearchText('')
        setOpenSearchPopover(false)
    }

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') onSearchModalClose()
    }

    const openProfilePopover = Boolean(anchorUserProfile)
    const contrastColor = palette.getContrastText(palette.background.topBar)

    if (isMobile) {
        return (
            <Box
                ref={topBarRef}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    position: 'relative',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '14%' }}>
                        <House color={atHome ? 'primary' : 'text.primary'} sx={{ fontSize: '18px' }} onClick={goHome} />
                        <FullScreenButton iconStyle={menuIconProps.fullScreenIconStyle} />
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
                        <Box className={classes.mobileLogoContainer} onClick={() => setOpenMobileNavPopover(true)}>
                            <ApplicationLogo classes={{ mobileLogo: classes.mobileLogo }} />
                            <Arrow htmlColor={contrastColor} />
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <NotificationController buttonStyle={{ padding: '0px' }} iconStyle={menuIconProps.iconStyle} />
                        <IconButton sx={{ paddingRight: '4px' }} onClick={e => setAnchorUserProfile(e.currentTarget)}>
                            <PersonIcon
                                style={{
                                    padding: '0px',
                                    marginLeft: '4px',
                                    fontSize: '20px',
                                    color: palette.text.primary,
                                }}
                            />
                        </IconButton>
                    </Box>
                </Box>
                <Box sx={{ width: '100%', padding: '3px 0' }}>
                    <MobileSearchController />
                </Box>

                <QuickNavButton
                    isNavOpen={openMobileNavPopover}
                    onNavClose={() => setOpenMobileNavPopover(false)}
                    anchorEl={topBarRef.current}
                />
                <Popover
                    open={openProfilePopover}
                    onClose={() => setAnchorUserProfile(null)}
                    anchorEl={anchorUserProfile}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <UserProfileButton />
                </Popover>
            </Box>
        )
    }

    return (
        <DefaultErrorBoundary>
            <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                <ApplicationLogo onClick={goHome} />
                <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={({ custom }) => ({
                        borderColor: contrastColor,
                        mt: 0,
                        mb: 0,
                        mr: '4px',
                        ml: '4px',
                        borderWidth: '1px',
                        height: `${custom.topNav.height - 10}px`,
                        alignSelf: 'center',
                    })}
                />
                {!enableNeoAppSelector && <ApplicationController moduleTitle={moduleTitle} textColor={contrastColor} />}
                {enableNeoAppSelector && (
                    <Tooltip title={appName}>
                        <span className={classes.moduleTitle}>{appName}</span>
                    </Tooltip>
                )}
                {layout === 'classic' && (
                    <>
                        <BusinessElementButtons />
                        {!showNeoClassic && <AppearanceButton sx={{ color: contrastColor }} />}
                    </>
                )}
            </Box>

            {layout === 'modern' && (
                <>
                    <AppMenu />
                    <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        sx={{
                            borderColor: contrastColor,
                            mt: 0,
                            mb: 0,
                            mr: '4px',
                            borderWidth: '1px',
                            height: '50%',
                            alignSelf: 'center',
                        }}
                    />
                </>
            )}

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {isSuperUser && isV2 && enableDrafts && <DeploymentViewController isDisabled={atEdit} />}
                {!atHome && enableSearch && (
                    <Box
                        sx={{
                            width: 50,
                            alignSelf: 'center',
                            backgroundColor: 'white',
                            border: `1px solid ${contrastColor}`,
                            borderRadius: '20px',
                            height: 30,
                            cursor: 'pointer',
                        }}
                        onClick={() => setOpenSearchPopover(true)}
                        ref={searchButtonRef}
                    >
                        <Search htmlColor="#24292E" className={classes.smallIcon} />
                    </Box>
                )}
            </Box>

            <ZendeskController
                iconProps={{ sx: { fontSize: '30px', color: contrastText } }}
                buttonProps={{ sx: menuIconProps.buttonStyle }}
                userAlias={userAlias}
                onOpen={() => {
                    logEvent('OPEN_ZENDESK_CONTACT_FORM')
                }}
                open={showHelp}
                onClose={() => dispatch(appearanceCreators.toggleHelp())}
            />

            <UserProfile />
            {enableNeoAppSelector && (
                <>
                    {enableNeoAppSelector && (
                        <Tooltip title={moduleTitle}>
                            <span className={classes.moduleTitle}>{moduleTitle}</span>
                        </Tooltip>
                    )}
                    <CloudQueueRoundedIcon
                        fontSize="large"
                        sx={{
                            cursor: 'pointer',
                            color: contrastText,
                            ml: 0.5,
                            transition: `all 200ms`,
                            transform: _p => `rotateX(${!showApplicationController ? 0 : 180}deg)`,
                        }}
                        onClick={() =>
                            dispatch(appearanceCreators.setShowApplicationSelector(!showApplicationController))
                        }
                    />
                </>
            )}
            <Dialog
                open={openSearchModal}
                onClose={handleClose}
                maxWidth="lg"
                classes={{ root: classes.root, paper: classes.paper }}
            >
                <DialogContent classes={{ root: classes.dialogContentRoot }}>
                    <Box sx={{ margin: '25px' }}>
                        <div className={classes.controls}>
                            <IconButton sx={{ color: 'text.primary' }} onClick={onSearchModalClose}>
                                <Close />
                            </IconButton>
                        </div>
                        <SearchController
                            preSearchText={searchText}
                            setPreSearchText={setSearchText}
                            onCloseSearch={onSearchModalClose}
                            width="35vw"
                        />
                    </Box>
                </DialogContent>
            </Dialog>
            <Popover
                open={openSearchPopover}
                anchorEl={searchButtonRef.current}
                onClose={onSearchPopoverClose}
                classes={{ paper: classes.popover }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <SearchController />
            </Popover>
        </DefaultErrorBoundary>
    )
}

const TVBar = () => {
    const classes = useTVStyles()
    const dispatch = useDispatch()
    const hoverRef = useRef()
    const hover = useHover(hoverRef)

    return (
        <div className={classes.hoverContainer} ref={hoverRef}>
            <div className={classes.left}>
                <ApplicationLogo />
            </div>
            <NavTitle className={classes.title} />
            <div className={classes.right}>
                <Button
                    classes={{
                        root: classnames([classes.exit, { [classes.active]: hover }]),
                        label: classes.exitLabel,
                    }}
                    onClick={() => dispatch(goBack())}
                >
                    Exit
                </Button>
            </div>
        </div>
    )
}

const CloudNameBar = () => {
    const isMobile = useIsMobile()
    const currentApplication = useSelector(applicationSelectors.getCurrentAppName)
    const appInfo = useSelector(applicationSelectors.getAppInfo)
    const appDisplayName = appInfo ? appInfo.appDisplayName : currentApplication
    const navigate = useNavigate()
    const classes = useTopNavStyles()
    const theme = useTheme()
    const currentModule = useSelector(moduleSelectors.getCurrentModule)
    const bgColor = currentModule?.theme?.custom?.accent1?.main ?? '#FDB01C'
    const color = theme.palette.getContrastText(bgColor)

    if (!isMobile) return

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: bgColor,
                color: color,
                position: 'relative',
            }}
        >
            <div style={{ position: 'absolute', top: 0, left: 0 }}>
                <ApplicationLogo
                    width={'3rem'}
                    classes={{ image: classes.applogo }}
                    onClick={() => navigate('/select')}
                />
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <Typography
                    sx={{ maxWidth: '65%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                    fontWeight={'bold'}
                >
                    {appDisplayName}
                </Typography>
            </div>
        </div>
    )
}
const TopNav = () => {
    const isMobile = useIsMobile()

    const classes = useTopNavStyles({ isMobile })
    const atTVRoute = useMatch(routePaths.TV)

    return (
        <div className={classes.topNavWrapper}>
            <CloudNameBar />
            <Banner />
            <div className={classes.flexContainer}>{atTVRoute ? <TVBar /> : <AppBar />}</div>
            <SecondTopBar />
            <ThirdTopBar />
            <NeoClassicGlobalFiltersWrapper />
        </div>
    )
}

export default TopNav
