import { Button, IconButton, TextField, Box, Chip } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import makeStyles from '@mui/styles/makeStyles'
import EditorWrapper from '../config_fields/EditorWrapper'
import { useContext, useState } from 'react'
import { ConfigContext } from '../ConfigContext'
import { is } from 'immer/dist/internal'
import { isEmpty } from 'lodash'

const useStyles = makeStyles(({ spacing, palette }) => ({
    actionContainer: {
        border: `2px solid ${palette.grayscale.light}`,
        padding: spacing(0.5),
        borderRadius: spacing(0.5),
        marginTop: spacing(0.5),
    },
    chipContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: spacing(0.5),
    },
    inputBox: {
        display: 'flex',
        alignItems: 'center',
    },
}))

export default function WidgetAction() {
    const classes = useStyles()
    const { config, dispatch, selectedField, configStructure, configKey } = useContext(ConfigContext)
    const [isDuplicate, setIsDuplicate] = useState(false)
    const [inputValue, setInputValue] = useState('')
    if (isEmpty(configStructure) || !configKey || isEmpty(configStructure[configKey])) return null
    const { Actions } = config

    const handleAddChip = () => {
        if (inputValue.trim()) {
            const actionName = inputValue.trim()
            const isDuplicate = Actions.some(action => action.ActionName === actionName)
            if (!isDuplicate) {
                const updatedActions = [...Actions, { ActionName: actionName }]
                dispatch({ type: 'UPDATE_ACTIONS', payload: updatedActions })
                dispatch({ type: 'SET_SELECTED_FIELD', payload: { type: 'action', index: updatedActions.length - 1 } })
                setInputValue('')
                setIsDuplicate(false)
            } else {
                setIsDuplicate(true)
            }
        }
    }

    const handleDeleteChip = (actionName: string) => {
        const updatedActions = Actions.filter(chip => chip.ActionName !== actionName)
        dispatch({ type: 'UPDATE_ACTIONS', payload: updatedActions })
        dispatch({ type: 'SET_SELECTED_FIELD', payload: null })
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            handleAddChip()
        }
    }

    return (
        <EditorWrapper header="Actions">
            <Box className={classes.actionContainer}>
                <Box className={classes.chipContainer}>
                    {Actions?.map((action, index) => (
                        <Chip
                            key={index}
                            label={action.ActionName}
                            onDelete={() => handleDeleteChip(action.ActionName)}
                            color="primary"
                            variant={
                                selectedField?.type === 'action' && selectedField.index === index
                                    ? 'filled'
                                    : 'outlined'
                            }
                            clickable
                            onClick={() => dispatch({ type: 'SET_SELECTED_FIELD', payload: { type: 'action', index } })}
                        />
                    ))}
                </Box>
                <Box className={classes.inputBox}>
                    <TextField
                        label="Action Name"
                        variant="standard"
                        size="small"
                        value={inputValue}
                        onChange={e => setInputValue(e.target.value)}
                        onKeyDown={handleKeyDown}
                        helperText={isDuplicate ? 'Action name already exists' : ''}
                        error={isDuplicate}
                    />
                    <IconButton color="primary" onClick={handleAddChip}>
                        <AddIcon />
                    </IconButton>
                </Box>
            </Box>
        </EditorWrapper>
    )
}
