import Spinner from 'genesis-suite/components/Spinner'
import { forwardRef, memo, useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { widgetConstants } from '../../constants'
import { createFetchConfig } from '../../lib/utils'
import { widgetSelectors } from '../../selectors'
import { PerspectiveContext } from '../contexts/PerspectiveContext'
import { InteractionProvider } from './InteractionContext'
import WidgetEmpty from './WidgetEmpty'
import WidgetError from './WidgetError'
import WidgetErrorBoundary from './WidgetErrorBoundary'
import WidgetUnauthorized from './WidgetUnauthorized'
import WidgetVisual from './WidgetVisual'
import WidgetWaiting from './WidgetWaiting'
import { useWidgetData } from './hooks/useWidgetData'
import { WIDGET_CONFIG_MAP } from './lib/configMap'
import withWidgetTooltip from './withWidgetTooltip'

const { ERROR, WAITING, FETCHING, UNAUTHORIZED, EMPTY } = widgetConstants.DataStatus

/**
 * Renders a widget and tooltip based on provided configuration.
 * @param {Object}  props.config the widget config
 * @param {Object=}  props.filters filters to be applied to the widget content
 * @param {Object=}  props.networkContext networkContext to be applied to the widget content
 * @param {(Object | false)=}  props.interactions false to disable interactions, otherwise an object keyed by event name of interaction overrides
 * @param {*=}  props.inlineFilters optional. global inline filters
 * @param {*=}  props.interactionType optional. 'drilldown' | 'infinite'
 * @param {function=}  props.onLoad callback for when new content is loaded for the widget
 * @param {function=}  props.goToPerspective optional. callback to navigate to perspective
 * @param {function=}  props.openDetails optional. callback to open details dialog for widget
 * @param {function=}  props.openProfile optional. callback to open profile for a node
 * @param {function=}  props.setInlineFilters optional. callback update the global inline filters
 * @param {string=} props.controlWidget control widget if any
 */

const Widget = forwardRef(
    (
        {
            config,
            networkContext,
            onLoad,
            appMode,
            setTrendLines,
            customConfig,
            inlineFilters,
            filters,
            activeScenarios,
            minimizeWidgetCB,
            setShowWidgetVersionToggle,
            canMultipleEdit,
            setCanMultipleEdit,
            isSyncChart,
            reload = false,
            ...rest
        },
        ref
    ) => {
        const refreshWidgets = useSelector(widgetSelectors.getRefreshWidgets)
        const { controlWidget, filterByWidgetId } = useContext(PerspectiveContext)
        const [widgetControl, setWidgetControl] = useState(() => makeInitialWidgetControl(config))
        const _config = useMemo(
            () =>
                createFetchConfig(
                    config,
                    networkContext,
                    filters,
                    inlineFilters,
                    filterByWidgetId?.[config.Id],
                    widgetControl,
                    rest?.actionFilters?.filters
                ),
            [config, networkContext, filters, inlineFilters, filterByWidgetId, widgetControl, rest?.actionFilters]
        )
        const { informationConfig, data, status, refresh } = useWidgetData(_config, activeScenarios, reload)
        useEffect(() => {
            if (refreshWidgets.includes(informationConfig?.Id) && !informationConfig.ForControl) {
                refresh()
            }
        }, [refreshWidgets])

        useEffect(() => {
            if (widgetControl.pageNumber !== 1) setWidgetControl(s => ({ ...s, pageNumber: 1 }))
        }, [config, networkContext, filters, inlineFilters, filterByWidgetId])

        let trendLines = []

        useEffect(() => {
            if (
                appMode === 'perspective' &&
                informationConfig &&
                informationConfig.ChartConfig &&
                informationConfig.ChartConfig.Series
            ) {
                informationConfig.ChartConfig.Series.forEach(series => {
                    let trendLineObj = {
                        seriesName: series.SeriesName,
                        showTrendLine: false,
                    }
                    if (series.IsTrendLine) trendLines.push(trendLineObj)
                })
            }

            if (trendLines.length > 0) {
                setTrendLines(trendLines)
            }
        }, [informationConfig])

        useEffect(() => {
            if (onLoad) onLoad(data)
        }, [data])

        const content =
            status === WAITING ? (
                <WidgetWaiting />
            ) : (status === FETCHING && !config.FormConfig) || (!informationConfig && config.FormConfig) ? (
                <Spinner />
            ) : status === ERROR ? (
                <MinimizwWidget config={config} cb={minimizeWidgetCB} type={ERROR}>
                    <WidgetError triggerRefresh={refresh} />
                </MinimizwWidget>
            ) : status === UNAUTHORIZED ? (
                <WidgetUnauthorized />
            ) : status === EMPTY ? (
                <MinimizwWidget config={config} cb={minimizeWidgetCB} type={EMPTY}>
                    <WidgetEmpty NoDataMessage={config.NoDataMessage} />
                </MinimizwWidget>
            ) : (
                <WidgetVisual
                    ref={ref}
                    data={data}
                    appMode={appMode}
                    requestConfig={_config}
                    triggerRefresh={refresh}
                    config={informationConfig}
                    customConfig={customConfig}
                    fetching={status === FETCHING}
                    isControlWidget={config.Id === controlWidget?.id}
                    canMultipleEdit={canMultipleEdit}
                    setCanMultipleEdit={setCanMultipleEdit}
                    minimizeWidgetCB={minimizeWidgetCB}
                    isSyncChart={isSyncChart}
                    activeScenarios={activeScenarios}
                    {...rest}
                />
            )

        return (
            // Always render the interaction provider so that it can keep local state between updates
            <WidgetErrorBoundary requestConfig={_config}>
                <InteractionProvider
                    baseConfig={config}
                    activeConfig={_config}
                    setWidgetControl={setWidgetControl}
                    inlineFilters={inlineFilters}
                    appMode={appMode}
                    informationConfig={informationConfig}
                    {...rest}
                >
                    {content}
                </InteractionProvider>
            </WidgetErrorBoundary>
        )
    }
)

const MinimizwWidget = ({ children, config, cb, type }) => {
    if (cb && config) {
        cb({ ...config, minimizeReason: type })
    }

    return <>{children}</>
}

export const makeInitialWidgetControl = config => {
    const visualConfigKey = WIDGET_CONFIG_MAP[config.Type]?.ConfigKey
    const sortOrders = config.Type.toLowerCase() === 'table' ? config[visualConfigKey].SortOrders ?? {} : null

    return { pageSize: null, pageNumber: 1, sortOrders, searchUrl: null }
}

// add tooltip and memoize Widget to prevent rerendering on tooltipConfig changes
export default withWidgetTooltip(memo(Widget))
