import { forwardRef, useState, useLayoutEffect, useCallback, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PerspectiveContext } from '~/components/contexts/PerspectiveContext'
import Measure from 'react-measure'
import { isEqual } from 'lodash'
import { Responsive as GridLayout } from 'react-grid-layout'
import GridLabel from './GridLabel'
import { widgetSelectors, authSelectors } from '~/selectors'
import { widgetCreators } from '~/actions/creators'
import { defaultLGLayout, getMappedSeries, getLayOutsForLabels, defaultVerticalLayout } from './LabelWidgetUtils'
import { LabelStyle } from './LabelStyle'

const useStyles = makeStyles(({ spacing, palette }) => ({
    wrapper: {
        borderRadius: p => (p.containerWidth === '100%' ? '' : '15px'),
        '& .react-grid-placeholder': { background: palette.primary.main },
        '& .react-resizable-handle.react-resizable-handle-se': {
            borderBottom: `4px solid ${palette.primary.main}`,
            borderRight: `4px solid ${palette.primary.main}`,
            width: '15px',
            height: '15px',
            backgroundImage: 'none',
            '&::after': { display: 'none' },
        },
    },
}))

const TopLabelWidget = forwardRef(({ configs, isEdit, orientation = 'horizontal' }, ref) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const topLabelWidgetLayouts = useSelector(widgetSelectors.getTopLabelWidgetLayouts)
    const { topLabelData, setTopLabelData } = useContext(PerspectiveContext)

    const topLabelSeries = getMappedSeries(configs)
    const isAdmin = useSelector(authSelectors.getIsAdmin)
    const widgetEdit = isAdmin ? isEdit : false
    const LG_WIDTH = 7000
    const LG_COL = 50

    const [mWidth, setMWidth] = useState(500)
    const [mHeight, setMHeight] = useState(0)
    const [layout, setLayout] = useState({ lg: [], sm: [] })

    useLayoutEffect(() => {
        const topLabelSeriesBool = topLabelSeries.every(item => item.Layout)
        const topLabelWidgetsLayoutBool = isEqual(topLabelWidgetLayouts?.payload?.configs, configs)
        let data = []
        if (orientation === 'vertical') {
            data = defaultVerticalLayout(topLabelSeries)
            if (isEqual(layout.sm, data)) return
            setLayout({ sm: data, lg: [] })
        } else {
            if (widgetEdit && topLabelWidgetsLayoutBool && topLabelWidgetLayouts?.payload?.lg?.length > 0) {
                data = topLabelWidgetLayouts?.payload?.lg
            } else if (topLabelSeriesBool) {
                data = getLayOutsForLabels(topLabelSeries, true)
                cleanLayouts(data)
            } else {
                data = defaultLGLayout(topLabelSeries)
            }
            if (isEqual(layout.lg, data)) return
            setLayout({ lg: data, sm: [] })
        }
    }, [])

    const handleLayoutChange = (_layout, layouts) => {
        if (!widgetEdit) return
        if (layout?.lg?.length == 0) return
        let updated_layout = _layout?.map(({ i, x, y, w, h, minH, maxH, minW }) => ({
            i,
            x,
            y,
            w,
            h,
            minH,
            maxH,
            minW,
        }))

        cleanLayouts(updated_layout)

        if (isEqual(layout.lg, updated_layout)) return
        const updated = {
            lg: updated_layout,
            configs: configs,
        }
        setLayout({ lg: updated_layout, sm: [] })
        dispatch(widgetCreators.changeTopLabelWidgetLayouts(updated))
    }

    const cleanLayouts = layout => {
        for (let i = 0; i < layout.length; i++) {
            if (i == 0) {
                layout[i].x = 0
            } else {
                layout[i].x = layout[i - 1].x + layout[i - 1].w
            }
            layout[i].y = 0
        }
    }

    const getProps = useCallback(
        labelSeries => {
            const _labelsConfig = configs.find(obj => obj.Id == labelSeries.config_id)
            const _labelData = topLabelData[labelSeries.config_id]
            const labelsConfig = _labelsConfig.LabelConfig
            const content = _labelData == undefined ? null : _labelData
            const status = _labelData == undefined ? 'fetching' : _labelData.status
            return { labelsConfig, content, status }
        },
        [topLabelData]
    )

    const getWidth = key => {
        let baseWidth = 100
        let itemWidth = layout?.lg?.find(item => item.i == key)
        if (itemWidth?.w) {
            baseWidth = (LG_WIDTH / LG_COL) * itemWidth.w - 20
        }
        return baseWidth
    }

    return (
        <Measure
            client
            onResize={({ client }) => {
                setMWidth(client.width - 1) // prevent overlap w/ scrollbar
                setMHeight(client.height - 1)
            }}
        >
            {({ measureRef }) => (
                <div
                    ref={measureRef}
                    style={{ width: '100%', overflowX: 'auto', overflowY: 'hidden' }}
                    className={classes.wrapper}
                >
                    <div style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
                        <GridLayout
                            layouts={{ lg: layout.lg, sm: layout.sm }}
                            cols={{ lg: LG_COL, sm: 1 }} // Define the number of columns
                            rowHeight={75}
                            width={orientation === 'vertical' ? mWidth : LG_WIDTH} // Set the width of the grid container
                            isDraggable={widgetEdit}
                            isResizable={widgetEdit}
                            breakpoints={{ sm: 0, lg: 300 }}
                            onLayoutChange={handleLayoutChange}
                        >
                            {topLabelSeries?.map((c, i) => {
                                const props = getProps(c)
                                const { labelStyle, leftStripStyle } = LabelStyle(c, props.content)
                                return (
                                    <div key={c.config_id + '_' + c.SeriesName.replaceAll(' ', '')} style={labelStyle}>
                                        <Box display="flex" sx={{ height: '100%', width: '100%' }}>
                                            <div style={leftStripStyle}></div>
                                            <GridLabel
                                                key={i}
                                                {...props}
                                                labelConfig={c}
                                                leftStripStyle={leftStripStyle}
                                                itemWidth={getWidth(
                                                    c.config_id + '_' + c.SeriesName.replaceAll(' ', '')
                                                )}
                                                labelStyle={labelStyle}
                                            />
                                        </Box>
                                    </div>
                                )
                            })}
                        </GridLayout>
                    </div>
                </div>
            )}
        </Measure>
    )
})

export default TopLabelWidget
