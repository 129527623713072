import {
    CellClassParams,
    ColDef,
    ICellRendererParams,
    IServerSideGetRowsParams,
    IServerSideGetRowsRequest,
    ServerSideTransaction,
} from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import { Box, Typography } from '@mui/material'
import { CategoryTableColumn, DataGroup, TableConfig, ValueTableColumn } from 'genesis-suite/types/visualTypes'
import { cloneDeep } from 'lodash'
import { ReactNode, useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { v4 as uuid } from 'uuid'
import { agGridConstants } from '../../constants/aggrid.constants'
import useProperties from '../../hooks/useProperties'
import { widgetService } from '../../lib/services'
import { themeSelectors } from '../../selectors'
import { WidgetProps } from '../../types/WidgetTypes'
import { BaseAgGridTable } from '../widgets2/agGridTable/BaseAgGridTable'
import { TableStyleChangesContext } from '../widgets2/contexts/TableStyleChangesContext'
import { conditionalFormatsDynamicTable } from '../widgets2/utils/getConditionalFormats'
import { CategoryColumn, SeriesColumn, createAutoGroupColumn, createColumnStyle, createColumns } from './Column'
import { createPivotSubSeriesRequest } from './DataRequest'
import { GetRowDataParams, getRowCount, getRowData } from './Row'
import { networkFilterSelectors } from '~/redux/networkFilterSlice'

interface DynamicTableProps extends WidgetProps<TableConfig> {
    categoryRenderer?: (params: ICellRendererParams, index: number) => ReactNode
    seriesRenderer?: (params: ICellRendererParams, value: ValueTableColumn) => ReactNode
    StatusBarComponent?: JSX.Element
    transactions?: ServerSideTransaction[]
    context?: string
    pagination?: boolean //prop to indicate if pagination enabled. Default true. If false, data loads on scrolling
    cacheBlockSize?: number //indicates pageSize for both pagination and infinite srolling scenarios
    cacheNextPage?: boolean //indicates whether to cache next page data
    roundNormal?: boolean // round normal values
    isCompare?: boolean
}

export default function DynamicTable({
    categoryRenderer,
    seriesRenderer,
    StatusBarComponent,
    transactions,
    context,
    pagination = true,
    roundNormal = false,
    ...props
}: DynamicTableProps) {
    const { config, cacheBlockSize, cacheNextPage, isCompare } = props
    const { categories, collapsable, filters } = config
    const gridRef = useRef<AgGridReact>(null)
    const tableRef = useRef<HTMLDivElement>(null)
    const gridStatusRef = useRef<HTMLDivElement>(null)
    const tableStyleChange = useContext(TableStyleChangesContext)?.tableStyleChange
    const themeMode = useSelector(themeSelectors.getThemeMode)
    const selectedNetworkFiltersIds = useSelector(
        isCompare ? networkFilterSelectors.getAppliedCompareIds : networkFilterSelectors.getAppliedIds
    )
    const properties = useProperties()
    const tableSeries = config.series[0]
    //map the pivot column name to the series name, used to sort columns when a pivot exists
    const mappedPivotsColumns: { [key: string]: string } = useMemo(() => {
        return {}
    }, [])
    const mappedSeriesTitles = mapTitles(tableSeries.values)
    const mappedCategoriesTitles = mapTitles(categories)
    const localDataSource: any = {}
    let totalRowCount = 0
    let accRowCount = 0

    const createColumnDefs = useCallback(() => {
        let _categoryRenderer = categoryRenderer

        if (!_categoryRenderer)
            _categoryRenderer = (params: ICellRendererParams, index: number) => (
                <CategoryColumn
                    params={params}
                    canClickTable={props.canClick}
                    categories={categories}
                    columnIndex={index}
                    series={tableSeries}
                    setSelectedPoint={props.setSelectedPoint}
                    themeMode={themeMode}
                />
            )

        let _seriesRenderer = seriesRenderer

        if (!_seriesRenderer)
            _seriesRenderer = (params: ICellRendererParams, value: ValueTableColumn) => (
                <SeriesColumn
                    params={params}
                    hyperlinkUrl={value.hyperlinkUrl}
                    isHyperlink={value.isHyperlink}
                    numberFormat={value.numberFormat}
                    roundNormal={roundNormal}
                />
            )

        const colDefs = createColumns(
            categories,
            properties,
            themeMode,
            _categoryRenderer,
            _seriesRenderer,
            !!tableSeries?.subSeries,
            collapsable,
            mappedCategoriesTitles,
            config.series,
            mappedSeriesTitles
        )
        return colDefs
    }, [
        categoryRenderer,
        seriesRenderer,
        categories,
        properties,
        themeMode,
        tableSeries,
        collapsable,
        mappedCategoriesTitles,
        config.series,
        mappedSeriesTitles,
        props.canClick,
        props.setSelectedPoint,
    ])

    const addPivotColDefs = useCallback(
        async (request: IServerSideGetRowsRequest) => {
            const grid = gridRef.current

            //fetch all values from the pivot column and use them to create pivotColDefs
            const dataRequest = createPivotSubSeriesRequest(config)
            const result = await widgetService.getData2(dataRequest)

            // check if pivot colDefs already exist and has the same length as the result times the number of series (that's how the pivot is shown)
            const existingPivotColDefs = grid?.columnApi?.getPivotResultColumns()
            if (
                existingPivotColDefs &&
                existingPivotColDefs.length === tableSeries?.values.length * result[0].data.length
            )
                return

            let pivotData = result[0].data

            // sort the result if the sort is set to descending
            if (tableSeries?.subSeries.sort === 'descending') pivotData = pivotData.sort((a, b) => (a < b ? 1 : -1))

            // create pivot colDef's based of data returned from the server
            const pivotColDefs = createPivotColDefs(
                request,
                pivotData,
                tableSeries?.values,
                themeMode,
                mappedSeriesTitles,
                mappedPivotsColumns
            )
            // supply pivot result columns to the grid
            grid?.columnApi?.setPivotResultColumns(pivotColDefs)
        },
        [mappedPivotsColumns, mappedSeriesTitles, config, tableSeries?.subSeries?.sort, tableSeries?.values, themeMode]
    )

    function handleFirstDataRendered() {
        if (!getShouldFlex()) gridRef?.current?.columnApi.autoSizeAllColumns()
    }

    function getShouldFlex() {
        //@ts-ignore
        const tableBody = tableRef.current?.getElementsByClassName('ag-body-viewport')[0]
        const tableWidth = tableBody?.clientWidth ?? 0
        return tableWidth / gridRef.current?.columnApi.getColumns().length > 150
    }

    const getDataSource = useCallback(() => {
        return {
            getRows: async (params: IServerSideGetRowsParams) => {
                try {
                    if (params.request.pivotCols.length > 0)
                        // add pivot colDefs in the grid based on the resulting data
                        addPivotColDefs(params.request)

                    let result: any = {}
                    /**
                     * If pagination is false then table has to load data on scroll
                     * In order to give seemless loading experience, we are fetching first
                     * 2 pages data and storing  in cache as localDataSource and serving data to grid from local datasource
                     * If pagination is true, following legacy implementation to load pagination control
                     * and loading data as moving across pages.
                     */
                    if (!pagination && cacheNextPage) {
                        result = await updateLocalDataSourceAndGetRowData({
                            request: params.request,
                            config: config,
                            categories: categories,
                            tableSeries: tableSeries,
                            mappedPivotsColumns: mappedPivotsColumns,
                            properties: properties,
                            isCollapsable: collapsable,
                            filters: filters,
                            networkFilters: selectedNetworkFiltersIds,
                            context: context,
                            cacheBlockSize: cacheBlockSize,
                        } as GetRowDataParams)
                    } else {
                        result = await getRowData({
                            request: params.request,
                            config: config,
                            categories: categories,
                            tableSeries: tableSeries,
                            mappedPivotsColumns: mappedPivotsColumns,
                            properties: properties,
                            isCollapsable: collapsable,
                            filters: filters,
                            networkFilters: selectedNetworkFiltersIds,
                            context: context,
                            cacheBlockSize: cacheBlockSize,
                        } as GetRowDataParams)

                        if (result.totalRowCount) totalRowCount = result.totalRowCount

                        result.rowCount = getRowCount(
                            collapsable ? result.data : result.rows,
                            params.request,
                            agGridConstants.CACHE_BLOCK_SIZE
                        )
                    }

                    if (!result.data) {
                        params.success({
                            rowData: [],
                            rowCount: 0,
                        })
                        return
                    }

                    accRowCount = params.request?.endRow >= totalRowCount ? totalRowCount : params.request?.endRow
                    if (gridStatusRef.current)
                        gridStatusRef.current.innerHTML = `Showing 1 - ${accRowCount} of ${totalRowCount} records`

                    params.success({
                        rowData: result.rows,
                        rowCount: result.rowCount,
                    })

                    if (transactions) {
                        const transactionRoute = params.request.groupKeys
                        const transaction = transactions.find(t => t.route.join() === transactionRoute.join())
                        if (transaction) {
                            const keyCategory = categories[transactionRoute.length]
                            // API sometimes returns the title and sometimes returns the field name
                            const keyFieldName = keyCategory.field.name
                            const keyTitle = keyCategory.title
                            const { add = [], update = [], remove = [] } = transaction
                            const { updatesToAdd, mergedUpdates } = update.reduce(
                                (acc, updateRow) => {
                                    const row = result.rows.find(r => {
                                        if (r[keyFieldName] === updateRow[keyFieldName]) return true
                                        else if (r[keyTitle] === updateRow[keyFieldName]) {
                                            return true
                                        }
                                    })
                                    if (!row)
                                        acc.updatesToAdd.push({ ...updateRow, [keyTitle]: updateRow[keyFieldName] })
                                    else acc.mergedUpdates.push({ ...row, ...updateRow })
                                    return acc
                                },
                                { updatesToAdd: [], mergedUpdates: [] }
                            )
                            const transactionResult = gridRef.current.api.applyServerSideTransaction({
                                route: transactionRoute,
                                add: add.concat(updatesToAdd).map(d => ({ ...d, id: uuid() })),
                                update: mergedUpdates,
                                remove: remove.map(removeRow => {
                                    const row = result.rows.find(r => r[keyFieldName] === removeRow[keyFieldName])
                                    return row?.id ?? ''
                                }),
                            })
                            if (transactionResult.status !== 'Applied') {
                                console.error('Transaction failed', transactionResult)
                            }
                        }
                    }
                } catch (error) {
                    console.error('error', error)
                    params.fail()
                }
            },
        }
    }, [addPivotColDefs, mappedPivotsColumns, properties, config, tableSeries?.values])

    const updateLocalDataSourceAndGetRowData = async (params: GetRowDataParams) => {
        const {
            request,
            config,
            categories,
            tableSeries,
            mappedPivotsColumns,
            properties,
            isCollapsable,
            filters,
            context,
            cacheBlockSize,
            networkFilters,
        } = params
        let size = cacheBlockSize || agGridConstants.CACHE_BLOCK_SIZE
        const clonedRequest = cloneDeep(request)
        const startRow = clonedRequest?.startRow
        const endRow = clonedRequest?.endRow
        /**
         * When data being loaded for first page, to make single request for first 2 pages
         * updating endRow and the the cacheBlockSize, making request and storing data in
         * LocalDataSource and serving firstPage data from Local DataSource to Ag-grid
         */
        if (startRow === 0) {
            clonedRequest.endRow = endRow * 2
            size = size * 2
            await updateLocalDataSource({
                request: clonedRequest,
                config: config,
                categories: categories,
                tableSeries: tableSeries,
                mappedPivotsColumns: mappedPivotsColumns,
                properties: properties,
                isCollapsable: collapsable,
                filters: filters,
                networkFilters: networkFilters,
                context: context,
                cacheBlockSize: size,
            } as GetRowDataParams)
        }

        const data = localDataSource?.data?.length ? localDataSource.data.slice(startRow, endRow) : undefined
        const rows = localDataSource?.rows?.length ? localDataSource.rows.slice(startRow, endRow) : undefined
        const result = {
            data: data,
            rows: rows,
            rowCount: getRowCount(isCollapsable ? data : rows, request, cacheBlockSize),
        }

        /**
         * When request comes for 2nd page data from ag-grid, we already have 2nd page onwards data in local datasource
         * which is served to ag-grid. At the same time making call to fetch next page data and store it in local datasource
         * when result.rowCount is -1, we can consider that it is the last page in table and no need to make any call
         * for next page data.
         */
        if (result.rowCount === -1 && startRow !== 0) {
            request.startRow = startRow + size
            request.endRow = endRow + size
            updateLocalDataSource({
                request: request,
                config: config,
                categories: categories,
                tableSeries: tableSeries,
                mappedPivotsColumns: mappedPivotsColumns,
                properties: properties,
                isCollapsable: collapsable,
                filters: filters,
                networkFilters: networkFilters,
                context: context,
                cacheBlockSize: size,
            } as GetRowDataParams)
        }

        return result
    }

    const updateLocalDataSource = async (params: GetRowDataParams) => {
        const {
            request,
            config,
            categories,
            tableSeries,
            mappedPivotsColumns,
            properties,
            isCollapsable,
            filters,
            networkFilters,
            context,
            cacheBlockSize,
        } = params

        const result = await getRowData({
            request,
            config,
            categories,
            tableSeries,
            mappedPivotsColumns,
            properties,
            isCollapsable,
            filters,
            networkFilters,
            context,
            cacheBlockSize,
        })

        if (result) {
            localDataSource.data = localDataSource.data || []
            localDataSource.rows = localDataSource.rows || []

            if (result.data) localDataSource.data = [...localDataSource.data, ...result.data]
            if (result.rows) localDataSource.rows = [...localDataSource.rows, ...result.rows]
            if (result.totalRowCount) totalRowCount = result.totalRowCount
        }
    }

    const gridStatus = `Showing 1 - ${accRowCount} of ${totalRowCount} records`

    const statusBar = useMemo(() => {
        return {
            statusPanels: [
                {
                    statusPanel: () => StatusBarComponent,
                    align: 'left',
                },
            ],
        }
    }, [])

    const defaultColDef = useMemo(() => {
        return {
            minWidth: 120,
            resizable: true,
            sortable: true,
            unSortIcon: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
        }
    }, [])

    //It's the data in the leaf of a collapsable table, always the last category
    //But the style of this column is applied to all group columns
    const autoGroupColumnDef = useMemo(() => {
        return createAutoGroupColumn(
            collapsable,
            categories,
            themeMode,
            mappedCategoriesTitles,
            props.canClick,
            tableSeries,
            props.setSelectedPoint
        )
    }, [mappedCategoriesTitles, properties, categories, collapsable, themeMode])

    const onGridReady = useCallback(
        params => {
            // register the datasource with the grid
            params.api.setGridOption('serverSideDatasource', getDataSource())
        },
        [getDataSource]
    )

    const clearSort = useCallback(() => {
        const grid = gridRef.current
        grid?.columnApi?.applyColumnState({
            defaultState: { sort: null },
        })
    }, [])

    //refresh the grid when config changes are made in the builder
    useEffect(() => {
        const grid = gridRef.current
        if (!grid?.api) return
        if (tableStyleChange) return grid?.api.refreshCells()
        if (grid?.columnApi && tableSeries?.subSeries) grid?.columnApi.setPivotResultColumns([])
        clearSort()
        grid.api.setGridOption('serverSideDatasource', getDataSource())
    }, [createColumnDefs])

    return (
        <Box ref={tableRef} sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
            {!pagination ? (
                <Typography
                    ref={gridStatusRef}
                    variant="body2"
                    sx={{ alignSelf: 'flex-end', pb: 1 }}
                >{`Showing 0 - ${accRowCount} of ${totalRowCount} records`}</Typography>
            ) : null}
            <BaseAgGridTable
                ref={gridRef}
                getRowId={params => params.data.id}
                autoGroupColumnDef={autoGroupColumnDef}
                createColumnDefs={createColumnDefs()}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                handleFirstDataRendered={handleFirstDataRendered}
                pagination={pagination}
                paginationPageSize={tableSeries?.pageSize}
                cacheBlockSize={cacheBlockSize}
                pivotMode={!!tableSeries?.subSeries}
                statusBar={StatusBarComponent ? statusBar : null}
            />
        </Box>
    )
}

// create pivot colDefs based on the data returned from the server
const createPivotColDefs = (
    request: IServerSideGetRowsRequest,
    response: DataGroup[],
    seriesValues: ValueTableColumn[],
    themeMode: any,
    mappedTitles: Partial<conditionalFormatsDynamicTable>,
    mappedPivotsColumns: { [key: string]: string }
): ColDef[] => {
    if (request.pivotMode && request.pivotCols.length > 0) {
        const pivotResultCols = []
        response.forEach(function (field: DataGroup) {
            if (!field) return
            const colDef: ColDef = {}
            colDef['groupId'] = field.group
            colDef.headerName = field.group
            colDef['children'] = []

            //We create a new object for each pivot group data, because the params.data property, in pivot mode, has the value for all the possible pivot groups
            const pivotGroupData = {}
            seriesValues.reduce((acc, value, index) => {
                acc[value.title || value.field.name] = field.aggregatedData[index]
                return acc
            }, pivotGroupData)

            seriesValues.forEach(seriesValue => {
                const valueColDef: ColDef = {}
                valueColDef.sortable = false
                //we use cellStyle instead of cellRenderer for better style performance and control
                valueColDef.cellStyle = (params: CellClassParams) => {
                    pivotGroupData[params.node.field] = params.node.key
                    return createColumnStyle(
                        params,
                        themeMode,
                        mappedTitles,
                        pivotGroupData,
                        seriesValue.conditionalFormats,
                        seriesValue.align
                    )
                }
                valueColDef.cellRenderer = (params: ICellRendererParams) => (
                    <SeriesColumn
                        params={params}
                        hyperlinkUrl={seriesValue.hyperlinkUrl}
                        isHyperlink={seriesValue.isHyperlink}
                        numberFormat={seriesValue.numberFormat}
                    />
                )
                const seriesField = seriesValue.title || seriesValue.field.name
                valueColDef.colId = `${field.group}_${seriesField}`
                valueColDef.headerName = seriesField
                valueColDef.field = `${field.group}_${seriesField}`

                mappedPivotsColumns[`${field.group}_${seriesField}`] = seriesField

                colDef['children'].push(valueColDef)
            })

            pivotResultCols.push(colDef)
        })
        return pivotResultCols
    }
    return []
}

//creates an object that maps the field name to the title of the column/series, if there is a title
//used with the create series/column style function to map the conditional formats target value, it's better than using find every cell render
const mapTitles = (
    column: ValueTableColumn[] | CategoryTableColumn[] | []
): Partial<conditionalFormatsDynamicTable> => {
    const mappedTitles: Partial<conditionalFormatsDynamicTable> = {}
    column.forEach((value: ValueTableColumn | CategoryTableColumn) => {
        if (value.title) mappedTitles[value.field.name] = value.title
    })

    return mappedTitles
}
