"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = (generator, {
  message
}) => generator({
  sendMessage: data => ({
    baseURL: message,
    method: 'post',
    url: `messages/${data?.domainId}/send`,
    data: data
  }),
  getChats: ({
    domainId,
    participantId,
    page,
    pageSize,
    searchTerm,
    showArchived
  }) => ({
    baseURL: message,
    method: 'get',
    url: `conversations/${domainId}`,
    config: {
      params: {
        participantId,
        page,
        pageSize,
        searchTerm,
        showArchived
      }
    }
  }),
  createNewConversation: data => ({
    baseURL: message,
    method: 'post',
    url: `conversations/${data?.domainId}`,
    data: data
  }),
  deleteConversation: ({
    domainId,
    id
  }) => ({
    baseURL: message,
    method: 'delete',
    url: `conversations/${domainId}`,
    config: {
      params: {
        domainId,
        id
      }
    }
  }),
  addParticipantToChat: ({
    domainId,
    id,
    body
  }) => ({
    baseURL: message,
    method: 'put',
    url: `conversations/${domainId}/participants/add`,
    config: {
      params: {
        domainId,
        id
      }
    },
    data: body
  }),
  deleteParticipantFromChat: ({
    domainId,
    id,
    participantId
  }) => ({
    baseURL: message,
    method: 'delete',
    url: `conversations/${domainId}/participants/remove`,
    config: {
      params: {
        domainId,
        id,
        participantId
      }
    }
  }),
  getChatMessages: ({
    domainId,
    conversationId,
    messageId,
    loadCount,
    direction
  }) => ({
    baseURL: message,
    method: 'get',
    url: `messages/${domainId}`,
    config: {
      params: {
        domainId,
        conversationId,
        messageId,
        loadCount,
        direction
      }
    }
  }),
  updateConversation: ({
    domainId,
    conversationId,
    messageId
  }) => ({
    baseURL: message,
    method: 'put',
    url: `UserConversation/${domainId}`,
    config: {
      params: {
        domainId
      }
    },
    data: {
      conversationId,
      messageId
    }
  }),
  getConversationById: (domainId, conversationId) => ({
    baseURL: message,
    method: 'get',
    url: `Conversations/${domainId}/${conversationId}`
  }),
  getArchiveChats: ({
    domainId,
    id
  }) => ({
    baseURL: message,
    method: 'put',
    url: `conversations/${domainId}/archive`,
    config: {
      params: {
        domainId,
        id
      }
    }
  }),
  getUnarchiveChats: ({
    domainId,
    id
  }) => ({
    baseURL: message,
    method: 'put',
    url: `conversations/${domainId}/unarchive`,
    config: {
      params: {
        domainId,
        id
      }
    }
  })
});
exports.default = _default;