import { CommentOutlined, EditRounded, VisibilityOffRounded } from '@mui/icons-material'
import ClearRounded from '@mui/icons-material/ClearRounded'
import CopyIcon from '@mui/icons-material/FileCopyOutlined'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import PopoutIcon from '@mui/icons-material/LaunchRounded'
import PrintIcon from '@mui/icons-material/PrintOutlined'
import FavoriteIcon from '@mui/icons-material/StarBorderRounded'
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded'
import ChatRoundedIcon from '@mui/icons-material/ChatRounded'
import GroupRounded from '@mui/icons-material/GroupRounded'
import FilledFavoriteIcon from '@mui/icons-material/StarRounded'
import TrendLinesIcon from '@mui/icons-material/TrendingUpRounded'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import { Badge } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Maximize } from 'genesis-suite/icons'
import { useSnackbar } from 'notistack'
import { useContext, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { authCreators, dialogCreators, navigationCreators, widgetCreators } from '../../actions/creators'
import { appearanceConstants } from '../../constants'
import PrintContainer, { printContainerDialogKey } from '../../containers/PrintContainer'
import WidgetInfoContainer from '../../containers/WidgetInfoContainer'
import useDashboardId from '../../hooks/useDashboardId'
import { useIsMobile } from '../../hooks/useIsMobile'
import { logEvent } from '../../lib/amplitudeClient'
import { useFeature } from '../../lib/featureFlags'
import { routePaths } from '../../lib/routes'
import {
    appearanceSelectors,
    applicationSelectors,
    authSelectors,
    filterSelectors,
    moduleSelectors,
} from '../../selectors'
import { CommentContext } from '../comments/CommentContext'
import { ColOptionsContext } from '../contexts/ColumnOptionsContext'
import { PopoutContext } from '../contexts/PopoutWidgetContext'
import { DashboardContext } from '../widgets2/dashboard/DashboardContext'
import TrendLineMenu from './../TrendLineMenu'
import Menu from './Menu'
import { copyImage, getExportType } from './lib/widgetExporting'
import { PerspectiveContext } from '../contexts/PerspectiveContext'
import { messengerSelectors } from '~/selectors/messenger.selectors'
import { messengerCreators } from '~/actions/creators/messenger.creators'
import NewChatDialog from '../Messenger/NewChatDialog'
import { widgetService } from '~/lib/services'

const useStyles = makeStyles(() => ({
    actions: {
        display: 'flex',
        alignItems: 'center',
    },
    paper: { width: 'auto' },
    colOptions: { width: 500 },
}))

/**
 * @param {*} props.config widget config
 * @param {*} props.visualRef ref container of widget
 * @param {*=} props.trendLines (optional)
 * @param {*=} props.data (optional)
 * @param {*=} props.iconStyle (optional)
 * @param {*=} props.buttonStyle (optional)
 * @param {*=} props.onToggle (optional) callback that returns state of menu
 * @returns
 */
export default function WidgetMenu({
    config,
    visualRef,
    trendLines = null,
    data = null,
    iconStyle = {},
    buttonStyle = {},
    onToggle,
}) {
    const { id: perspectiveId } = useDashboardId()
    const classes = useStyles({})
    const isMobile = useIsMobile()

    const { popoutWidget } = useContext(PopoutContext)
    const { config: dashboard, editable: dashboardEditable } = useContext(DashboardContext)
    const { resetOptions } = useContext(ColOptionsContext)
    const { onHideWidget } = useContext(PerspectiveContext)
    const [openDialog, setOpenDialog] = useState(false)

    const userId = useSelector(authSelectors.getUserId)
    const isPublicLogin = useSelector(authSelectors.getIsPublicLogin)
    const isPowerUser = useSelector(authSelectors.getIsPowerUser)
    const layout = useSelector(appearanceSelectors.getApplicationLayout)
    const isFavorite = useSelector(state => authSelectors.getIsFavorite(state, 'widgetFavorites', config.Id))
    const networkContext = useSelector(filterSelectors.getCoord)
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const visorId = useSelector(moduleSelectors.getModuleId)
    const isUserWidget = !config?.IsManaged

    const { data: commentData } = useContext(CommentContext)
    const totalRecords = commentData?.[config.Id]?.totalRecords
    const [showComments] = useFeature('comments')

    const dispatch = useDispatch()
    const { enqueueSnackbar: showSnackbar, closeSnackbar } = useSnackbar()

    const exportType = getExportType(config)
    const exportOptions = { includeWatermark: true, title: config.Title }

    const handleDialogOpen = () => setOpenDialog(true)
    const handleDialogClose = () => setOpenDialog(false)
    const appDomainId = useSelector(state => applicationSelectors.getAppDomainId(state, appName))
    const chatList = useSelector(messengerSelectors.getMessageList)

    const conversationList = useMemo(() => {
        return chatList.filter(conversation =>
            conversation.participants.some(participant => participant.id === config.Id)
        )
    }, [chatList])
    const fullscreenWidget = () => {
        const state = { context: networkContext }
        dispatch(navigationCreators.goTo(`${routePaths.FULLSCREEN}?start=${config.Id}`, perspectiveId, state))
    }

    const onDeleteWidget = async widget => {
        const key = showSnackbar(`Deleting widget ${widget.Title}. Please wait...`, { persist: true })
        try {
            await widgetService.deleteWidget(appName, visorId, widget.Id)
            dispatch(widgetCreators.deleteUserWidget(widget.Id))
            refreshOnDeleteWidget?.(widget.Id)
            showSnackbar(`Widget ${widget.Title} deleted successfully`, { variant: 'success' })
        } catch (e) {
            console.error('Error in deleting widget', e)
            showSnackbar(`Error occurred while deleting widget. Try again later`, { variant: 'error' })
        } finally {
            closeSnackbar(key)
        }
    }

    if (isPublicLogin) return null

    const isTableWidget =
        config.Type?.toUpperCase() === 'TABLE' ||
        (config.Type?.toUpperCase() === 'FORM' && !config.allowFormOnly && config.defaultMode !== 6)
    const handleOpenChat = conversationId => {
        dispatch(messengerCreators.openChatById(conversationId))
    }
    const items = [
        {
            name: 'Info',
            icon: <InfoIcon fontSize="small" />,
            click: () => {
                dispatch(
                    dialogCreators.showDialog(config.Id, WidgetInfoContainer, {
                        title: 'Widget Info',
                        componentProps: { config },
                    })
                )
            },
        },
        {
            name: 'Start Chat',
            click: () => {
                handleDialogOpen()
            },
            hide: !appDomainId || !userId || isUserWidget,
            icon: <QuestionAnswerRoundedIcon />,
        },
        {
            name: 'Open Chat(s)',
            hide: !conversationList?.length > 0 || isUserWidget,
            icon: <ChatRoundedIcon />,
            children: conversationList.map(conversation => {
                return {
                    name: conversation?.name,
                    icon: <GroupRounded />,
                    click: () => handleOpenChat(conversation?.id),
                }
            }),
        },
        {
            name: 'Favorite',
            hide: isUserWidget,
            icon: isFavorite ? <FilledFavoriteIcon fontSize="small" /> : <FavoriteIcon fontSize="small" />,
            click: () => {
                logEvent('FAVORITE_WIDGET')
                dispatch(authCreators.saveFavorite('widgetFavorites', config.Id)).catch(() =>
                    showSnackbar('An error occurred saving your favorites.', { variant: 'error' })
                )
            },
        },
        {
            name: 'Show Comment',

            icon: (
                <Badge
                    badgeContent={totalRecords}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    color="primary"
                >
                    <CommentOutlined fontSize="small" />
                </Badge>
            ),
            click: () => {
                logEvent('OPEN_WIDGET_COMMENTS', { widgetId: config.Id })
                dispatch(widgetCreators.setCommentingWidget(config.Id))
            },
            hide: !showComments || isUserWidget,
        },
        {
            name: 'Popout',
            icon: <PopoutIcon fontSize="small" />,
            hide: layout !== appearanceConstants.Layout.CLASSIC,
            click: () => popoutWidget(config),
        },
        {
            name: 'Print',
            click: () => {
                logEvent('PRINTING_WIDGET')

                dispatch(
                    dialogCreators.showDialog(printContainerDialogKey, PrintContainer, {
                        title: 'Print Widget',
                        componentProps: { configId: config.Id },
                        dialogContentStyle: { overflow: 'hidden' },
                    })
                )
            },
            icon: <PrintIcon fontSize="small" />,
            hide:
                isMobile ||
                isUserWidget ||
                config.version === '2' ||
                config.Type === 'Table' ||
                config.Type === 'WebView',
        },

        {
            name: 'Edit',
            click: () => {
                const state = { type: config.Type }
                dispatch(navigationCreators.goTo(routePaths.EDIT, config.Id, state))
            },
            icon: <EditRounded fontSize="small" />,
            hide: !allowEdit(config, isPowerUser, userId, isMobile, dashboardEditable ?? true),
        },
        {
            name: 'Delete',
            click: () => onDeleteWidget(config),
            icon: <DeleteRoundedIcon fontSize="small" />,
            hide: !allowDelete(config),
        },
        {
            name: 'Expand',
            click: fullscreenWidget,
            icon: <Maximize fontSize="small" />,
            hide: !isMobile,
        },
        {
            name: 'Linear trend lines',
            icon: <TrendLinesIcon fontSize="small" />,
            hide: !trendLines?.length,
            Component: () => <TrendLineMenu trendLines={trendLines} widgetId={config.Id} />,
        },
        {
            name: 'Copy',
            icon: <CopyIcon fontSize="small" />,
            click: () =>
                copyImage(exportType, visualRef, exportOptions)
                    .then(() => showSnackbar('Image copied', { variant: 'success' }))
                    .catch(e => {
                        console.error(e)
                        showSnackbar('Failed to copy image', { variant: 'error' })
                    }),
            hide: !exportType,
        },
        {
            name: 'Reset Column Settings',
            icon: <ClearRounded fontSize="small" />,
            hide: !isTableWidget || isMobile || !data,
            click: resetOptions,
        },
        {
            name: 'Hide',
            icon: <VisibilityOffRounded fontSize="small" />,
            hide: isMobile,
            click: () => {
                onHideWidget(config.Id)
            },
        },
    ]

    return (
        <>
            <div className={classes.actions}>
                <Menu
                    iconProps={{ style: { ...iconStyle } }}
                    buttonStyle={{ ...buttonStyle }}
                    items={items}
                    onToggle={onToggle}
                />
                {openDialog && (
                    <NewChatDialog
                        perspectiveName={config?.Title}
                        open={openDialog}
                        onClose={handleDialogClose}
                        isWidgetChat={true}
                        widgetId={config?.Id}
                    />
                )}
            </div>
        </>
    )
}

function allowEdit(widget, isPowerUser, userId, isMobile, editable) {
    if (widget?.IsManaged === false) return true
    if (!editable) return false
    if (widget?.version !== '2') return false
    if (isMobile) return false

    const { createdBy, active } = widget
    if (createdBy?.id !== userId) {
        if (!isPowerUser || !active) return false
    }

    return true
}

function allowDelete(widget) {
    if (widget?.IsManaged === false) return true

    return false
}
